import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { MessageTypes, api } from "../utils/Enums";
import { apiMessage, onData } from "../WebSocketProvider";
import AlertMessage, {AlertMessageType } from "../elements/AlertMessage";

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [replyId, setReplyId] = useState<number | null>(null);
  const [email, setEmail] = useState<string>("");
  const [messageType, setMessageType] = useState<AlertMessageType>(
    AlertMessageType.WARNING,
  );
  const [message, setMessage] = useState<string>("");

  const handleReply = (data: any) => {
    // If data is the replyId then clear all states
    if (replyId === data.id) {
      setReplyId(null);
      setMessageType(AlertMessageType.SUCCESS);
      setMessage(
        "A verification code has been sent to the provided email address.",
      );
    }
  };

  const handleError = (data: IError) => {
    if (replyId === data.id) {
      setReplyId(null);
      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason);
    }
  };

  useEffect(() => {
    onData.on(MessageTypes.SITE, handleReply);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      onData.off(MessageTypes.SITE, handleReply);
      onData.off(MessageTypes.ERROR, handleError);
    };
  }, [replyId, handleError, handleReply]);


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage("");

    if (!email) {
      setMessage("Please enter your email");
      return;
    }

    setReplyId(apiMessage(api.FORGOT_PASSWORD, { name_email: email }));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="login_main">
          <div>
            <div className="sign_title text-center col-12 mb-md-5 mb-4">
              <p className="pb-3">{t("_forgot_pwd.heading")}</p>
              <h2>{t("_forgot_pwd.sub_heading")}</h2>
            </div>
            <div className="login_page_main d-lg-flex justify-content-between">
              <div className="offset-md-4 col-xl-4 col-lg-5 col-md-12 flex-column justify-content-center mb-5 mb-lg-0">
                <div className="lo_leftside">
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className="text-center"
                  >
                    <div className="login_field">
                      <input
                        className="form-control"
                        autoComplete="one-time-code"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t("_forgot_pwd.plc.name")}
                      />
                    </div>
                    <br />
                    <AlertMessage type={messageType} message={message} />
                    <button
                      type="submit"
                      disabled={replyId !== null}
                      className="login_button"
                    >
                      <span>{t("_forgot_pwd.btn_signin")}</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link className="go_back_to_main" to="/login">
                  {t("_forgot_pwd.back_to_login")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
