import { useTranslation } from "react-i18next";
import { images } from "../../../helper";
import { Link } from "react-router-dom";

export const QPlayNow = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="section-sticky-right">
      <div className="sticky-content">
        <h2>{t("_common.play_now")}</h2>

        <div className="game-box justify-content-center d-flex">
          <div className="game-sec">
            <div>
              <img src={images.svg_windfall_lp} alt="TODO: Image Title"/>
              <br />
              <label>Windfall Lottery&#8482;</label>
            </div>
          </div>

          <Link to="/" className="btn btn-quinto">
            <img src={images.svg_login_button} alt="" /> &nbsp;
            <span>Play Now!</span>
          </Link>
        </div>

        <div className="game-box trimmora d-flex justify-content-center">
          <div className="game-sec">
            <div>
              <img src={images.svg_ic_game_trimorra} alt="TODO: Image Title"/>
              <br />
              <label>TRIMORRA&#8482;</label>
            </div>
          </div>

          <Link to="/" className="btn btn-quinto">
            <img src={images.svg_login_button} alt="" /> &nbsp;
            <span>Play Now!</span>
          </Link>
        </div>

        <div className="game-box justify-content-center trimmora d-flex">
          <div className="game-sec">
            <div>
              <img
                style={{ width: "342px", padding: "0px 20px" }}
                src={images.png_ic_game_3up_bingo}
              />
              <br />
              <label>3up bingo&#8482;</label>
            </div>
          </div>

          <Link to="/" className="btn btn-quinto">
            <img src={images.svg_login_button} alt="" /> &nbsp;
            <span>Play Now!</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
