export const PageHeader = (props) => {
  return (
    <section>
      <div className="pages-header">
        <div className="container">
          <h1>{props.title || ""}</h1>
        </div>
      </div>
    </section>
  );
};
