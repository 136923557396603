import { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../utils/Enums";
import { apiMessage, onData } from "../../WebSocketProvider";
import AlertMessage, {AlertMessageType } from "../../elements/AlertMessage";
import FAQCollapsable from "../../elements/FAQCollapsable";
import QButton from "../../elements/QButton";
import WordLimiter from "../../elements/WordLimiter";

const Support = () => {
  const { i18n, t } = useTranslation();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [question, setQuestion] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [messageType, setMessageType] = useState(AlertMessageType.WARNING);
  const [message, setMessage] = useState("");

  let msgId = -1;

  const handleSubmit = (e) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage("");

    if (!name) {
      setMessage("Please enter name");
      return;
    }

    if (!email) {
      setMessage("Please enter email");
      return;
    }

    if (!subject) {
      setMessage("Please enter subject");
      return;
    }

    if (!question) {
      setMessage("Please enter question");
      return;
    }

    setIsLoading(true);

    let params = {};
    params.name = name;
    params.email = email;
    params.subject = subject;
    params.question = question;

    msgId = apiMessage(api.SUPPORT_REQUEST, params);
  };

  onData.addListener("site_info", (arg) => {
    if (arg.id == msgId) {
      if (arg.hasOwnProperty("error")) {
        setMessageType(AlertMessageType.WARNING);
        setMessage(arg.error.reason);
      } else {
        setName("");
        setEmail("");
        setSubject("");
        setQuestion("");
        setMessageType(AlertMessageType.SUCCESS);
        setMessage(
          "Thank you for contacting Quinto Games! Your request is successfully submitted. We'll respond within 24-48 hours. Appreciate your patience!",
        );
      }
    }

    setIsLoading(false);
  });

  return (
    <section className="pages">
      {/* <PageHeader title={t("_page_support.heading")} /> */}

      <div className="container">
        <br />
        <br />
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center text-primary">
              {t("_page_support.heading")}
            </h1>
            <br />
            <br />

            <div className="card card-white hiw-card p-50">
              <div className="card-body">
                <p>
                  At Quinto Games, we're committed to providing you with an
                  outstanding gaming experience. Our dedicated support team is
                  here to ensure that any questions or issues you might have are
                  addressed promptly and efficiently.
                </p>
                <p>
                  We understand that challenges can arise, and we're ready to
                  assist you with any aspect of your gaming journey – from game
                  features and account issues to technical difficulties or
                  payment inquiries. Our support channels are constantly
                  monitored, guaranteeing timely and professional responses to
                  your needs.
                </p>
                <p>
                  Our promise to you is a maximum response time of 24 to 48
                  hours. This commitment allows us to carefully address your
                  concerns while ensuring that you receive the thorough and
                  effective support you deserve.
                </p>
                <p>
                  Your satisfaction and enjoyment are our top priorities. Trust
                  that when you're playing our games, you have a reliable and
                  passionate team supporting you every step of the way. At
                  Quinto Games, we're more than just a gaming site; we're your
                  partners in every adventure.
                </p>
                <br />

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="form-control"
                        placeholder={t("_page_support.plc.name")}
                      />
                      <br />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder={t("_page_support.plc.email")}
                      />
                      <br />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        className="form-control"
                        placeholder={t("_page_support.plc.subject")}
                      />
                      <br />
                    </div>

                    <div className="col-md-12">
                      <WordLimiter
                        value={question}
                        maxWords="2000"
                        charCount
                        onChange={(val) => setQuestion(val)}
                        className="form-control"
                        placeholder={t("_page_support.plc.comments")}
                      />
                    </div>

                    <div className="col-md-12">
                      <AlertMessage
                        className="mt-4"
                        pClass="ml-30 text-white"
                        type={messageType}
                        message={message}
                      />
                    </div>

                    <div className="col-md-8 text-end"></div>
                    <div className="col-md-4 text-end">
                      <br />
                      <QButton submit block noIcon text="Submit" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <h1 className="text-center text-primary">
              Frequently Asked Questions
            </h1>
            <br />

            <FAQCollapsable open={true} q="Do I need to signup to play?">
              <p>
                Yes, to participate in any game you must register, however, feel
                free to browse our website before deciding to signup.
              </p>
            </FAQCollapsable>

            <FAQCollapsable q="What is a Quintoshi?">
              <p>
                All wager amounts on the platform are presented exclusively
                using the site's internal currency, known as Quintoshi ("
                <span className="q-symbol">&#xE1E0;</span>"), which holds parity
                with the US Dollar.
              </p>
            </FAQCollapsable>

            <FAQCollapsable q="What is the minimum deposit amount?">
              <p>Minimum deposits vary depending on the type of deposit.</p>
              <p>
                We do not have a minimum deposit requirement for
                cryptocurrencies. However, for reference, the Windfall Lottery
                ticket price is <span className="q-symbol">&#xE1E0;</span>25.00,
                and we recommend this as your minimum deposit amount.
              </p>
            </FAQCollapsable>

            <FAQCollapsable q="Are taxes deducted from winnings?">
              <p>
                No. Taxes, fees, or other charges, if applicable, are the sole
                responsibility of the winner(s).{" "}
              </p>
            </FAQCollapsable>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <br />
            <br />
            <h1 className="text-center text-primary">
              Windfall Lottery Frequently Asked Questions
            </h1>
            <br />

            <FAQCollapsable
              open={true}
              q="When does the Windfall Lottery drawing take place?"
            >
              <p>The drawing occurs as soon as the last ticket is sold.</p>
              <p>
                While the first lottery game may take some time, our goal is to
                establish a monthly or weekly drawing schedule to provide more
                frequent opportunities for our participants.
              </p>
            </FAQCollapsable>

            <FAQCollapsable q="What is the cost of playing Windfall Lottery?">
              <p>
                The cost of playing Windfall Lottery is{" "}
                <span className="q-symbol">&#xE1E0;</span>25.00 per ticket.
              </p>
              <p>
                Each ticket includes 125 plays. This pricing structure ensures
                that every ticket has a payout and offers a chance to win
                significant prizes.
              </p>
            </FAQCollapsable>

            <FAQCollapsable q="Is Windfall Lottery a raffle?">
              <p>
                Windfall Lottery is not a raffle, unlike raffles, where winners
                are randomly drawn from a pool of tickets, Windfall Lottery is a
                lottery game. In our game, participants purchase tickets with
                multiple picks, and the lottery drawing determines the outcome
                of the tickets based on the cards drawn.
              </p>
            </FAQCollapsable>

            <FAQCollapsable q="What is the deadline for purchasing tickets?">
              <p>
                Windfall Lottery has a total of 2,500,000 tickets available for
                sale, and the deadline for purchasing tickets is when the last
                of these tickets is sold.
              </p>
            </FAQCollapsable>
            <FAQCollapsable q="Who can purchase tickets?">
              <p>
                Tickets can be purchased by individuals who are at least 18
                years of age or the legal age of majority in their jurisdiction,
                provided that local laws permit participation in the game.
                Please note that residents of Costa Rica are not eligible to
                purchase tickets.
              </p>
            </FAQCollapsable>
            <FAQCollapsable q="Is it true every ticket wins a prize?">
              <p>
                Yes. Every ticket wins a prize. There is a high statistical
                probability that every ticket has at least one winning pick. In
                the rare case that a ticket has no winning picks, it still
                returns <span className="q-symbol">&#xE1E0;</span>25.00, which
                is the purchase price of the ticket.
              </p>
            </FAQCollapsable>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
