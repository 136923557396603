// PurchaseTickets.tsx
import React, { useState, useEffect } from "react";
import TicketQuantity from "../../../elements/TicketQuantity";
import { images } from "../../../../helper";
import QButton from "../../../elements/QButton";
import JackpotAnimator, { IJackpotAnimator } from "../../../elements/JackpotAnimator";
import { db, onData } from "../../../WebSocketProvider";
import { ILotteryGame, LotteryStatus } from "../LotteryBase";
import { MessageTypes } from "../../../utils/Enums";
import QSymbol from "../../../utils/QSymbol";
import { formatValue } from "../../../utils/Round";

const PurchaseTickets: React.FC<IString> = ({ text }) => {

  const [defId, setDefId] = useState<string | null>(null); 
  const [game, setGame] = useState<ILotteryGame | null>(null);  
  const [remaining, setRemaining] = useState<IJackpotAnimator>({
    init: {
      value: 0,       // Initial value
      precision: 0,   // Number of decimal points
      symbol: false,  // Display currency symbol
      time: 7.50      // Time to achieve the target value in seconds
    },
    target: 0         // Target value to reach
  });

  useEffect(() => {
    const handleLotteryData = (data: ILotteryGame) => {
      if (!defId || defId !== data.def_id) return;
      setGame(data);
      setRemaining((prevProps) => ({
        ...prevProps,
        target: data.remaining,
      }));         
    };

    const initialize = () => {
      if (!db) throw new Error("Database instance not available");
      
      setDefId(text);
      const query = `
        SELECT 
          *
        FROM
          lottery_games
        WHERE
          def_id = $id;
      `;
      const stmt = db.prepare(query);
      const row = stmt.getAsObject({$id: text});
      console.log(`PresalePage.tsx Data(${text})`, row);
      stmt.free();

      setGame(row);

      setRemaining((prevProps: JsonObject) => ({
        ...prevProps,
        init: {
          ...prevProps.init,
          value: row.remaining,
        },
        target: row.remaining,
      }));
    };

    if (!defId)
      initialize();
    onData.on(MessageTypes.LOTTERY_GAME, handleLotteryData);

    return () => {
      onData.off(MessageTypes.LOTTERY_GAME, handleLotteryData);
    };

  }, [game]);

  return (
    <div className="pool-purchase">
      <div className="purchase-ticket">
        <div className="purchase-ticket-column d-flex justify-content-around mb-3">
          <div style={{ flex: "20%" }}>
            <TicketQuantity label_3="Ticket Quantity" />
          </div>
          <div className="min-purchase-card flex-grow-1 mx-2">
            <div>
              <label>Min Purchase</label> 1
            </div>
            <div className="border mx-2"></div>
            <div>
              <label>Max Purchase</label> 1000
            </div>
            <div className="border mx-2"></div>
            <div>
              <label>Cost Per Ticket</label> <QSymbol value={formatValue(game ? game.buyin : 25, 2)} />
            </div>
          </div>
        </div>
        <QButton className="create-lottery-button my-3" noIcon={false}>
          CREATE LOTTERY POOL
        </QButton>
        <div className="confirm-purchase d-flex flex-column">
          <h5 className="p-4">Please Confirm Your Purchase</h5>
          <div className="flex-fill d-flex flex-column justify-content-around">
            <div className="d-flex justify-content-between confirm-purchase-items mx-4 py-3 px-2">
              <div className="purchase-items">
                <label>25 LOTTERY POOL TICKETS</label>
                <p>Game #1 Windfall Lottery General Pool</p>
              </div>
              <div className="border mx-2"></div>
              <div className="purchase-items">
                <label>Price</label>
                <h5>
                  <QSymbol/> Quintoshi 625.00
                </h5>
              </div>
            </div>
            <div className="text-center mt-4">
              <QButton className="mx-3" icon={images.svg_windfall_Vector}>
                YES! LET’S GO!
              </QButton>
            </div>
            <p className="text-center my-3">Cancel</p>
          </div>
        </div>
        <div className="confirm-purchase d-flex flex-column justify-content-around">
          <h4 className="p-4">You are in the game!</h4>
          <h5 className="">Thank you for your purchase of 25 pool tickets!</h5>
          <div className="purchase-game mx-3">
            <div className="py-3 mx-1 mt-3">
            <QSymbol/> Quintoshi 625.00
            </div>
            <div className="py-3 mx-1 mt-3">Transaction ID #457987</div>
          </div>
          <div className="confirm-purchase-items text-center py-3 mx-3 my-3">
            Please check email for a confirmation receipt if you have an email on file
          </div>
          <div>
            <p className="text-center pb-3">Cancel</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseTickets;
