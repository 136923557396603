import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import ProfileHeader from "./ProfileHeader";
import { AuthTypes, ClientStatus } from "../utils/Enums";
import "./Profile.css";
import TrackPage from "../inc/TrackPage";

const Profile: React.FC = () => {
  TrackPage();

  const { i18n, t } = useTranslation();

  return (
    <>
      <ProfileHeader />

      <section>
        <div className="container p-0">
          <div className="profile-tabs">
          <div className="tab">
              <NavLink to="AvatarSelector" className={({ isActive }) => (isActive ? "active" : undefined)}>
                {t("_profile.section.avatar.title")}
              </NavLink>
            </div>            
            <div className="tab">
              <NavLink to="ChangeEmail" className={({ isActive }) => isActive ? "active" : undefined}>
                {t("_profile.section.changeEmail.title")}
              </NavLink>
            </div>
            <div className="tab">
              <NavLink to="ChangePassword" className={({ isActive }) => isActive ? "active" : undefined}>
                {t("_profile.section.password.title")}
              </NavLink>
            </div>
            {mii.authentication === AuthTypes.NONE && (
              <div className="tab">
                <NavLink to="TwoFA
                " className={({ isActive }) => isActive ? "active" : undefined}>
                  {t("_profile.section.2FA.title")}
                </NavLink>
              </div>
            )}
            {mii.status === ClientStatus.UNVERIFIED && (
              <div className="tab">
                <NavLink to="ValidateEmail" className={({ isActive }) => isActive ? "active" : undefined}>
                  {t("_profile.section.validate_email.title")}
                </NavLink>
              </div>
            )}
            <div className="tab">
              <NavLink to="SecureAddresses" className={({ isActive }) => isActive ? "active" : undefined}>
                {t("_profile.section.secure.title")}
              </NavLink>
            </div>
          </div>

          <div className="outlet">
            <Outlet/>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
