import { useTranslation } from "react-i18next";
import React, { useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

interface ProfileDropDownProps {
  onLinkClicked?: () => void;
  className?: string;
  children?: React.ReactNode; // Add children to the props interface
}

const CustomToggle = forwardRef<HTMLAnchorElement, any>(({ children, onClick }, ref) => (
  <a
    href=""
    className="text-capitalize"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick && onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = forwardRef<HTMLDivElement, any>(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul>
          {React.Children.toArray(children).filter(
            (child: any) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

const ProfileDropDown: React.FC<ProfileDropDownProps> = (props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <>
      <Dropdown className={`h_text ${props.className || ""}`}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-true">
          {props.children}
        </Dropdown.Toggle>

        <Dropdown.Menu align="end" as={CustomMenu}>
          <li>
            <Dropdown.Item
              onClick={props.onLinkClicked}
              className="text-center"
              as={Link}
              to="/profile"
            >
              {t("_dropdowns.profile.settings")}
            </Dropdown.Item>
          </li>

          <li>
            <Dropdown.Item
              onClick={props.onLinkClicked}
              className="text-center"
              as={Link}
              to="/accounts"
            >
              {t("_dropdowns.profile.accounting")}
            </Dropdown.Item>
          </li>

          <li>
            <Dropdown.Item
              onClick={props.onLinkClicked}
              className="text-center"
              as={Link}
              to="/logout"
            >
              {t("_dropdowns.profile.logout")}
            </Dropdown.Item>
          </li>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ProfileDropDown;
