import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { images } from "../../helper";
import { apiMessage, onData } from "../WebSocketProvider";
import AlertMessage, {AlertMessageType } from "../elements/AlertMessage";
import { MessageTypes, api } from "../utils/Enums";
import { LoginWith } from "./LoginWith";

import PasswordElement from "../elements/PasswordElement";
import QButton from "../elements/QButton";


export const Register: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [replyId, setReplyId] = useState<number | null>(null);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState<string | null>(null);
  const [opt_in, setOptIn] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const hadleRegistered = ( data: IData) => {
    if (data.id !== replyId)
      throw new Error(`hadleRegistered.INVALID RETURN MESSAGE replyId${replyId}, data.id${data.id}`);

    setReplyId(null);
    setErrorMsg(null);
    unHook();
  };

  const handleClient = ( data: IData) => {
    onData.off(MessageTypes.CLIENT, handleClient);
    navigate("/");
  };

  const handleError = ( data: IError) => {  
    if (data.id !== replyId)
      throw new Error(`hadleRegistered.INVALID RETURN MESSAGE replyId${replyId}, data.id${data.id}`);

    setReplyId(null);
    setErrorMsg(data.reason);
    unHook();
  };


  const unHook = () => {
    onData.off(MessageTypes.CLIENT, hadleRegistered);
    onData.off(MessageTypes.ERROR, handleError);
  }
  
  useEffect(() => {
    onData.on(MessageTypes.CLIENT, handleClient);
    onData.on(MessageTypes.SITE, hadleRegistered);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      unHook();
    };
  }, [hadleRegistered, handleError]);


  const handlePassword = (value: string) => {
    setPassword(value);
  };

  const handleConfirm = (value: string) => {
    setConfirmation(value);
  };
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMsg(null);

    if (!name) {
      setErrorMsg(t("_register.error.name"));
      return;
    }

    if (!email) {
      setErrorMsg(t("_register.error.email"));
      return;
    }

    if (!password) {
      setErrorMsg(t("_register.error.password"));
      return;
    }

    if (!confirmation) {
      setErrorMsg(t("_register.error.confirm"));
      return;
    }

    if (password !== confirmation) {
      setErrorMsg(t("_register.error.match"));
      return;
    }

    onData.on(MessageTypes.CLIENT, handleClient);
    onData.on(MessageTypes.SITE, hadleRegistered);
    onData.on(MessageTypes.ERROR, handleError);

    setReplyId(apiMessage(api.REGISTER, {email: email, name: name, password: password, opt_in: opt_in}));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="login_main login_main_register">
          <div>
            <div className="sign_title col-12 mb-md-5 mb-4">
              <p>{t("_register.welcome")}</p>
              <h2>{t("_register.create-account")}</h2>
            </div>

            <div className="login_page_main d-lg-flex justify-content-between">
              <div className="col-xl-4 col-lg-5 col-md-12 flex-column justify-content-center mb-5 mb-lg-0">
                <div className="lo_leftside">
                  <form onSubmit={handleSubmit} className="text-center">
                    <div className="login_field">
                      <input
                        type="email"
                        autoFocus
                        className="form-control"
                        autoComplete="one-time-code"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t("_register.input.email")}
                      />
                    </div>
                    <br />

                    <div className="login_field">
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="one-time-code"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t("_register.input.name")}
                      />
                    </div>
                    <br />

                    <PasswordElement
                      onPasswordChange={handlePassword}
                      className="login_field"
                      placeholder={t("_register.input.password")}
                    />
                    <br />

                    <PasswordElement
                      onPasswordChange={handleConfirm}
                      className="login_field"
                      placeholder={t("_register.input.confirm")}
                    />
                    <br />

                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        checked={opt_in}
                        onChange={(e) => setOptIn(e.target.checked)}
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                      <label
                        className="form-check-label m-0 text-start d-block"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        {t("_register.input.updates")}
                      </label>
                    </div>
                    <br />

                    <AlertMessage type={AlertMessageType.WHITE_WARNING} message={errorMsg ? errorMsg : ''} />

                    <QButton
                      submit
                      block
                      icon={images.svg_login_button}
                      className="login_button"
                      text={t("_register.create-account")}
                      disabled={replyId !== null}
                    />
                  </form>
                </div>
              </div>
              <span className="middle_border d-none d-lg-block"></span>

              <div className="col-xl-4 col-lg-5 col-md-12 text-center">
                <div className="lo_rightside">
                  <div className="">
                    <img
                      className="img-responsive"
                      src={images.svg_windfall_acct}
                      alt=""
                    />
                  </div>
                  <div className="acc_border my-4"></div>
                  <LoginWith />
                  <div className="trouble_login trouble_login_page text-center mb-2">
                    <span>{t("_register.have-login")}</span>
                    <span>
                      <Link to="/login">{t("_register.click-here")}</Link>
                    </span>
                  </div>
                  <div className="trouble_login trouble_login_page text-center">
                    <span>{t("_register.forgot")}</span>
                    <span>
                    <Link to="/forgot-password">{t("_register.click-here")}</Link>                      
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link className="go_back_to_main" to="/">
                  {t("_common.back-home")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
