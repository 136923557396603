import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import PublicRoutes from './components/PublicRoutes';
import WebSocketProvider from './components/WebSocketProvider';
import Home from './components/pages/Home';

/* Profile Page Imports */
import AvatarSelector from './components/profile/AvatarSelector';
import ChangeEmail from './components/profile/ChangeEmail';
import ChangePassword from './components/profile/ChangePassword';
import SecureAddresses from './components/profile/SecureAddresses';
import TwoFA from './components/profile/TwoFA';
import ValidateEmail from './components/profile/ValidateEmail';

/* Accounting Imports */
import Accounts from './components/accounts/Accounts';
import Transactions from './components/accounts/Transactions';
import Deposit from './components/accounts/deposit/Deposit';
import Withdraw from './components/accounts/withdraw/Withdraw';

/* Depsoit Imports */

/* Withdraw Imports */

import { AccountBalance } from './components/accounts/AccountBalance';
import { AboutUs } from './components/pages/static/AboutUs';




import { EmailValidation } from './components/auth/EmailValidation';
import { Login } from './components/auth/Login';
import { Register } from './components/auth/Register';
import { ResetPassword } from './components/auth/ResetPassword';
import { Error400 } from './components/pages/static/Error400';
import { Error500 } from './components/pages/static/Error500';
import { LegalAgeReq } from './components/pages/static/LegalAgeReq';
import { PlayingResponsiblyMatters } from './components/pages/static/PlayingResponsiblyMatters';
import { PrivacyPolicy } from './components/pages/static/PrivacyPolicy';
import { TermsAndConditions } from './components/pages/static/TermsAndConditions';
import Profile from './components/profile/Profile';
import { disableScrollTopRoutes } from './config';

import { ValidatePassword } from './components/profile/ValidatePassword';

import ForgotPassword from './components/auth/ForgotPassword';
import Logout from './components/auth/Logout';
import Trimorra from './components/games/trimorra/Trimorra';
import LotteryPools from './components/pages/static/LotteryPools';
import NotEnExchange from './components/pages/static/NotEnExchange';
import ProvablyFairGeneral from './components/pages/static/ProvablyFairGeneral';
import QRewards from './components/pages/static/QRewards';
import Support from './components/pages/static/Support';



/* Windfall Lottery Imports */
import PresalePage from './components/games/lottery/windfall/PresalePage';
import WindfallProvablyFair from './components/games/lottery/windfall/ProvablyFair';
import WindfallProvablyFairTech from './components/games/lottery/windfall/ProvablyFairTech';
import WindfallPayouts from './components/games/lottery/windfall/WindfallPayouts';
import WindfallTickets from './components/games/lottery/windfall/WindfallTickets';
import PrizePayoutTable from './components/pages/static/PrizePayoutTable';
import WhyWindfallLottery from './components/pages/static/WhyWindfallLottery';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop avoid={disableScrollTopRoutes} />
      <Routes>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="*" element={<Error400 />} />
          <Route path="/" element={<Home />} />

          // Accounts Section
          <Route path="/accounts" element={<Accounts />}>
          {/* Nested routes */}
            <Route path="AccountBalance" element={<AccountBalance />} />
            <Route path="Deposit" element={<Deposit />} />
            <Route path="Withdraw" element={<Withdraw />} />
            <Route path="Transactions" element={<Transactions />} />
          </Route> 

          <Route path="/presale" element={<PresalePage />} />
          <Route path="/presale" element={<WindfallPayouts />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/email_validation" element={<EmailValidation />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/profile" element={<Profile />}>
            <Route
                path="/profile"
                element={<Navigate to="/profile" />}
              />
              <Route path="AvatarSelector" element={<AvatarSelector />} />
              <Route path="ChangeEmail" element={<ChangeEmail />} />
              <Route path="ChangePassword" element={<ChangePassword />} />
              <Route path="TwoFA" element={<TwoFA />} />
              <Route path="ValidateEmail" element={<ValidateEmail />} />
              <Route path="ValidatePassword" element={<ValidatePassword />} />
              <Route path="SecureAddresses" element={<SecureAddresses />} />

          </Route>          

          <Route path="/windfall" element={<WindfallTickets />} />
            <Route path="/windfall/provably-fair-tech" element={<WindfallProvablyFairTech />} />
            <Route path="/windfall/provably-fair" element={<WindfallProvablyFair />} />

          <Route path="/trimorra" element={<Trimorra />} />
            
          <Route path="/support" element={<Support />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/why-windfall-lottery" element={<WhyWindfallLottery />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/provably-fair-general" element={<ProvablyFairGeneral />} />
          <Route path="/playing-responsibly-matters" element={<PlayingResponsiblyMatters />} />
          <Route path="/legal-age-req" element={<LegalAgeReq />} />
          <Route path="/lottery-pools" element={<LotteryPools />} />
          <Route path="/prize-payout-table" element={<PrizePayoutTable />} />
          <Route path="/rewards" element={<QRewards />} />
          <Route path="/not-an-exchange" element={<NotEnExchange />} />
          <Route path="/not-found" element={<Error400 />} />
          <Route path="/500" element={<Error500 />} />
        </Route>
      </Routes>
    </Router>
  );
};

interface IScrollToTopProps {
  avoid?: string[];
}

const ScrollToTop: React.FC<IScrollToTopProps> = ({ avoid = [] }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Check if the pathname is in the avoid array
    if (avoid.includes(pathname)) {
      console.log(`Pathname ${pathname} is in the avoid array.`);
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname, avoid]);

  return null;
};


const MainApp: React.FC = () => {
  useEffect(() => {
    console.log("PUBLIC_URL:", process.env.PUBLIC_URL);
  }, []);

  return (
    <WebSocketProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </WebSocketProvider>
  );
};

export default MainApp;
