import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import QSymbol from '../../../utils/QSymbol';

interface WindfallPayoutsTableProps {
  className?: string;
}

const WindfallPayoutsTable: React.FC<WindfallPayoutsTableProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="table-responsive">
      <table className={`mini-table wwl-aligned ${props.className || ""}`}>
        <thead className="text-center">
          <tr>
            <th>{t('_lottery.windfall.payout.columns.match')}</th>
            <th>{t('_lottery.windfall.payout.columns.wins')}</th>
            <th>{t('_lottery.windfall.payout.columns.odds')}</th>
            <th>
              <Trans
                i18nKey="_lottery.windfall.payout.columns.payout"
                components={{ symbol: <QSymbol /> }}
              />
            </th>
          </tr>
        </thead>
        <tbody className="text-end">
          <tr className="wlp-bg-yellow">
            <td>2-0</td>
            <td>12,647,700</td>
            <td>0.20</td>
            <td>0.25</td>
          </tr>
          <tr className="wlp-bg-yellow">
            <td>2-1</td>
            <td>5,837,400</td>
            <td>0.43</td>
            <td>0.50</td>
          </tr>
          <tr className="wlp-bg-yellow">
            <td>2-2</td>
            <td>972,900</td>
            <td>2.56</td>
            <td>1.00</td>
          </tr>
          <tr className="wlp-bg-orange">
            <td>3-0</td>
            <td>691,840</td>
            <td>3.61</td>
            <td>1.00</td>
          </tr>
          <tr className="wlp-bg-orange">
            <td>3-1</td>
            <td>454,020</td>
            <td>5.50</td>
            <td>2.00</td>
          </tr>
          <tr className="wlp-bg-orange">
            <td>3-2</td>
            <td>129,720</td>
            <td>19.23</td>
            <td>5.00</td>
          </tr>
          <tr className="wlp-bg-orange">
            <td>3-3</td>
            <td>21,620</td>
            <td>115.40</td>
            <td>25.00</td>
          </tr>
          <tr className="wlp-bg-green">
            <td>4-0</td>
            <td>12,455</td>
            <td>200.32</td>
            <td>50.00</td>
          </tr>
          <tr className="wlp-bg-green">
            <td>4-1</td>
            <td>10,340</td>
            <td>241.30</td>
            <td>50.00</td>
          </tr>
          <tr className="wlp-bg-green">
            <td>4-2</td>
            <td>4,230</td>
            <td>589.83</td>
            <td>100.00</td>
          </tr>
          <tr className="wlp-bg-green">
            <td>4-3</td>
            <td>940</td>
            <td>2,654.26</td>
            <td>1,000.00</td>
          </tr>
          <tr className="wlp-bg-green">
            <td>4-4</td>
            <td>235</td>
            <td>10,617.03</td>
            <td>10,000.00</td>
          </tr>
          <tr className="wlp-bg-light-blue">
            <td>5-0</td>
            <td>44</td>
            <td>56,704.58</td>
            <td>50,000.00</td>
          </tr>
          <tr className="wlp-bg-light-blue">
            <td>5-1</td>
            <td>45</td>
            <td>55,444.48</td>
            <td>100,000.00</td>
          </tr>
          <tr className="wlp-bg-light-blue">
            <td>5-2</td>
            <td>20</td>
            <td>124,750.08</td>
            <td>250,000.00</td>
          </tr>
          <tr className="wlp-bg-light-blue">
            <td>5-3</td>
            <td>10</td>
            <td>249,500.16</td>
            <td>1,000,000.00</td>
          </tr>
          <tr className="wlp-bg-blue">
            <td>5-5</td>
            <td>1</td>
            <td>2,495,001.60</td>
            <td>{t('_lottery.windfall.payout.columns.aggregate')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WindfallPayoutsTable;