import React, { useState, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { images } from "../../helper";
import { writeParams } from "../utils/Utils";
import TranslationStatusModal from "../pages/static/TranslationStatus";
import { MessageTypes } from "../utils/Enums";
import "./LangDropDownMenu.css";  // Ensure your CSS file is loaded

const LANGUAGES = [
  { code: "en", flag: "en.svg" },
  { code: "es", flag: "es.svg" },
  { code: "de", flag: "de.svg" },
  { code: "fr", flag: "fr.svg" },
  { code: "it", flag: "it.svg" },
];

interface CustomToggleProps {
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const CustomToggle = React.forwardRef<HTMLAnchorElement, CustomToggleProps>(
  ({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(e);
      }}
      className="lang-dropdown-toggle"
    >
      {children}
    </a>
  )
);

interface CustomMenuProps {
  children: ReactNode;
  style: React.CSSProperties;
  className: string;
  "aria-labelledby": string;
}

const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div ref={ref} style={style} className={`${className} lang-dropdown-menu`} aria-labelledby={labeledBy}>
        <ul>
          {React.Children.toArray(children).filter(
            (child: any) => !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

interface LangDropDownMenuProps {
  support?: boolean;
}

const LangDropDownMenu: React.FC<LangDropDownMenuProps> = ({ support = false }) => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleLangChange = (e: React.MouseEvent, code: string) => {
    i18n.changeLanguage(code);
    writeParams(MessageTypes.MII, "language", code);
    setShowModal(code !== "en");
  };

  const getCurrentLangFlag = useMemo(() => {
    return LANGUAGES.find((val) => val.code === i18n.language)?.flag;
  }, [i18n.language]);

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Dropdown className={`h_text h_language ${support ? "h_support" : ""}`}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-true">
          <div className="toggle-icon">
            <img src={support ? images.png_d_errow : images.svg_arrow_down_colored} alt="Toggle Icon" />
          </div>
          <span>{i18n.language.toUpperCase()}</span>
          <img src={`/flags/${getCurrentLangFlag}`} alt={i18n.language} />
        </Dropdown.Toggle>

        <Dropdown.Menu align="end" as={CustomMenu}>
          {LANGUAGES.map(({ code, flag }) => (
            <li key={`lang-${code}`} className="lang-dropdown-item" onClick={(e) => handleLangChange(e, code)}>
              <Dropdown.Item eventKey={code}>
                <img src={`/flags/${flag}`} alt={code} />
                <label>{t(`languages.${code}`)}</label>
              </Dropdown.Item>
            </li>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <TranslationStatusModal show={showModal} handleClose={handleCloseModal} />
    </>
  );
};

export default LangDropDownMenu;
