import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Paginations from '../../../elements/Paginations';
import { db, onData } from '../../../WebSocketProvider';
import * as utils from '../../../utils/Utils';
import { IParticipant, IPresale } from "../LotteryBase";
import { ClientStatus, MessageTypes, TabTypes } from '../../../utils/Enums';
import AvatarImage from '../../../elements/AvatarImage';
import './Windfall.css'; // Import the Windfall CSS file

interface PresaleListProps {
  def_id: string;
}

interface IReply {
  row: IPresale;
}

const PresaleList: React.FC<PresaleListProps> = ({ def_id }) => {
  const { t } = useTranslation();
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [mine, setMine] = useState<boolean>(false);
  const [paginationKey, setPaginationKey] = useState<number>(0); // Add a state for pagination key

  const handleMine = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMine(e.target.checked);
    setCurrentPage(1); // Reset to the first page
    setPaginationKey(prevKey => prevKey + 1); // Change the pagination key
    utils.writeParams(TabTypes.LOTTERY, 'presale.mine', e.target.checked); // Updated to use e.target.checked
    fetchWaitingList(e.target.checked);
  };

  const fetchWaitingList = (mine: boolean) => {
    if (!db) throw new Error("Database instance not available");
  
    const query = `
      SELECT
        avatar AS ndx, user_name AS name, trx_id, ticket_qty, purchased
      FROM
        windfall_presales
      LEFT JOIN 
        avatars USING (client_ref)
      WHERE
        def_id = $id ${mine ? 'AND client_ref = $client' : ''}
      ORDER BY
        purchased DESC;
    `;
  
    try {
      const rows: IParticipant[] = [];
      const stmt = db.prepare(query);
      stmt.bind({ $id: def_id, $client: mine ? mii.client_ref : undefined });
  
      while (stmt.step()) {
        const data = stmt.getAsObject();
        const participant: IParticipant = {
          profile: {
            name: data.name,
            ndx: utils.asInt(data.ndx), // Assuming ndx needs to be converted to Integer
          },
          trx_id: data.trx_id,
          ticket_qty: data.ticket_qty,
          purchased: new Date(data.purchased), // Convert purchased to Date
        };
  
        rows.push(participant);
      }
      stmt.free();
  
      setParticipants(rows);
  
    } catch (error) {
      console.log("Error querying waiting list:", error);
    }
  };

  useEffect(() => {
    fetchWaitingList(mine);
  }, [mine]);

  useEffect(() => {
    const handleUpdate = (data: IReply) => {
      if (!db) throw new Error("Database instance not available");

      const query = `
        SELECT
          avatar as ndx, user_name as name, trx_id, ticket_qty, purchased
        FROM
          windfall_presales
        LEFT JOIN avatars USING (client_ref)
        WHERE
          def_id = $id AND client_ref = $client
        ORDER BY
          purchased DESC;
      `;

      const presale = data.row as IPresale;

      try {
        const stmt = db.prepare(query);
        const result = stmt.getAsObject({ $id: presale.def_id, $client: presale.client_ref });
        stmt.free();

        if (!result.name) {
          console.log("Could Not Find the data (NOTE: Should never happen)");
          return;
        } 
        else {
          const participant: IParticipant = {
            profile: {
              name: result.name,
              ndx: utils.asInt(result.ndx), // Assuming ndx needs to be converted to Integer
            },
            trx_id: result.trx_id,
            ticket_qty: result.ticket_qty,
            purchased: new Date(result.purchased), // Convert purchased to Date
          };

          if (!mine || presale.client_ref === mii.client_ref)
            setParticipants((prevTempData: IParticipant[]) => [participant, ...prevTempData]); // Add new data to temporary collection
        }

      } catch (error) {
        console.log("Error querying presale waiting list:", error);
      }
    };

    onData.on(MessageTypes.PRESALE, handleUpdate);

    setMine(utils.readParams(TabTypes.LOTTERY, 'presale.mine', false));

    return () => {
      onData.off(MessageTypes.PRESALE, handleUpdate);
    };
  }, []);

  const tableHeaders = (
    <tr>
      <th className="text-start">{t('_lottery.windfall.listing.player')}</th>
      <th>{t('_lottery.windfall.listing.id')}</th>
      <th>{t('_lottery.windfall.listing.qty')}</th>
      <th>{t('_lottery.windfall.listing.purchased')}</th>
    </tr>
  );

  const renderPresaleData = (data: IParticipant[]) => (
    <>
      {data.map((participant, index) => (
        <tr key={`presale-tr-${index}`} className="presale-list-item">
          <td>
            <div className="avatar-profiler">
              <AvatarImage
                profile={participant.profile}
                className="avatar-image avatar-tiny m-2"
              />
              <span>{participant.profile.name}</span>
            </div>                 
          </td>
          <td>{participant.trx_id}</td>
          <td>{participant.ticket_qty}</td>
          <td>{moment.utc(participant.purchased).local().format('YYYY-DD-MM / HH:mm:ss')}</td>
        </tr>
      ))}
    </>
  );

  return (
    <div className="presale-list-container">
      <Paginations
        data={participants}
        header={undefined}        
        className="table qtable presale-list-table"
        tableHeaders={tableHeaders}
        jsxData={renderPresaleData}

        recordsPerPage={12}
        useCircles={true}
        theme="light"
      />
      <div className="presale_my_entries">
        <input
          checked={mine}
          onInput={handleMine}
          className="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          disabled={mii.status === ClientStatus.OBSERVER}
        />
        <label className="form-check-label" htmlFor="flexCheckDefault">
          &nbsp; {t('_lottery.windfall.listing.mine')}
        </label>
      </div>
    </div>
  );
};

export default PresaleList;
