import React from "react";
import { Link } from "react-router-dom";
import { images } from "../../../helper";

const NotEnExchange = () => {
  return (
    <section className="pages page-bg-white">
      <div className="position-relative">
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <h1 className="heading">Handling of Funds / Not An Exchange</h1>
              <br />
              <p>
                Welcome to Quinto Games, where transparency and responsible
                gaming are the Company’s top priorities. The Company is
                committed to ensuring that you have a clear understanding of how
                your funds are handled in a world where the convenience of
                cryptocurrencies meets online gaming.
              </p>
              <br />
              <p>
                When you deposit funds into your account, the Company maintains
                those funds in the currency in which they were deposited. The
                Company does not convert or manipulate your funds in any way.
                While the Company does accept multiple cryptocurrencies for
                gaming on its platform, the Company’s primary focus is on
                providing you with an online casino experience, not facilitating
                transfers or acting as a cryptocurrency exchange.
              </p>
              <br />
              <p>
                <strong>Gaming-Centric Platform:</strong> At Quinto Games, the
                Company’s core mission is to provide players with a secure and
                enjoyable gaming experience. The Company has integrated
                cryptocurrencies as a payment option to enhance your convenience
                when playing.
              </p>
              <br />

              <p>
                <strong> Multiple Cryptocurrency Support:</strong> The Company
                does accept a variety of cryptocurrencies to cater to the
                diverse preferences of our players.
              </p>
              <br />
              <p>
                <strong>Non-Exchange Functionality:</strong> Unlike
                cryptocurrency exchanges, the Company’s platform does not
                facilitate the buying, selling, or trading of cryptocurrencies
                among players. The Company does not offer exchange services or
                enable peer-to-peer transfers. Your crypto transactions with the
                Company are solely related to gaming activities, and there are
                stringent security measures in place to safeguard your funds and
                transactions.
              </p>
              <br />
              <p>
                <strong>Fair Wagering: </strong>All wager amounts on the
                platform are presented exclusively using the site's internal
                currency, known as Quintoshi ("
                <span className="q-symbol">&#xE1E0;</span>"), which holds parity
                with the US Dollar. When a wager is placed, the required amount
                of funds is converted from the deposited currency or currencies
                into Quintoshis based on the prevailing exchange rates.
              </p>
              <br />
              <p>
                At Quinto Games, the primary focus is on providing an online
                casino experience that combines the convenience of
                cryptocurrencies with the excitement of gaming. The Company
                wants to emphasize that it is not a cryptocurrency exchange, and
                it does not engage in activities related to cryptocurrency
                trading or transfers among players. Experience responsible
                gaming with the cryptocurrency of your choice.
              </p>
              <br />

              <br />
              <Link className="back-to-doc" to="/">
                <img src={images.svg_ic_arrow_left_black} alt="TODO: Image Title"/>
                &nbsp; Back to documents
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotEnExchange;
