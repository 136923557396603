import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import SelectorCurrency from '../../elements/SelectorCurrency';
import { FundTypes } from '../../utils/Enums';
import * as utils from '../../utils/Utils';
import FiatDeposit from './FiatDeposit';
import CryptoDeposit from './CryptoDeposit';
import "../Accounts.css";

const Deposit: React.FC = () => {
  const { currencies } = useOutletContext<{ currencies: ICurrency[] }>();
  const [selected, setSelected] = useState<ICurrency | undefined>(undefined);

  const accountType = "DEPOSIT";

  // Filter currencies for deposit (exclude HOUSE)
  const depositCurrencies = currencies.filter(currency => currency.fundType !== FundTypes.HOUSE);

  const handleCurrencySelect = (currency: ICurrency) => {
    console.log("handleCurrencySelect triggered with:", currency); // Debugging log
    utils.writeParams(accountType, "acronym", currency.acronym);
    setSelected(currency);
  };

  useEffect(() => {
    const acronym = utils.readParams(accountType, "acronym", null);
    if (acronym) {
      // Find currency matching the acronym
      const foundCurrency = depositCurrencies.find(currency => currency.acronym === acronym);
      if (foundCurrency) {
        console.log("Setting initial selected currency:", foundCurrency); // Debugging log
        setSelected(foundCurrency);
      }
    }
  }, [depositCurrencies]);

  return (
    <div className="outlet-inner">
      <SelectorCurrency
        label="Select Currency"
        currencies={depositCurrencies}
        onSelect={handleCurrencySelect}
        preSelect={selected}
        accountType={accountType}
      />
      {selected && (
        <div>
          {selected.fundType === FundTypes.FIAT ? (
            <FiatDeposit currency={selected} />
          ) : (
            <CryptoDeposit currency={selected} />
          )}
        </div>
      )}
    
    </div>
  );
};

export default Deposit;
