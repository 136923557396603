/**
 * @file Utils.ts
 * @description
 * This utility script provides various functions for interacting with a WebSocket-based
 * application. It includes functions for two-factor authentication checks, fetching user profiles,
 * reading and writing user parameters, handling tab information, managing subscriptions, and more.
 *
 * Overview:
 * - is2FA: Checks if two-factor authentication (2FA) is enabled.
 * - myProfile: Fetches the profile of the current user, including avatar details.
 * - readParams: Reads parameters from the user_params table.
 * - writeParams: Writes parameters to the user_params table and sends a message to the server.
 * - readTab: Reads tab details from the tabs table.
 * - getTabRefId: Retrieves the reference ID for a specific tab.
 * - isSubscribed: Checks if a subscription exists for a given reference ID.
 * - getAvatar: Retrieves the avatar details for a given client reference.
 * - specialTruncate: Truncates a string to a specified length, with an optional suffix length.
 */

import { useLocation } from 'react-router-dom';
import i18n from "i18next";  // Correct import for i18n
import { api, AuthTypes, ClientStatus, MessageTypes, TabTypes } from "./Enums";
import { db, apiRawMessage, apiMessage } from "../WebSocketProvider";
import { formatValue

 } from './Round';
import Decimal from 'decimal.js';
export interface Subscription {
  ref_id: string;
}

export interface Tab {
  ref_id: string;
  tab_name: string;
}

export interface Avatar {
  client_ref: string;
  avatar: string;
  avatar_ndx: number;
  anonymous: boolean;
}

interface Params {
  section: string;
  ident: string;
  value: any;
}

/*
export function avatarPath(profile: IUserProfile): string {
  console.log(`avatarPath.profile`, profile);

  const ndx: number = (profile.ndx as number);
  const ndxString = ndx.toString().padStart(3, '0');
  const result: string = `/avatars/${ndxString}.svg`;
  console.log(`avatarPath returns ${result}`);

  return result;
}
*/

/**
 * Checks if two-factor authentication (2FA) is enabled.
 * @returns {boolean} - Returns true if 2FA is enabled, otherwise false.
 */
export const is2FA = (): boolean => {
  if (!mii)
    return false;
  else
    return mii.authentication === AuthTypes.TOTP;
};

export const isAuthenticated = ():boolean => {
  return mii.status !== ClientStatus.OBSERVER;
};

export const sufficientFunds = (value: number):boolean => {
  const amount = new Decimal(value);
  return quintoshi.balance.greaterThanOrEqualTo(amount);
}

/**
 * Retrieves the enum key corresponding to the given string value in a case-insensitive manner.
 *
 * @template T - The enum type which extends object with string values.
 * @param {T} enumType - The enum type to search in.
 * @param {string} value - The string value to find in the enum.
 * @returns {(keyof T | undefined)} The enum key if found, otherwise `undefined`.
 */
export function enumValueOf<T extends { [key: string]: string }>(enumType: T, value: string): keyof T | undefined {
  const normalizedValue: string = value.toLowerCase();
  for (const key in enumType) {
    if (enumType.hasOwnProperty(key)) {
      const enumValue = enumType[key];
      if (enumValue.toLowerCase() === normalizedValue) {
        return key as keyof T;
      }
    }
  }

  throw new Error("Invalid Enum string");
}

/**
 * Retrieves the profile details for a given client reference.
 * @param {string} clientRef - The client reference to fetch the profile for.
 * @returns {IUserProfile} - Returns the profile details.
 * @throws Will throw an error if the database instance is not available or if the query fails.
 */
export const getProfile = (client: string): IUserProfile => {
  if (!db) throw new Error("Database instance not available");

  const query = `
    SELECT 
      user_name as name, IFNULL(avatar, $dflt) as ndx
    FROM
      avatars
    WHERE
      client_ref = $client;
  `;

  const stmt = db.prepare(query);
  const data = stmt.getAsObject({ $client: client, $dflt: 1 });
  stmt.free();

  const result: IUserProfile = {
    name: data.name,
    ndx:  data.ndx
  };

  return result;
};

/**
 * Reads parameters from the user_params table.
 * @param {string} section - The section of the parameters to read.
 * @param {string} ident - The identifier of the parameter to read.
 * @param {any} [dflt=null] - The default value to return if the parameter is not found.
 * @returns {any | null} - Returns the parameter value if found, otherwise the default value or null.
 * @throws Will throw an error if the database instance is not available or if the query fails.
 */
export const readParams = (section: string, ident: string, dflt: any = null): any | null => {
  if (!db) throw new Error("Database instance not available");

    try {
    const query = `
      SELECT 
        section, ident, value 
      FROM 
        user_params 
      WHERE 
        section = $section AND ident = $ident;
    `;

    const stmt = db.prepare(query);
    const result: Params = stmt.getAsObject({$section: section, $ident: ident});
    stmt.free();
    console.log("readParams", result);
    return result.value !== undefined ? result.value : dflt;

  } catch (error) {
    console.error("Error fetching parameter:", error);
    return null;
  }
};

/**
 * Writes parameters to the user_params table and sends a message to the server.
 * @param {string} section - The section of the parameter.
 * @param {string} ident - The identifier of the parameter.
 * @param {any} value - The value of the parameter.
 * @returns {boolean} - Returns true if the parameter was successfully written, otherwise false.
 * @throws Will throw an error if the database instance is not available or if the query fails.
 */
export const writeInternal = (section: string, ident: string, value: any): boolean => {
  const convertValueToString = (value: any): string => {
    if (value === null || value === undefined) {
      return '';
    } else if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value.toString();
    }
  };

  if (!db) throw new Error("Database instance not available");

  const query = `
    INSERT OR REPLACE INTO user_params 
      (section, ident, value) 
    VALUES 
      ($section, $ident, $value);
  `;

  const params: Params = {
    section: section,
    ident: ident,
    value: convertValueToString(value),
  };

  try {
    console.log(`Utils.writeParams: Writing to database`, params);
    const stmt = db.prepare(query);
    stmt.bind({$section: params.section, $ident: params.ident, $value: params.value}); 
    stmt.step();
    stmt.free();

  } catch (error) {
    console.error("Error writing parameters:", error);
    return false;
  }

  return true;
};

export const writeParams = (section: string, ident: string, value: any): void => {
  const convertValueToString = (value: any): string => {
    if (value === null || value === undefined) {
      return '';
    } else if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value.toString();
    }
  };

  const params: Params = {
    section: section,
    ident: ident,
    value: convertValueToString(value),
  };

  if (writeInternal(section, ident, value) && isAuthenticated())
    apiRawMessage(api.USER_PARAMS, params);
  
};

/**
 * Reads tab details from the tabs table.
 * @param {TabTypes} child - The child tab name.
 * @param {TabTypes} [parent=TabTypes.BASE] - The parent tab name, defaults to TabTypes.BASE.
 * @returns {JsonObject | null} - Returns the tab details if found, otherwise null.
 * @throws Will throw an error if the database instance is not available or if the query fails.
 */
export const readTab = (child: TabTypes, parent: TabTypes = TabTypes.BASE): JsonObject | null => {
  const query = `
    SELECT *
    FROM
      tabs
    WHERE 
      parent_name = $parent AND tab_name = $child;
  `;

  if (!db) throw new Error("Database instance not available");

  console.log("READ TAB - Query", query);

  try {
    const stmt = db.prepare(query);
    const result = stmt.getAsObject({$parent: parent, $child: child});
    stmt.free();
   
    console.log("READ TAB: RESULTS", result);

    if (!result.ref_id) {
      console.log("Got NULL value from reading tabs data");
      return null;
    }

    return result;
  } catch (error) {
    console.error("Error fetching tab details:", error);
    return null;
  }
};

/**
 * Retrieves the reference ID for a specific tab.
 * @param {TabTypes} child - The child tab name.
 * @param {TabTypes} [parent=TabTypes.BASE] - The parent tab name, defaults to TabTypes.BASE.
 * @returns {string | null} - Returns the reference ID if found, otherwise null.
 */
export const getTabRefId = (child: TabTypes, parent: TabTypes = TabTypes.BASE): string | null => {
  console.log("getTabsRefId(child, parent)", child, parent);

  const result = readTab(child, parent);
  console.log("readTab return", result);

  return result ? result.ref_id : null;
};

/**
 * Checks if a given ref_id is subscribed in the subscriptions table.
 * 
 * @param {string} refId - The reference ID to check for subscription.
 * @returns {boolean} - Returns true if the ref_id is subscribed, otherwise false.
 * @throws Will throw an error if the database instance is not available or if the query fails.
 */
export const doSubscribed = (refId: string): number | null => {
  if (!db) throw new Error("Database instance not available");

  const query = `
    SELECT *
    FROM
      subscriptions
    WHERE
      ref_id = $ref;
  `;

  const params: JsonObject = {"ref_id":refId};
  try {
    const stmt = db.prepare(query);
    const result = stmt.getAsObject({ $ref: params.ref_id });
    stmt.free();
    console.log(`Reading Subscription for ${refId}`, result);

    if (result.ref_id == undefined)
      return apiMessage(api.SUBSCRIBE, params);
  } catch (error) {
    console.error("Error fetching subscription details:", error);
  }
  
  return null;  
};

/**
 * Truncates a string to a specified length, with an optional suffix length.
 * @param {string} source - The source string to truncate.
 * @param {number} str_length - The length to truncate the string to.
 * @param {number} [opt_length=0] - Optional length to add after truncation, defaults to 0.
 * @returns {string} - Returns the truncated string.
 */
export const specialTruncate = (
  source: string,
  str_length: number,
  opt_length: number = 0,
): string => {
  if (source.length <= str_length) return source;

  let result = source.substring(0, str_length - (opt_length - 1)) + "…";
  if (opt_length > 0) result = result + " " + source.slice(-opt_length);

  return result;
};

/**
 * Formats a date string to the current locale's date and time format.
 * @param {string} dateString - The date string to format.
 * @returns {string} - The formatted date and time string.
 */
export const formatDateTime = (dateString: string) => {
  const locale = i18n.language; // Get the current language/locale from i18next

  const date = new Date(dateString);
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }).format(date);
};

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return word;

  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const toWords = (qty:number) => {
  const units = [
    i18n.t('_numbers.zero'), 
    i18n.t('_numbers.one'), 
    i18n.t('_numbers.two'), 
    i18n.t('_numbers.three'), 
    i18n.t('_numbers.four'), 
    i18n.t('_numbers.five'), 
    i18n.t('_numbers.six'), 
    i18n.t('_numbers.seven'), 
    i18n.t('_numbers.eight'), 
    i18n.t('_numbers.nine'), 
    i18n.t('_numbers.ten')
  ];

  if (qty == null) return "";

  if (qty < 0 || Math.trunc(qty) !== qty) {
    return i18n.t('Invalid input'); // Handling negative numbers
  } else if (qty <= 10) {
    return `${units[qty]} (${qty})`;
  } else {
    return formatValue(qty, 0);
  }
};

/**
 * Splits a string into chunks of specified size and joins them with spaces.
 * @param str - The string to split.
 * @param chunkSize - The size of each chunk.
 * @returns A formatted string with chunks separated by spaces.
 */
export function formatSeed(str: string, chunkSize: number): string {
  if (chunkSize <= 0) throw new Error("Chunk size must be greater than 0.");
  return str
      .match(new RegExp('.{1,' + chunkSize + '}', 'g')) // Split the string into chunks of the specified size
      ?.join(' ') || ''; // Join the chunks with spaces, handle null or undefined cases
}

function isInteger(value: number): value is Integer {
  return Number.isInteger(value);
}

export function asInt(value: any): Integer {
  if (!isInteger(Number(value))) {
    throw new Error(`Value ${value} is not an integer`);
  }
  return value as Integer;
}

export const formatCountdown = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

// Helper function to ensure we always work with arrays
export function ensureArray(input: any): string[] {
  if (typeof input === 'string') {
    return [input];
  }
  if (Array.isArray(input)) {
    return input;
  }
  throw new Error("Expected a string or an array of strings.");
}

// Other utility functions can be added here
