import React, { useState } from "react";
import { images } from "../../../../helper";

import PoolTicketsCard from "./PoolTicketsCard";
import PoolTicketCompact from "./PoolTicketCompact";
import PaginationCircle from "../../../elements/PaginationCircle";

const PoolTickets = () => {
  const [activeColor, setActiveColor] = useState(1);

  const data = [
    {
      id: 1,
      member: "U7fj HJ5G",
      ticketid: "TICKET ID 5",
      date: "mm_dd_00:00",
      title: "Game #1 Windfall Lottery General Pool",
    },
    {
      id: 2,
      member: "U7fj HJ5G",
      ticketid: "TICKET ID 5",
      date: "mm_dd_00:00",
      title: "",
    },
    {
      id: 3,
      member: "U7fj HJ5G",
      ticketid: "TICKET ID 5",
      date: "mm_dd_00:00",
      title: "",
    },
    {
      id: 4,
      member: "U7fj HJ5G",
      ticketid: "TICKET ID 5",
      date: "mm_dd_00:00",
      title: "Game #1 Windfall Lottery General Pool",
    },
  ];

  const addclass = (id) => {
    document.getElementById(`ticket_none${id}`).classList.remove("d-none");
    document.getElementById(`ticket_none1${id}`).classList.add("d-none");
  };

  const removeclass = (id) => {
    document.getElementById(`ticket_none${id}`).classList.add("d-none");
    document.getElementById(`ticket_none1${id}`).classList.remove("d-none");
  };

  return (
    <div className="pool-members-box">
      {/* <div className="pool-members-card  d-flex justify-content-between align-items-center my-1">
        <div className="d-flex align-items-center ">
            <img src={images.svg_windfall_ellipse} />
            <p className="px-2">Member</p>
        </div>
        <div>
            <label>TICKET ID 5</label>
        </div>
        <div>

            <p>mm_dd_00:00</p>
        </div>
        <div className="d-flex flex-wrap">
            <p className="detail-view">Detail view</p>
            <img src={images.svg_ic_chevron_lw_down} />
        </div>
    </div> */}
      {data.map((item, i) => {
        return (
          <div className="pools-box-container my-3">
            <div
              className="pools-container-column pool-members-card  d-flex justify-content-between align-items-center flex-wrap my-1"
              id={`ticket_none1${item.id}`}
            >
              <div className="d-flex align-items-center py-1">
                <img src={images.svg_windfall_ellipse} 
                alt="TODO: Image Title"
              />
                <p className="px-2">{item.member}</p>
              </div>
              <div className="py-1">
                <label>{item.ticketid}</label>
              </div>
              <div className="py-1">
                <p>{item.date}</p>
              </div>
              <div
                className="d-flex flex-wrap py-1"
                onClick={() => addclass(item.id)}
              >
                <p className="detail-view">Detail view</p>
                <img src={images.svg_ic_chevron_lw_down} 
                alt="TODO: Image Title"
              />
              </div>
            </div>

            <div className="pool-box d-none" id={`ticket_none${item.id}`}>
              <div className="pool-members">
                <div className="pools-container-column pool-members-card  d-flex justify-content-between align-items-center flex-wrap my-1">
                  <div className="d-flex align-items-center py-1">
                    <img src={images.svg_windfall_ellipse}
                alt="TODO: Image Title"
              />
                    <p className="py-1">{item.member}</p>
                  </div>
                  <div className="py-1">
                    <label>{item.ticketid}</label>
                  </div>
                  <div className="py-1">
                    <p>{item.date}</p>
                  </div>
                  <div
                    className="d-flex flex-wrap py-1"
                    onClick={() => removeclass(item?.id)}
                  >
                    <img src={images.svg_windfall_detail} 
                alt="TODO: Image Title"
              />
                  </div>
                </div>

                <div
                  className="nav nav-tabs d-flex justify-content-center"
                  role="tablist"
                >
                  <div
                    className="nav-item lottery-pool-button my-2"
                    role="presentation"
                  >
                    <a
                      className="active"
                      data-bs-toggle="tab"
                      href="#compact1"
                      role="tab"
                      aria-selected="true"
                    >
                      <button
                        className={`${activeColor === 1 ? "button_active" : ""}`}
                        onClick={() => setActiveColor(1)}
                      >
                        Compact
                      </button>
                    </a>
                    <a
                      className=""
                      data-bs-toggle="tab"
                      href="#cards2"
                      role="tab"
                      aria-selected="false"
                      tabindex="-1"
                    >
                      <button
                        className={`${activeColor === 2 ? "button_active" : ""}`}
                        onClick={() => setActiveColor(2)}
                      >
                        cards
                      </button>
                    </a>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active pool-members-compact container"
                    id="compact1"
                    role="tabpanel"
                  >
                    <PoolTicketCompact />
                  </div>

                  <div
                    className="tab-pane fade pool-members-compact container"
                    id="cards2"
                    role="tabpanel"
                  >
                    <PoolTicketsCard />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {/* <div className="pools-box-container my-3">
        <div className="pool-members-card  d-flex justify-content-between align-items-center my-1">
            <div className="d-flex align-items-center ">
                <img src={images.svg_windfall_ellipse} />
                <p className="px-2">Member</p>
            </div>
            <div>
                <label>TICKET ID 5</label>
            </div>
            <div>

                <p>mm_dd_00:00</p>
            </div>
            <div className="d-flex flex-wrap" onClick={()=> addclass()} >
                <p className="detail-view">Detail view</p>
                <img src={images.svg_ic_chevron_lw_down} />
            </div>
        </div>

        <div className="pool-box">
            <div className="pool-members">
                <div className="pool-members-card  d-flex justify-content-between align-items-center my-1">
                    <div className="d-flex align-items-center ">
                        <img src={images.svg_windfall_ellipse} />
                        <p className="px-2">Member</p>
                    </div>
                    <div>
                        <label>TICKET ID 5</label>
                    </div>
                    <div>

                        <p>mm_dd_00:00</p>
                    </div>
                    <div className="d-flex flex-wrap">
                        <img src={images.svg_windfall_detail} />
                    </div>
                </div>

                <div className="nav nav-tabs d-flex justify-content-center" role="tablist">
                    <div className="nav-item lottery-pool-button my-2" role="presentation">
                        <a className="active" data-bs-toggle="tab" href="#compact" role="tab" aria-selected="true">
                            <button className={`${activeColor===1 ? "button_active" : "" }`} onClick={()=>
                                setActiveColor(1)} >Compact</button>
                        </a>
                        <a className="" data-bs-toggle="tab" href="#cards" role="tab" aria-selected="false"
                            tabindex="-1">
                            <button className={`${activeColor===2 ? "button_active" : "" }`} onClick={()=>
                                setActiveColor(2)} >cards</button>
                        </a>

                    </div>
                </div>
                <div className="tab-content">
                    <div className="tab-pane fade show active pool-members-compact container" id="compact"
                        role="tabpanel">
                        <PoolTicketCompact />
                    </div>

                    <div className="tab-pane fade pool-members-compact container" id="cards" role="tabpanel">
                        <PoolTicketsCard />
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div className="pool-members-card  d-flex justify-content-between align-items-center my-1">
        <div className="d-flex align-items-center ">
            <img src={images.svg_windfall_ellipse} />
            <p className="px-2">Member</p>
        </div>
        <div>
            <label>TICKET ID 5</label>
        </div>
        <div>

            <p>mm_dd_00:00</p>
        </div>
        <div className="d-flex flex-wrap">
            <p className="detail-view">Detail view</p>
            <img src={images.svg_ic_chevron_lw_down} />
        </div>
    </div>

    <div className="pool-members-card  d-flex justify-content-between align-items-center my-1">
        <div className="d-flex align-items-center ">
            <img src={images.svg_windfall_ellipse} />
            <p className="px-2">Member</p>
        </div>
        <div>
            <label>TICKET ID 5</label>
        </div>
        <div>

            <p>mm_dd_00:00</p>
        </div>
        <div className="d-flex flex-wrap">
            <p className="detail-view">Detail view</p>
            <img src={images.svg_ic_chevron_lw_down} />
        </div>
    </div> */}

      <PaginationCircle className2="page-link-circlewhite" />
    </div>
  );
};

export default PoolTickets;
