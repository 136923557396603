export interface IPlaceholder {
  type: 'footnote' | 'link';
  href: string;
  content: string;
}
export const renderPlaceholders = (text: string, placeholders: { [key: string]: IPlaceholder }, navigate: Function) => {
  const parts = text.split(/(\{\{[^}]+\}\})/).map((part, index) => {
    if (part.startsWith('{{') && part.endsWith('}}')) {
      const key = part.slice(2, -2);
      const placeholder = placeholders[key];
      if (placeholder) {
        if (placeholder.type === 'link') {
          return (
            <span 
              key={index} 
              style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} 
              onClick={() => navigate(placeholder.href)}
            >
              {placeholder.content}
            </span>
          );
        } else if (placeholder.type === 'footnote') {
          return (
            <sup key={index}>
              <a href={placeholder.href} className="footnote">{placeholder.content}</a>
            </sup>
          );
        }
      }
    } else if (/<\/?[a-z][\s\S]*>/i.test(part)) { // Check if the part contains HTML tags
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    }
    return part;
  });
  return <>{parts}</>;
};
