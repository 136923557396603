import { Collapse } from "react-bootstrap";

const FAQCollapsable = (props) => {
  // useEffect(()=>{
  //   setOpen(props.open || false)
  // }, [props])

  const getInitialWords = (string, numWords) => {
    const words = string.split(" ");
    return words.slice(0, numWords).join(" ");
  };

  return (
    <>
{/*    
      <div className={`faqs-section ${!open ? "collapsed" : ""}`}>
        <h2
          className="faqs-heading"
          //onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <span className="text">{props.q}</span>
          <span className="icon-plus arrow" role="none"></span>
        </h2>

        <div className="divider" />

        <Collapse in={open}>
          <div id="example-collapse-text">
            <div className="box-desc">{props.children}</div>
          </div>
        </Collapse>

        <a onClick={() => setOpen(!open)} className="read-more-less">
            {open ? t("_collasable.read_less") : t("_collasable.read_more") }
        </a> 
      </div>
*/}      
    </>
  );
 
};

export default FAQCollapsable;
