import React, { useEffect } from "react";
import Select, { SingleValue } from "react-select";
import { images } from "../../helper";
import { formatValue } from "../utils/Round";
import "./Selector.css";

interface SelectorCurrencyProps {
  label: string;
  className?: string;
  onSelect?: (currency: ICurrency) => void;
  preSelect?: ICurrency;
  currencies: ICurrency[];
  onToggle?: (isOpen: boolean) => void;
  accountType: "DEPOSIT" | "WITHDRAWAL";
}

export const SelectorCurrency: React.FC<SelectorCurrencyProps> = ({
  label,
  className,
  onSelect,
  preSelect,
  currencies,
  onToggle,
  accountType,
}) => {
  const [selected, setSelected] = React.useState<ICurrency | undefined>(preSelect);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  useEffect(() => {
    setSelected(preSelect);
  }, [preSelect]);

  const handleSelectChange = (newValue: SingleValue<ICurrency>) => {
    if (newValue) {
      setSelected(newValue);
      onSelect && onSelect(newValue);
    } else {
      setSelected(undefined);
    }
    setIsOpen(false); // Close the dropdown after selection
  };

  const handleExpandCollapse = () => {
    setIsOpen(!isOpen);
    onToggle && onToggle(!isOpen);
  };


  const customSelected = ({ data }: { data: ICurrency }) => (
    <div className={`selector disabled`}>
      <div className={`selector-item selector-item-currency`}>
        <img className="icon" src={data.icon} alt={data.descr} /> &nbsp;
          {data.descr} ({data.acronym})
          {accountType === "WITHDRAWAL" && (
            <span className="selector-item-right">
              {formatValue(data.balance.toNumber(), data.scale)}
            </span>
          )}
      </div>
    </div>
  );
  
  const customSingleValue = ({ data }: { data: ICurrency }) => (
    <div className={`selector selected`}>
      <div className={`selector-item selector-item-currency`}>
        <img className="icon" src={data.icon} alt={data.descr} /> &nbsp;
          {data.descr} ({data.acronym})
          {accountType === "WITHDRAWAL" && (
            <span className="selector-item-right">
              {formatValue(data.balance.toNumber(), data.scale)}
            </span>
          )}
      </div>
    </div>
  );

  const customOption = ({ data }: { data: ICurrency }) => (
    <div className={`selector ${selected === data ? 'selected' : ''}`} onClick={() => handleSelectChange(data)}>
      <div className="selector-item selector-item-currency">
        <img className="icon" src={data.icon} alt={data.descr} /> &nbsp;
        {data.descr} ({data.acronym})
        {accountType === "WITHDRAWAL" && (
          <span className="selector-item-right">
            {formatValue(data.balance.toNumber(), data.scale)}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className={`selector-container`}>
      <div  className="selector-expander" onClick={handleExpandCollapse}>
        <label>{label || "Select"}</label>
        <div className="icon">
          <img src={isOpen ? images.svg_ic_chevron_up : images.svg_ic_chevron_down} alt="toggle" />
        </div>
      </div>

      {!isOpen && selected && customSelected({ data: selected })}

      <Select
        className={`qselect ${isOpen ? "qselect-active" : ""}`}
        defaultMenuIsOpen={isOpen}
        menuIsOpen={isOpen}
        options={currencies}
        defaultValue={selected}
        value={selected}
        onChange={(e) => {
          handleSelectChange(e as SingleValue<ICurrency>);
        }}
        getOptionLabel={(currency: ICurrency) => currency.descr}
        getOptionValue={(currency: ICurrency) => currency.acronym}
        components={{ SingleValue: customSingleValue, Option: customOption }}
        isClearable
      />
    </div>
  );
};

export default SelectorCurrency;
