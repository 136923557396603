export enum api {
  // Define your events here
  // Misc
  GOODBYE = 'goodbye', // Send to close socket on server… params {}
  OPEN_SESSION = 'open_session', // Mandatory call upon socket connection (Once Only)¸ params {}
  SUBSCRIBE = 'subscribe', // Subscribe to sections to receive data… params {ref_id}
  UNSUBSCRIBE = 'unsubscribe', // Unsubscribe from sections to stop receive data… params {ref_id}
  SUPPORT_REQUEST = 'support_request', // Message to Support… params {name, email, subject, question}
  USER_PARAMS = 'write_param', // Store information client want to save… params {section , ident, value}

  // Member
  // Account access
  FORGOT_PASSWORD = 'forgot_password', // params {name_email}
  FORGOT_NAME = 'forgot_name', // params {email_address}… Should be deprecated
  GOOGLE_LOGIN = 'google_login', // params {token, type}
  LOGIN = 'player_login', // params {email, password}
  REGISTER = 'player_signup', // params {email, name, password, opt_in}
  RESET_PASSWORD = 'reset_password', // params {password, [pin_code]}

  // Profile
  CHANGE_ANONYMOUS = 'change_anonymous', // Toggle Private/Public… params {}
  CHANGE_AVATAR = 'change_avatar', // Avatar file name… params {avatar}
  CHANGE_EMAIL = 'change_email', // params {email_address, password, [code]}
  CHANGE_PASSWORD = 'change_password', // params {old_password, password, [code]}
  EMAIL_VALIDATION = 'email_validation', // params {pin_code}
  RESEND_PIN = 'resend_email_pin', // params {}
  TOTP_AUTH = 'totp_authentication', // params {code}

  // Accounting
  REQUEST_ADDRESS = 'request_address', // params {acronym}
  SECURE_ADDRESS = 'secure_address', // params {acronym, address, [nickname], [exclusive], [doomsday], [remove]}
  WITHDRAWAL_REQUEST = 'withdrawal_request', // params {acronym, address, amount, password, [code]}

  // Games
  // BINGO
  BINGO_AUTOWAGER = 'bingo_autowager', // params {def_id, [games]} …Bingo Autowager for set number of game or unlimited
  BINGO_KEEP_CARDS = 'bingo_keep_cards', // params {def_id} …Player was to keep the same cards
  BINGO_NEW_CARDS = 'bingo_new_cards', // params {game_id} …Player wants a new set of cards
  BINGO_PURCHASE = 'bingo_purchase', // params {def_id, buyins} …This is also used to increase player cards
  BINGO_REFUND = 'bingo_refund', // params {def_id}  …Refund are allowed if game cycles

  // LOTTERY
  LOTTERY_ADD_PICK = 'lottery_add_pick', // params {def_id, picks, [box]} …Box is only for Quinto
  LOTTERY_CLEAR = 'lottery_clear_cart', // params {def_id} …Clears all picks in cart
  LOTTERY_CREATE_POOL = 'lottery_create_pool', // params {def_id, descr, [min_picks], [max_picks], [password]}
  LOTTERY_JOIN_POOL = 'lottery_pool', // params {pool_id, [password]}
  LOTTERY_PRESALE = 'windfall_presale', // params {quantity} …Windfall first game only
  LOTTERY_PURCHASE = 'lottery_purchase', // params {ref_id, [games]} …Reference ID is def_id or pool_id
  LOTTERY_QUICK_PICK = 'lottery_quick_pick', // params {def_id, [qty]} …Will return max picks if no quantity
  LOTTERY_TICKET_PICKS = 'lottery_retrieve_picks', // params {game_id, ticket_id}

  // TRIMORRA
  TRIMORRA_BUYIN = 'trimorra_buyin', // params {room_id}
  TRIMORRA_GAME = 'trimorra_game', // params {game_id} …No Yet Implemented as May 2024
  TRIMORRA_REFUND = 'trimorra_refund', // params {room_id}
  TRIMORRA_PAYOUT = 'trimorra_completed', // params {game_id} …This will pay the player and update balance
}

export enum ClientStatus {
  OBSERVER = 'OBSERVER',
  ACTIVE = 'ACTIVE',
  UNVERIFIED = 'UNVERIFIED',
  BLOCKED = 'BLOCKED'
}

export enum MessageTypes {
  // These are Special MessageTypes
  MII = 'mii',
  ERROR = 'error',
  EMPTY = 'empty',
  REPLY = 'reply',
  TODO = 'TODO',
  OPEN = 'opened',
  DATA = 'data',

  // These MessageTypes should be the same as database tables
  PARAMS = 'user_params',
  SUBSCRIBE = 'subscriptions',
  SITE = 'site_info',
  TABS = 'tabs',
  AVATAR = 'avatars',
  CLIENT = 'client',
  BALANCE = 'client_balances',
  JOURNAL = 'journal',

  CRYPTO_ADDRESS = 'deposit_addresses',
  
  // LOTTERY TABLES
  LOTTERY_GAME = 'lottery_games',
  PRESALE = 'windfall_presales',

  // TRIMORRA TABLES
  TRIMORRA          = 'trimorra_defs',
  TRIMORRA_TIERS    = 'trimorra_tiers',
  TRIMORRA_SEEDS    = 'trimorra_seeding',
  TRIMORRA_ROOMS    = 'trimorra_rooms',
  TRIMORRA_PAYOUTS  = 'trimorra_payouts',
  TRIMORRA_PLAYERS  = 'trimorra_players',
  TRIMORRA_GAME     = 'trimorra_games',
  TRIMORRA_GROUP    = 'trimorra_grouping',
  TRIMORRA_HISTORY  = 'trimorra_history',
}

export enum ModeTypes {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  UPSERT = 'UPSERT',
  REMOVE = 'REMOVE'
}

export enum TabTypes {
  BASE = 'BASE',
  BINGO = 'BINGO',

  LOTTERY = 'LOTTERY',
  EASY4 = 'EASY4',
  LOTTO555 = 'LOTTO555',
  SUPER10 = 'SUPER10',
  QUINTO = 'QUINTO',
  FAUCET = 'FAUCET',
  WINDFALL = 'WINDFALL',

  TRIMORRA = 'TRIMORRA',
}

export enum AuthTypes {
  NONE = 'SIMPLE',
  TOTP = 'TOTP',
}

export enum FundTypes {
  ALL = '',
  HOUSE = 'HOUSE',
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO',
  POKER = 'POKER',
}

type AccountListTypes = {
  toString: string;
  query: string;
};

export const AccountTypes: { [key: string]: AccountListTypes } = {
  BASIC: {
    toString: 'BASIC',
    query: '',
  },
  
  DEPOSIT: {
    toString: 'DEPOSIT',
    query: `
      SELECT 
        acronym, fund_type, description
      FROM
        client_balances
      WHERE
        fund_type IN ('FIAT', 'CRYPTO')
      ORDER BY
        seq_nbr;
    `,
  },

  WITHDRAWAL: {
    toString: 'WITHDRAWAL',
    query: `
      SELECT 
        acronym, 
        fund_type, 
        description, 
        exchange_rate, 
        min_withdrawal,
        balance + 
          COALESCE(
            (SELECT
              ((balance - pending) / cb.exchange_rate)
            FROM
              client_balances
            WHERE
              acronym = 'QVC'
            ), 
          0) AS balance
      FROM
        client_balances cb
      WHERE
        fund_type IN ('FIAT', 'CRYPTO')
      ORDER BY
        seq_nbr;
    `,
  },
};


