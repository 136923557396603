import React from "react";
import PayoutTable from "./PayoutTable";
import WaitingList from "./WaitingList";
import { IRoom } from "./TrimorraBase";
import "./Trimorra.css";

interface IRoomInfoProps {
  room: IRoom | null;
}

const RoomInformation: React.FC<IRoomInfoProps> = ({ room }) => {
  if (! room) return null;

  return (
    <div className="trimorra-details-container">
      <div className="trimorra-details-section">
        <PayoutTable room={room} />
      </div>
      <div className="trimorra-details-section">
        <WaitingList room_id={room.id} />
      </div>
    </div>
  );
};

export default RoomInformation;
