import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap"; // Import Modal from react-bootstrap
import { MessageTypes, api } from "../utils/Enums";
import { apiRawMessage, onBalanceChange, onData } from "../WebSocketProvider";
import { formatValue } from "../utils/Round"; // Assuming formatValue is imported correctly
import QSymbol from "../utils/QSymbol";
import AvatarSelector from "./AvatarSelector"; // Make sure the path is correct
import AvatarImage from "../elements/AvatarImage";
import "../../assets/css/avatar.css";

const ProfileHeader: React.FC = () => {
  const { t } = useTranslation();

  const [replyId, setReplyId] = useState<number | null>(null);
  const [anonymous, setAnonymous] = useState<boolean>(mii.anonymous);
  const [avatar, setAvatar] = useState<IUserProfile>(mii.profile);
  const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false);

  useEffect(() => {
  }, [quintoshi.balance]);

  useEffect(() => {
    setAnonymous(mii.anonymous);    
  }, [anonymous]);

  useEffect(() => {
    const handleUpdate = (data: any) => {
      setAvatar(data.profile);
    }    
    setAvatar(mii.profile);

    onData.on(MessageTypes.MII, handleUpdate);
    return (() => {
      onData.off(MessageTypes.MII, handleUpdate);
    });
  }, []);

  const submitPrivateChange = () => {
    const newAnonymousValue = !mii.anonymous;
    setAnonymous(newAnonymousValue); // Update the state
    mii.anonymous = newAnonymousValue; // Update the global variable
    apiRawMessage(api.CHANGE_ANONYMOUS, {}); // Send the API request
  };

  return (
    <section>
      <div className="user_account_main">
        <div className="user_account">
          <div className="container banner_top_text">
            <div className="row">
              <div className="col-12">
                <div className="card card-profile">
                  <div className="card-body d-flex">
                    <div className=".outlet-inner">
                      <AvatarImage
                        profile={mii.profile}
                        className={'avatar-image avatar-XXL'}
                      />
                    </div>
                    <div className="profile-info">
                    <h1 className="text-capitalize">{mii.profile.name || 'N/A'}</h1>
                      <label>
                        <strong>
                          {t("_profile.player_id", {id: mii.client_ref || ''})}
                        </strong>
                      </label>
                      <br />
                      <strong>{mii.email_address || "No Email Address"}</strong>
                      <hr />

                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          checked={anonymous}
                          onInput={submitPrivateChange}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                        <label
                          className="form-check-label m-0"
                          htmlFor="flexSwitchCheckDefault"
                        >
                         {t("_profile.private")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-10">
                <div className="card card-profile">
                  <div className="card-body qab">
                    <label className="txt-avl-bal">
                      {t("_profile.balance")}
                    </label>
                    <div className="">
                      <label>
                        <QSymbol value={quintoshi.text}/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProfileHeader;
