import { useTranslation } from "react-i18next";
import AlertMessage, {AlertMessageType } from "../elements/AlertMessage";
import { QCardHeaders } from "./QCardHeaders";
import QButton from "../elements/QButton";

/* NOT IN USE */
export const ValidatePassword = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="card user-account-tabs-card">
      <div className="card-body">
        <QCardHeaders
          title={t("_profile.validate_password.title")}
          subTitle={t("_profile.validate_password.subTitle")}
          desc={t("_profile.validate_password.desc")}
        />

        <div className="card-content">
          <input
            className="form-control form-control-lg"
            placeholder={t("_profile.validate_password.plc.vp")}
          />

          <AlertMessage
            className="mt-4"
            type={AlertMessageType.WARNING}
            message=""
          />

          <br />

          <QButton block text={t("_profile.btn.validate_password")} />
        </div>
      </div>
    </div>
  );
};
