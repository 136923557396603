import { useTranslation } from "react-i18next";
import { PageHeader } from "./PageHeader";
import { QPlayNow } from "./QPlayNow";
import { Link } from "react-router-dom";
import { images } from "../../../helper";

const PrizePayoutTable = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="pages">
      <PageHeader title="DOCUMENTS" />

      <div className="position-relative mh-1200">
        <div className="container">
          <div className="row spacerT">
            <div className="col-md-7">
              <label className="small_heading">
                {t("_page_prize_table.sub_heading")}
              </label>
              <h1 className="heading">{t("_page_prize_table.heading")}</h1>
              <br />
              <p>{t("_page_prize_table.line_1")}</p>

              <div className="line-infront-icon d-flex">
                <div className="rect-icon"></div>
                <div className="">
                  <p>{t("_page_prize_table.line_1")}</p>
                </div>
              </div>
              <div className="line-infront-icon d-flex">
                <div className="rect-icon"></div>
                <div className="">
                  <p>{t("_page_prize_table.line_1")}</p>
                </div>
              </div>
              <div className="line-infront-icon d-flex">
                <div className="rect-icon"></div>
                <div className="">
                  <p>{t("_page_prize_table.line_1")}</p>
                </div>
              </div>
              <br />
              <p>{t("_page_prize_table.line_1")}</p>
              <br />
              <p>{t("_page_prize_table.line_1")}</p>
              <br />
              <p>{t("_page_prize_table.line_1")}</p>
              <br />
              <p>{t("_page_prize_table.line_1")}</p>

              <br />

              <Link className="back-to-doc" to="/">
                <img src={images.svg_ic_arrow_left_black} alt="TODO: Image Title"/>
                &nbsp; Back to documents
              </Link>
            </div>
          </div>
        </div>

        <QPlayNow />
      </div>
    </section>
  );
};

export default PrizePayoutTable;
