import React, { useState, useEffect } from "react";

export enum AlertMessageType {
  SUCCESS = 0,
  ERROR = 1,
  WARNING = 2,
  WHITE_WARNING = 3,
}

interface AlertMessageProps {
  type?: AlertMessageType;
  outline?: boolean;
  message?: string;
  className?: string;
  pClass?: string;
}

const AlertMessage: React.FC<AlertMessageProps> = (props) => {
  const [type, setType] = useState<AlertMessageType>(AlertMessageType.SUCCESS);
  const [outline, setOutline] = useState<boolean>(false);

  useEffect(() => {
    setType(props.type || AlertMessageType.SUCCESS);
    setOutline(props.outline || false);
  }, [props]);

  const getClass = () => {
    if (type === AlertMessageType.WHITE_WARNING) {
      return "white-warning";
    }

    if (outline) {
      if (type === AlertMessageType.SUCCESS) return "outline-success";
      if (type === AlertMessageType.WARNING) return "outline-warning";
      if (type === AlertMessageType.ERROR) return "outline-danger";
    }

    if (type === AlertMessageType.SUCCESS) return "success";
    if (type === AlertMessageType.WARNING) return "warning";
    if (type === AlertMessageType.ERROR) return "danger";
  };

  return props.message ? (
    <div className={`alert alert-${getClass()} ${props.className || ""}`}>
      <p className={`${props.pClass || ""}`}>{props.message}</p>
    </div>
  ) : null;
};

export default AlertMessage;