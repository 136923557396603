import React from 'react';
import { useTranslation } from "react-i18next";
import { ensureArray } from '../../utils/Utils';

interface FiatDepositProps {
  currency: ICurrency;
}

const FiatDeposit: React.FC<FiatDepositProps> = ({ currency }) => {
  const { i18n, t } = useTranslation();

  const message = ensureArray(t('_ACCOUNTING.deposit.fiat.message', { returnObjects: true })).join('');
  return (
    <div>
      <h2>{t('_ACCOUNTING.deposit.fiat.title')}</h2>
      <p></p><br/>
      <p>{message}</p>
    </div>
  );
};

export default FiatDeposit;
