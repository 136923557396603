import { images } from "../../helper";

export const NotificationBell = () => {
  return (
    <div className="notification-bell">
      <img src={images.svg_notification_bell} />
      {/* <span className="badge">0</span> */}
    </div>
  );
};
