import { images } from "../../../../helper";
import QButton from "../../../elements/QButton";
import TicketQuantity from "../../../elements/TicketQuantity";
import PersonalYourTickets from "./PersonalYourTickets";

const PersonalTickets = () => {
  return (
    <div>
      <div className="create-lottery-pool mt-4">
        <div className="create-lottery-section">
          <div className="create-lottery-box d-flex justify-content-around align-items-around py-3 px-2">
            <div>
              <label>Game Number</label>
              <label>1</label>
            </div>
            <div className="border mx-2"></div>
            <div>
              <label>Game Hash</label>
              <label>
                1e41e611f4b66041f7d7e5d4556e8603ee8736bcc931784ae4c6c40e428d3bc2
              </label>
            </div>
            <div className="border mx-2"></div>
            <div>
              <label>Game Status</label>
              <label>
                {" "}
                <label className="circle-status mx-2"></label>Open
              </label>
            </div>
          </div>
          {/* <div className="purchase-ticket"> */}
          <div className="purchase-ticket-column d-flex justify-content-around mb-3">
            <div style={{ flex: "20%" }}>
              <TicketQuantity label_3="Ticket Quantity" />
            </div>
            <div className="min-purchase-card flex-grow-1 mx-2">
              <div>
                <label>Min Purchase 1</label>
              </div>
              <div className="border mx-2"></div>
              <div>
                <label>Max Purchase 1000</label>
              </div>
              <div className="border mx-2"></div>
              <div>
                <label>
                  Cost Per Ticket &nbsp; 
                  <img src={images.svg_quintos_white}
                    alt="TODO: Image Title"
                  />{" "}
                  &nbsp; 25
                </label>
              </div>
            </div>
          </div>
          <QButton className="create-lottery-button my-3" noIcon={false}>
            CREATE LOTTERY POOL
          </QButton>

          {/*
            </div> */}
        </div>
      </div>

      <PersonalYourTickets />
    </div>
  );
};

export default PersonalTickets;
