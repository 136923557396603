import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import AlertMessage, {AlertMessageType } from "../elements/AlertMessage";

import { apiMessage, onData } from "../WebSocketProvider";
import { MessageTypes, api } from "../utils/Enums";

export const EmailValidation = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [replyId, setReplyId] = useState(null);
  const [code, setCode] = useState("");

  const [messageType, setMessageType] = useState(AlertMessageType.WARNING);
  const [message, setMessage] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("pin_code")) {
      setCode(searchParams.get("pin_code").replace(/ /g, ""));
    } else {
      navigate("/not-found");
    }
  }, [searchParams, navigate]);

  useEffect(() => {

    setReplyId(apiMessage(api.EMAIL_VALIDATION, { pin_code: code }));
  }, [code]);

  onData.addListener(MessageTypes.SITE_INFO, (arg) => {
    if (arg.id !== replyId) return;

    if (arg.hasOwnProperty("error")) {
      setMessageType(AlertMessageType.WARNING);
      setMessage(arg.error.reason);
    } else {
      setMessageType(AlertMessageType.SUCCESS);
      setMessage("Email has been validated.");
    }

    setReplyId(null);
  });

  return (
    <div className="container">
      <div className="row">
        <div className="login_main">
          <div>
            <div className="sign_title text-center col-12 mb-md-5 mb-4">
              <p>{t("_reset_pwd.heading")}</p>
              <h2>Email Validation</h2>
            </div>
            <div className="login_page_main d-lg-flex justify-content-between">
              <div className="offset-md-4 col-xl-4 col-lg-5 col-md-12 flex-column justify-content-center mb-5 mb-lg-0">
                <div className="lo_leftside">
                  <div className="text-center">{replyId && "Loading..."}</div>

                  <AlertMessage type={messageType} message={message} />
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link className="go_back_to_main" to="/login">
                  {t("_forgot_pwd.back_to_login")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
