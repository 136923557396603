import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { apiMessage, db, onData } from "../WebSocketProvider";
import AvatarImage from "../elements/AvatarImage";
import { MessageTypes, api } from "../utils/Enums";
import * as utils from "../utils/Utils";
import { QCardHeaders } from "./QCardHeaders";
import "./AvatarSelector.css";


interface ReplyData {
  id: number;
}

const AvatarSelector: React.FC = () => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState<string[]>([]);
  const [selCategory, setCategory] = useState<string | undefined>(undefined)
  const [selected, setSelected] = useState<Integer | undefined>(undefined);
  const [avatarList, setAvatarList] = useState<Integer[]>([]);

  const fetchCategories = () => {
    if (!db) throw new Error("SQLite database instance not available");

    // Initialize categories with 'DEFAULT'
    const rows = ['DEFAULT'];

    const query = `
      SELECT 
        DISTINCT category 
      FROM 
        avatar_images 
      WHERE 
        category <> 'DEFAULT' 
      ORDER BY 
        category;
    `;

    let stmt;

    try {
      stmt = db.prepare(query);
      while (stmt.step()) {
        const row = stmt.getAsObject();
        rows.push(row.category);
      }
    } catch (err) {
      console.error("Error fetching categories:", err);
    } finally {
      if (stmt) stmt.free();
    }

    // Set the categories state
    setCategories(rows);
  };

  const handleFilter = (category: string) => {
    if (category === selCategory) return;
    
    /* We need to send the last category and selected to server */
    if (selCategory && selected)
      utils.writeParams(MessageTypes.AVATAR, selCategory, selected);

    const query = `
      SELECT id 
      FROM avatar_images 
      WHERE category = ?;
    `;

    let stmt;
    const ids = [];

    try {
      stmt = db.prepare(query);
      stmt.bind([category]);
      while (stmt.step()) {
        const row = stmt.getAsObject();
        ids.push(utils.asInt(row.id));
      }
    } catch (err) {
      console.error(`Error fetching avatars for category ${category}:`, err);
    } finally {
      if (stmt) stmt.free();
    }

    setCategory(category);
    const ndx = utils.asInt(utils.readParams(MessageTypes.AVATAR, category, ids[0]));
    console.log(`New Category ${category} ${ndx}`);
    setSelected(ndx);

    setAvatarList(ids);
  };

  const handleAvatarSelect = (avatar: Integer) => {
    if (avatar === selected) return;

    if (selCategory)
      utils.writeInternal(MessageTypes.AVATAR, selCategory, avatar);
    setSelected(avatar);
  };

  const saveAvatar = () => {
    // Implement the logic to save the selected avatar
    console.log(`Avatar ${selected} saved!`);
    apiMessage(api.CHANGE_AVATAR, {avatar: selected});
  };

  // Load user profile and initialize state
  useEffect(() => {
    const handleReply = (data: any) => {
      if (!data.id) return;
    };

    fetchCategories();
    handleFilter(utils.readParams(MessageTypes.AVATAR, "category", "DEFAULT"));

    onData.on(MessageTypes.AVATAR, handleReply);
    return () => {
      console.log(`Sending writeParams ${selCategory} ${selected}`);
      if (selCategory)
        utils.writeParams(MessageTypes.AVATAR, selCategory, selected);

      onData.off(MessageTypes.AVATAR, handleReply);
    };
  }, []);

  return (
    <div className="card user-account-tabs-card">
      <div className="card-body">
        <QCardHeaders
          title="CHANGE AVATAR"
          subTitle="Change Your Avatar"
          desc="Please select an avatar of your choosing."
        />
        <hr/>
        <div className="avatar-selector-wrapper">        
          <div className="avatar-save-container">
            {selected && (
              <AvatarImage
                profile={{ name: 'selected', ndx: selected }}
                className="avatar-image avatar-XXL"
              />
            )}
            
            <Button variant="primary"
              className={`default-submit-button`}
              onClick={saveAvatar} 
              disabled={!selected || selected === mii.profile.ndx}
            >
              {t('_avatar.save')}
            </Button>
          </div>
          
          <div className="category-buttons-container">
            {categories.map((category) => (
                <button
                key={category} // Add key prop for list rendering
                className={`default-selection-tab ${category === selCategory ? "selected" : ""}`}
                onClick={() => handleFilter(category)}
              >
                {t(`_avatar.category.${category.toLowerCase()}`)}
              </button>
          ))}
          </div>
        </div>

        <div className="card-content">
          {avatarList.map(id => (
            <AvatarImage
              key={id}
              profile={{ name: `avatar-${id}`, ndx: id }}
              className={`avatar-image avatar-circle avatar-large ${selected === id ? "selected" : ""}`}
              onClick={() => handleAvatarSelect(id)}
            />
          ))}          
        </div>
      </div>      
    </div>
  );
};

export default AvatarSelector;