import React, { useEffect, useState } from "react";
import { images } from "../../helper";
import { onBalanceChange, onData } from "../WebSocketProvider";
import ProfileDropDown from "../elements/ProfileDropDown";
import { formatValue } from "../utils/Round";
import { MessageTypes } from "../utils/Enums";
import QSymbol from "../utils/QSymbol";
import AvatarImage from "../elements/AvatarImage";
import "../../assets/css/avatar.css";

interface ProfileNavigationProps {
  onLinkClicked: () => void;
}

export const ProfileNavigation: React.FC<ProfileNavigationProps> = (props) => {
  const [balance, setBalance] = useState<string>(quintoshi.text);
  const [profile, setProfile] = useState<IUserProfile>(mii.profile);

  useEffect(() => {
    const handleUpdate = (data: any) => {
      setProfile(data.profile);
    }

    const handleBalance = () => {
      setBalance(quintoshi.text);
    };

    onBalanceChange.on(MessageTypes.DATA, handleBalance);
    onData.on(MessageTypes.MII, handleUpdate);

//    setBalance(formatValue(quintoshi.balance));

    return () => {
      onBalanceChange.off(MessageTypes.DATA, handleBalance);
      onData.off(MessageTypes.MII, handleUpdate);
    };
  }, []);


  console.log("Rendering ProfileNavigation", { profile, balance });

  return (
    <div className="profile-top-card">
      <ProfileDropDown
        onLinkClicked={props.onLinkClicked}
        className="profile-dropdown"
      >

      <AvatarImage
        profile={profile}
        className="avtr avatar-image avatar-profiler"
      />
      <span>{profile.name}</span> &nbsp;
      
      <img src={images.svg_arrow_down} alt="↓" />
      <div className="qpoints">
        <label className="">
          <QSymbol value={balance} />
        </label>
      </div>

      </ProfileDropDown>
    </div>
  );
};

export default ProfileNavigation;
