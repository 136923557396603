import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { apiMessage, db, onData } from "../../WebSocketProvider";
import AlertMessage, { AlertMessageType } from '../../elements/AlertMessage';
import { MessageTypes, TabTypes, api } from "../../utils/Enums";
import QSymbol from "../../utils/QSymbol";
import { formatValue } from "../../utils/Round";
import * as utils from "../../utils/Utils";
import RoomInformation from "./RoomInformation";
import { dbITrimorraRoom } from "./TrimorraDBI";
import { IRoom, ITier, getTierName, getRoom, mapRoom } from "./TrimorraBase";
import WagerButtonManger from "./WagerButtonManger";
import "./Trimorra.css";

interface IWagersProps {
  tier: ITier;
}

const WagerSelection: React.FC<IWagersProps> = ({ tier }) => {
  const { t } = useTranslation();

  // Error Handling
  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [replyId, setReplyId] = useState<number | null>(null);
  const [selected, setSelected] = useState<IRoom | null>(null);
  
  let loading:number | null = null;
  let holdings: IRoom[] = ([]);
  
  const isParticipant = (): boolean => {
    let result: boolean = false;

    if (selected) 
      result = !!selected.participant;

/*
    if (selected) {
      const room = getRoom(selected.room_id);
      if (room && (room.participant !== selected.participant)) {
        //console.log(`TrimorraWagerSelection.isParticipant… mismatch on selected vs room`);
        result = !!room.participant;
        setSelected(room);
      }
    };
*/
    return result;
  }

  const setGameHash = (room: IRoom) => {
    setMessage(undefined);
    if (room.hash) {
      setMessage(utils.formatSeed(room.hash, 4));
      setMessageType(AlertMessageType.SUCCESS);
    }
  }

  const handleBuyinChange = (room: IRoom, isDoubleClick: boolean) => {
    const doDoubleClick = () => {
      console.log(`  …Authenticate ${utils.isAuthenticated()} and participant A${!(!!room.participant)} B${!!room.participant === false}`);
      if (utils.isAuthenticated() && !(!!room.participant)) {
        console.log("Sending Buyin");
        setReplyId(apiMessage(api.TRIMORRA_BUYIN, {room_id: room.id}));          
      }
    };

    const commonActions = () => {
      loading = null;

      onData.off(MessageTypes.REPLY, handleLoading);
      setSelected(room);
      setGameHash(room);
      
      console.log(`Wager.handleBuyinChange.commonActions: Double Click ${isDoubleClick}`);
      if (isDoubleClick) 
        doDoubleClick();        
    };

    const handleLoading = (data: IData) => {
      if (data.id && data.id === loading) 
        commonActions();
    };

    if (loading || (room === selected && !isDoubleClick)) return;

    if (room === selected && isDoubleClick) {
      doDoubleClick();
      return;
    }
    
    console.log(`Double click on room ${isDoubleClick}:`, room);

    utils.writeParams(TabTypes.TRIMORRA, tier.tier, room.id);
    onData.on(MessageTypes.REPLY, handleLoading);
    loading = utils.doSubscribed(room.id);

    if (loading) {
      setMessage(t("Loading Data…"));
      setMessageType(AlertMessageType.WHITE_WARNING);
    } else {
      commonActions();
    }

  };

  useEffect(() => {
    const fetchRooms = () => {
      if (!db) throw new Error("Database instance not available");
      const startTime = performance.now();

      const query = `
      SELECT 
        *
      FROM
        trimorra_rooms
      WHERE
        tier = $tier
      ORDER BY
        buyin;
      `;

      holdings = [];
      const stmt = db.prepare(query);
      //console.log(`trimorra.Wagers.fetchRooms… Binding Query to ${tier.tier}`);
      stmt.bind({$tier: tier.tier});
      while (stmt.step()) {
        const row: IRoom = mapRoom(stmt.getAsObject() as dbITrimorraRoom, tier);
        holdings.push(row);
      }
      stmt.free();
     
      //console.log(`Wager Selection Rooms: Count ${holdings.length}`, holdings);
      setRooms(holdings);
      
      if (holdings.length > 0) {
        const dflt = holdings[0].id;
        const id: string = utils.readParams(TabTypes.TRIMORRA, tier.tier, dflt);
        //console.log(`Wager Selection Starting Room: ${id}, Default: ${dflt}`);
//        const initialRoom = holdings.find(holdings => holdings.room_id === id); // DO NOT USE getRoom as the rows are not yet assigned

        const initialRoom = getRoom(id, tier);
        if (initialRoom) 
          handleBuyinChange(initialRoom, false);
      };

      const endTime = performance.now();
      //console.log(`TrimorraWagerSelection.fetchRooms executed in ${endTime - startTime} milliseconds`);
    };

    setSelected(null);
    //console.log(`Received Tier Change to ${tier.tier}, selected state is ${!!selected}`);

    fetchRooms();
  }, [tier]);

  useEffect(() => {
    const handleReply = (data: IData) => {
      //console.log(`TrimorraWagerSelection.handleReply — replyId: ${replyId}, data.id: ${data.id}`);
      if (data.id && data.id === replyId) {
        //console.log(`Trimorra.Wagers.handleReply… canceling replyId`);
        setReplyId(null);
      }
    };
  
    const handleRoomUpdates = (data: { row: dbITrimorraRoom }) => {
      //console.log(`TrimorraWagerSelection.handleRoomUpdates — data:`, data);
      
      const room_id = data.row.room_id;
      const newRoom = getRoom(room_id, undefined, false);
      //console.log(`trimorra.Wagers.handleRoomUpdate… ${newRoom?.tier.tier} vs ${tier.tier}`);

      if (!newRoom || newRoom.tier.tier !== tier.tier) return;
    
      setRooms(prevRooms => {
        const roomIndex = prevRooms.findIndex(room => room.id === room_id);
    
        if (roomIndex !== -1) {
          // Update the existing room
          const updatedRooms = [...prevRooms];

          updatedRooms[roomIndex] = { ...prevRooms[roomIndex], ...newRoom };
          return updatedRooms;
        } else {
          // Throw an error if the room is not found
          throw new Error(`Room with id ${room_id} not found in the current state.`);
        }
      });
    
      if (selected && room_id === selected.id) {
        const updatedSelectedRoom = getRoom(room_id, tier);
        if (updatedSelectedRoom) {
          setSelected(updatedSelectedRoom);
          setGameHash(updatedSelectedRoom);
        }
      }
    };

    const handleError = (data: IError) => {
      //console.log('TrimorraWagerSelection.handleError data:', data);

      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason || "An error occurred while placing/canceling a wager.");
      setTimeout(() => setMessage(undefined), 5000); // Clear the message after 5 seconds
    };

    onData.on(MessageTypes.TRIMORRA_ROOMS, handleRoomUpdates);
    onData.on(MessageTypes.REPLY, handleReply);    
    onData.on(MessageTypes.ERROR, handleError);
    return () => {
      onData.off(MessageTypes.TRIMORRA_ROOMS, handleRoomUpdates);
      onData.off(MessageTypes.ERROR, handleError);
      onData.off(MessageTypes.REPLY, handleReply);
    };
  }, [selected, replyId, rooms, message]); // Added dependencies to avoid unnecessary re-renders

  
  const handlePurchase = () => {
    if (!selected)
      throw new Error(`TrimorraWagerSelection.handlePurchase has no selected room`);

    if (isParticipant())
      throw new Error(`TrimorraWagerSelection.handlePurchase already purchased ${isParticipant()}`);

    setReplyId(apiMessage(api.TRIMORRA_BUYIN, {room_id: selected.id}));
  };

  const handleRefund = () => {
    if (!selected)
      throw new Error(`TrimorraWagerSelection.handleRefund has no selected room`);

    if (!isParticipant())
      throw new Error(`TrimorraWagerSelection.handleRefund has no participant ${isParticipant()}`);

    setReplyId(apiMessage(api.TRIMORRA_REFUND, {room_id: selected.id}));
  };

  const isPurchaseDisabled = () => {
    let result: boolean = true;
    //console.log(`TrimorraWagerSelection.isPurchaseDisabled: reply — ${!!replyId}, selected — ${!!selected}, isAuthenticated — ${utils.isAuthenticated()}`);
    if (!replyId)
      if (selected && utils.isAuthenticated()) {
        //console.log(`TrimorraWagerSelection.isPruchaseDisabled: participant: ${isParticipant()}`)      
        if (isParticipant()) 
          result = false; // Always enable refund
        else 
          result = !utils.sufficientFunds(selected.buyin);
      };

    //console.log(`TrimorraWagerSelection.isPruchaseDisabled… Result: ${result}`)
    return result;
  };

  const getPurchasePhrase = () => {
    if (!selected) return 'Purchase';
  
    const phraseKey = isParticipant() ? '_trimorra.cancel-purchase' : '_trimorra.purchase-game';
    const tierName = getTierName(tier.ord, t);
    const buyinValue = formatValue(selected.buyin, selected.buyin < 100.00 ? 2:0);
  
    const components = {
      amount: tier.ord === 0 ? <></> : <QSymbol value={buyinValue} />
    };
  
    return (
      <Trans
        i18nKey={phraseKey}
        values={{ tier: tierName, amount: components.amount}}
        components={components}
      />
    );
  };
  
  return (
    <div className="trimorra-selection trimorra-wager-section">
      <div className="trimorra-title">
        <h3>{t("_trimorra.buyin-selection")}</h3>
      </div>           
      <div className="trimorra-buttons">
        {rooms.map((room) => (
          <WagerButtonManger
            key={room.id}
            room={room}
            isSelected={room.id === selected?.id}
            onClick={handleBuyinChange}  // Pass the handler directly with the room
          />
        ))}
      </div>

      {selected && (
        <div>
          {isParticipant() ? (
            <button className="default-submit-button refund-button" onClick={handleRefund} disabled={isPurchaseDisabled()}>
              {getPurchasePhrase()}
            </button>
          ) : (
            <button className="default-submit-button purchase-button" onClick={handlePurchase} disabled={isPurchaseDisabled()}>
              {getPurchasePhrase()}
            </button>        
          )}
        </div>
      )}

      {message && (<AlertMessage type={messageType} message={message} />)}
      {!loading && (
        <div className="trimorra-details-container">
          {selected && <RoomInformation room={selected} />}
        </div>
      )}

    </div>
  );
};

export default WagerSelection;
