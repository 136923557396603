import React, { useState, useEffect, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Paginations from "../elements/Paginations";
import moment from 'moment';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MessageTypes } from '../utils/Enums';
import { db, onData } from '../WebSocketProvider';
import * as utils from '../utils/Utils';
import { formatValue } from '../utils/Round';
import { TransactionStatusType } from './AccountBase';

import './Accounts.css';

const Transactions: React.FC = () => {
  const { t } = useTranslation();

  const [pending, setPending] = useState<boolean>(false);  
  const [journal, setJournal] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationKey, setPaginationKey] = useState<number>(0);

  const fetchTransactions = (pending: boolean) => {
    if (!db) throw new Error("Database instance not available");

    const query = `
      SELECT
        *
      FROM
        journal
      ${pending ? 'WHERE status <> $pending' : ''}
      ORDER BY
        txn_date desc, cashier_id
    `;

    try {
      const rows = [];
      const stmt = db.prepare(query);
      stmt.bind({ $pending: pending ? "COMPLETED" : undefined });    
      while (stmt.step()) {
        rows.push(stmt.getAsObject());
      }
      stmt.free();

      setJournal(rows);
    } catch (error) {
      console.log("Error querying waiting list:", error);
    }
  };

  const handlePending = () => {
    const newPendingValue = !pending;
    setPending(newPendingValue);
    setCurrentPage(1); // Reset to the first page
    setPaginationKey(prevKey => prevKey + 1); // Change the pagination key
    console.log(`Handle PENDING Change: ${newPendingValue}`);
    fetchTransactions(newPendingValue);
  };

  useEffect(() => {
    const handleData = (data: any) => { // Ensure correct type for data if available
      setJournal((prevItem) => [data.row, ...prevItem]);      
    };

    fetchTransactions(pending);
    onData.on(MessageTypes.JOURNAL, handleData);
    return () => {
      onData.off(MessageTypes.JOURNAL, handleData);
    }
  }, [pending]);

  const tableHeaders = (
    <tr>
      <th>{t('_ACCOUNTING.transactions.table.type')}</th>
      <th>{t('_ACCOUNTING.transactions.table.currency')}</th>
      <th>{t('_ACCOUNTING.transactions.table.when')}</th>
      <th>{t('_ACCOUNTING.transactions.table.info')}</th>
      <th>{t('_ACCOUNTING.transactions.table.amount')}</th>
      <th>{t('_ACCOUNTING.transactions.table.status')}</th>
    </tr>
  );

  const renderData = (paginatedData: any[]) => (
    paginatedData.map((val, key) => {
      const baseStatus = val.status.split(" ")[0]; // Extract the base status
      return (
        <tr key={val.txn_date} className="transaction-item">
          <td>{val.txn_type}</td>
          <td>
            <img src={`/currencies/${val.acronym}.svg`} alt="icon" className="w-25 m-2" />{" "}&nbsp;{val.acronym}
          </td>
          <td>{moment.utc(val.txn_date).local().format('D MMM / HH:mm:ss')}</td>                  
          <td>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">{val.txn_info}</Tooltip>}
            >
              <label>{utils.specialTruncate(val.txn_info, 30, 6)}</label>
            </OverlayTrigger>
          </td>
          <td>{formatValue(val.amount ? val.amount : 0, 8)}</td>
          <td>
            <div className="dot-status-wrapper">
              <div className="dot-status" data-status={baseStatus}>
                <div className="status"></div>
              </div>
              <label className="status-label">{val.status}</label>
            </div>            
          </td>
        </tr>
      );
    })
  );

  return (
    <div className="outlet-inner">
      <div className="transaction-container"></div>
      <Paginations
        data={journal}
        header={undefined}
        className="table transaction-table" // Pass the table's class name here
        tableHeaders={tableHeaders}
        jsxData={renderData}
        recordsPerPage={10}
        useCircles={true} // Show dots instead of page numbers
      />
      <div className="presale_my_entries">
        <div className="form-check form-switch transaction-switch">
          <input
            className="form-check-input transaction-check-input"
            checked={pending}
            onInput={handlePending}
            type="checkbox"
            role="switch"
            id="flexSwitchCheckPending"
          />
          <label className="form-check-label transaction-check-label" htmlFor="flexSwitchCheckPending">
            {t('_ACCOUNTING.transactions.pending')}
          </label>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
