import { useNavigate } from "react-router-dom";
import { apiMessage, db, onData } from "../WebSocketProvider";
import { MessageTypes, api } from "../utils/Enums";

const Logout = () => {
    const navigate = useNavigate();

  // Handler function
  const handleReplay = (data: { id: number }) => {
    // Clear all states if necessary (e.g., update Redux state)
    /*
    dispatch({
      type: LOGIN,
      message: "",
      isLoggedIn: false,
    });
    */

    // Redirect to login screen
    navigate("/login");
  };

  // Clear the database
  const clearDatabase = () => {
    if (!db) throw new Error("SQLite database instance not available");

    const query = `
      SELECT
        name
      FROM
        sqlite_master
      WHERE
        type = 'table' AND name <> 'sqlite_sequence';
    `;

    const stmt = db.prepare(query);
    const tables: { name: string }[] = stmt.all();
    stmt.free();

    if (!tables || tables.length === 0) {
      throw new Error("SQLite database error: No Tables Found.");
    }

    tables.forEach((table) => {
      try {
        db.run(`DELETE FROM ${table.name};`);
        console.log(`Cleared data from table: ${table.name}`);
      } catch (error) {
        console.log(`Error clearing table ${table.name}:`, error);
      }
    });

    console.log("All data has been cleared while preserving table structures.");
  };

  // Perform logout actions
  clearDatabase();
  onData.once(MessageTypes.SITE, handleReplay);
  apiMessage(api.GOODBYE, {});

  return null;
};

export default Logout;
