import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { db, onData } from "../../WebSocketProvider";
import Paginations from "../../elements/Paginations";
import { MessageTypes } from "../../utils/Enums";
import QSymbol from "../../utils/QSymbol";
import AvatarImage from '../../elements/AvatarImage';
import { formatValue, roundDown } from "../../utils/Round";

import { IHistory, getTierName, mapHistory } from "./TrimorraBase";
import { dbITrimorraHistory } from "./TrimorraDBI";
import "./Trimorra.css";

const GameHistory: React.FC = () => {
  const { t } = useTranslation();

  const [participants, setParticipants] = useState<IHistory[]>([]);
  
  useEffect(() => {
    const fetchList = () => {
      if (!db) throw new Error("Database instance not available");      
      const startTime = performance.now();
  
      const query = `
      SELECT
        game_id, user_name, avatar, tier, buyin,
        payout, game_time
      FROM
        trimorra_history
      LEFT JOIN
        avatars USING (client_ref)
      ORDER BY
        game_time DESC;
    `;
    
      const rows: IHistory[] = [];
      
      const stmt = db.prepare(query);
      while (stmt.step()) {
        const row: IHistory = mapHistory(stmt.getAsObject() as dbITrimorraHistory);
        rows.push(row);
      }
      stmt.free();
      
      console.log(`History List Query Results… Rows: ${rows.length}`);
      setParticipants(rows);
    };

    const handleUpdate = (data: IData) => {
      if (!db) throw new Error("Database instance not available");      
      const startTime = performance.now();
  
      const query = `
      SELECT
        game_id, user_name, avatar, tier, buyin,
        payout, game_time
      FROM
        trimorra_history
      LEFT JOIN
        avatars USING (client_ref)
      WHERE
        game_id = $id
    `;
    
      const stmt = db.prepare(query);
      const row: IHistory = mapHistory(stmt.getAsObject({$id: data.row.game_id}) as dbITrimorraHistory);
      stmt.free();

      setParticipants((prevTempData: IHistory[]) => [row, ...prevTempData]);
      
      const endTime = performance.now();
      console.log(`History List Fetching Player… Executed in ${endTime - startTime} milliseconds`);
    };

    fetchList();
    
    onData.on(MessageTypes.TRIMORRA_HISTORY, handleUpdate);
    return () => {
      onData.off(MessageTypes.TRIMORRA_HISTORY, handleUpdate);
    };
  }, []);

  const tableHeaders = (
    <tr>
      <th className="text-start">{t('_trimorra.common.game-id')}</th>
      <th>{t('_trimorra.history.players')}</th>
      <th>{t('_trimorra.history.tier')}</th>
      <th>{t('_trimorra.history.buy-in')}</th>                
      <th>{t('_trimorra.history.game-time')}</th>
      <th>{t('_trimorra.history.winner')}</th>
      <th>{t('_trimorra.history.payout')}</th>
    </tr>
  );

  const renderHistoryData = (data: IHistory[]) => (
    <>
      {data.map((participant) => (
        <tr key={participant.game_id} className="history-list-item">
          <td>{participant.game_id}</td>
          <td>{participant.tier.players}</td>
          <td>{getTierName(participant.tier.ord, t)}</td>
          <td>
            {participant.buyin === 0 ? t("_common.free") : 
              <QSymbol value={formatValue(participant.buyin, participant.buyin < 100 ? 2 : 0)}/>}                    
          </td>
          <td>{moment.utc(participant.when).local().format('D MMM / HH:mm:ss')}</td>                  
          <td>
            <AvatarImage
              profile={participant.profile}
              className="avatar-image avatar-small m-2"
            />
            <span>{participant.profile.name}</span>
          </td>
          <td>{<QSymbol value={formatValue(participant.payout, roundDown(participant.payout, 2) === participant.payout ? 2 : 3)} />}
          </td>                  
        </tr>
      ))}
    </>
  );

  return (
    <div className="trimorra-selection">
      <h2>{t("_trimorra.history.title")}</h2>
      <div className="history-list-container">
      <Paginations
        data={participants}
        header={undefined}
        className="table history-list-table" // Pass the table's class name here
        tableHeaders={tableHeaders}
        jsxData={renderHistoryData}

        recordsPerPage={12}
        useCircles={true} // Show dots instead of page numbers
        theme="light"
      />
      </div>
    </div>
  );
};

export default GameHistory;
