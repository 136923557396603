import React from 'react';
import Lottie from 'react-lottie-player';
import loaderJson from '../../assets/quinto_logo_animation.json';

// Define an interface for the props if needed, for now it's empty since no props are used
interface LoaderWrapperProps {}

export const LoaderWrapper: React.FC<LoaderWrapperProps> = () => {
  return (
    <div className="main-loader">
      <Lottie loop animationData={loaderJson} play />
    </div>
  );
};
