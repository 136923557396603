import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import Decimal from "decimal.js";
import { formatValue } from "../utils/Round";
import { onBalanceChange } from "../WebSocketProvider";
import { MessageTypes } from "../utils/Enums";
import QSymbol from "../utils/QSymbol";
import "../games/lottery/windfall/Windfall.css";

interface IAccountBalanceList {
  heading: string;
  list: ICurrency[];
}

const getAccountBalanceList = (currencies: ICurrency[]): IAccountBalanceList[] => {
  const result: IAccountBalanceList[] = [];

  // Get unique fund types from currencies
  const uniqueFundTypes = Array.from(new Set(currencies.map(currency => currency.fundType)));

  uniqueFundTypes.forEach((fundType) => {
    const filteredCurrencies = currencies.filter(
      (currency) => currency.fundType === fundType && currency.inUse
    );
    if (filteredCurrencies.length > 0) {
      result.push({ heading: fundType, list: filteredCurrencies });
    }
  });

  return result;
};

export const AccountBalance: React.FC = () => {
  const { t } = useTranslation();
  const { currencies } = useOutletContext<{ currencies: ICurrency[] }>();

  const [list, setList] = useState<IAccountBalanceList[]>([]);

  useEffect(() => {
    const handleChange = () => {
      setList(getAccountBalanceList(currencies));
    };

    onBalanceChange.on(MessageTypes.MII, handleChange);

    handleChange();

    return () => {
      onBalanceChange.off(MessageTypes.MII, handleChange);
    };
  }, [currencies]);

  return (
    <div className="outlet-inner">
      <div className="card-content">
        <div className="card">
          <div className="card-body qab mini">
            <label className="txt-avl-bal">{t('_ACCOUNTING.exact')}</label>
            <div className="balance-edging">
              <label>{<QSymbol value={formatValue(quintoshi.balance.toNumber(), 8)} />}</label>
            </div>
          </div>
        </div>

        <br />

        {list &&
          list.map((accountBalanceList, listIndex) => (
            <div key={listIndex}>
              <div className="table-responsive">
                <table className="table qtable">
                  <thead>
                    <tr>
                      <th style={{ width: "30%" }}>
                        {t(`_ACCOUNTING.column.types.${accountBalanceList.heading.toLowerCase()}`)} {t('_ACCOUNTING.column.currency')}
                      </th>
                      <th style={{ width: "20%" }} className="text-end">
                        {t('_ACCOUNTING.column.balance')}
                      </th>
                      <th style={{ width: "25%" }} className="text-end">
                        {<QSymbol value={t('_ACCOUNTING.column.exchange')} />}
                      </th>
                      <th style={{ width: "25%" }} className="text-end">
                        {<QSymbol value={t('_ACCOUNTING.column.amount')} />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountBalanceList.list.map((currency, currencyIndex) => (
                      <tr key={`ab-${currencyIndex}`}>
                        <td className="mwr-200">
                          <img
                            className="w-25"
                            src={`/currencies/${currency.acronym}.svg`}
                            alt={currency.descr}
                          />
                          &nbsp;
                          {currency.descr} ({currency.acronym})
                        </td>
                        <td className="text-end">
                          {formatValue(currency.balance.toNumber() ?? 0, 8)}
                        </td>
                        <td className="text-end">
                          {formatValue(currency.exchange.toNumber(), 8)}
                        </td>
                        <td className="text-end">
                          {formatValue(currency.exchange.mul(currency.balance).toNumber(), 8)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
            </div>
          ))}
      </div>
    </div>
  );
};
