/**
 * @file WebSocketProvider.tsx
 * @description
 * This file provides utilities and a React context for managing WebSocket connections
 * and database interactions in the Quinto Games application.
 *
 * The WebSocketProvider component initializes the SQLite database, manages WebSocket
 * connections, and handles various application events such as client balance changes.
 * It exposes several utility functions and event emitters for interacting with the WebSocket
 * and the database.
 *
 * Overview:
 * - apiMessage: Sends an API message via WebSocket with an incrementing identifier.
 * - apiRawMessage: Sends a raw API message via WebSocket without an identifier.
 * - WebSocketProvider: React component that provides WebSocket and database context.
 * - onChange, onBalanceChange, onChange: Event emitters for handling various application events.
 * - initializeDatabase: Initializes the SQLite database.
 * - handleClientChange: Handles changes to the client data.
 * - handleBalanceChange: Handles changes to the client balance data.
 */

import React, { ReactNode, useEffect, useState } from "react";
import Decimal from "decimal.js";
import PreLoader from "./PreLoader"; // Ensure this component exists and is imported correctly
import { initializeSQL } from './wasmLoader';

import { EventEmitter } from "events";
import { processMessage } from "./utils/IterateJson";
import { ClientStatus, MessageTypes, TabTypes, api } from "./utils/Enums";
import * as utils from "./utils/Utils";
import { roundDown, roundUp } from "./utils/Round";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/responsive.css"; // Adjust the path if needed
import "../assets/css/style.css"; // Adjust the path if needed
import TrackPage from "./inc/TrackPage";

// Declare db, ws, totp, onChange, and onBalanceChange at the module level
let db: any = null;
let ws: WebSocket | null = null;

const onData = new EventEmitter();
onData.setMaxListeners(0);

const onBalanceChange = new EventEmitter();
onBalanceChange.setMaxListeners(0);

// Messages are delivered as a stream of newline-delimited JSON objects.
// Each message consists of a wrapper with several control fields along with the data contents:
//
//   "id"       -> The identifier is echoed back as part of the reply
//   "method"   -> The name of the API call you are making
//   "params"   -> A singular JSON object with each key is a parameter name and the parameter value, and the ordering does not matter.

let idCount: number = 0; // used for message identifier

/**
 * Sends an API message via WebSocket with an incrementing identifier.
 * @param {string} method - The name of the API call.
 * @param {object} [params={}] - The parameters for the API call.
 * @returns {number} The identifier of the sent message.
 */
const apiMessage = (method: string, params: any = {}):number => {
  const message = { method, params, id: idCount };
  sendMessage(message);

  return idCount++;
};

/**
 * Sends a raw API message via WebSocket without an identifier.
 * @param {string} method - The name of the API call.
 * @param {object} [params={}] - The parameters for the API call.
 */
const apiRawMessage = (method: string, params: any = {}):void => {
  const message = { method, params };
  sendMessage(message);
};

/**
 * Sends a message via WebSocket.
 * @param {object} message - The message to send.
 * @throws Will throw an error if WebSocket context is not available or if the message is invalid.
 */
const sendMessage = (message: any) => {
  if (!ws)
    throw new Error(
      "WebSocket context is not available. Make sure WebSocketContext is provided.",
    );

  if (!message) throw new Error("Invalid message");

  ws.send(JSON.stringify(message));
};

interface WebSocketProviderProps {
  children: ReactNode;
}

/**
 * React component that provides WebSocket and database context.
 * @param {WebSocketProviderProps} props - The component props.
 * @returns {React.ReactElement} The WebSocketProvider component.
 */
const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const connectSocket = () => {
      const onOpen = () => {
        console.log("Websocket is connected…");
  
        let replyId:number | null = null;
        const handleOpenSession = ( data: IData) => {
          const handleTabs = (data: IData) => {
            const handleSubscribed = (data: IData) => {
              if (data.id !== replyId) return;

              onData.off(MessageTypes.SUBSCRIBE, handleSubscribed);
              setLoading(false);
            }

            if (data.id !== replyId) return;
  
            onData.off(MessageTypes.TABS, handleTabs);
  
            let id = utils.getTabRefId(TabTypes.WINDFALL, TabTypes.LOTTERY);
            if (!id)
              throw new Error("handleTabs: TAB INFORMATION WAS NOT LOADED…");

            onData.on(MessageTypes.SUBSCRIBE, handleSubscribed);
            replyId = utils.doSubscribed(id);
          }
  
          if (data.id !== replyId)
            throw new Error(`handleOpenSession.INVALID RETURN MESSAGE replyId${replyId}, data.id${data.id}`);
  
          onData.off(MessageTypes.SITE, handleOpenSession);
  
          // This is special logic for Windfall Lottery Presale
          let id = utils.getTabRefId(TabTypes.LOTTERY);
          if (!id)
            throw new Error("onOpen: TAB INFORMATION WAS NOT LOADED…");
  
          console.log("Sending Lottery Subscription…");
          onData.on(MessageTypes.TABS, handleTabs);
          
          replyId = utils.doSubscribed(id);
          if (replyId === null)
            throw new Error("We should not be subscribe to anything at this point");
        }
  
        ws?.removeEventListener("open", onOpen);
  
        onData.on(MessageTypes.SITE, handleOpenSession);
        replyId = apiMessage(api.OPEN_SESSION);
      };
    
      ws = new WebSocket(window.WS_BASE);

      ws.addEventListener("open", onOpen);
      ws.addEventListener("close", onClose);
      ws.addEventListener("message", onMessage);

      onData.on(MessageTypes.CLIENT, handleClientChange);
      onData.on(MessageTypes.BALANCE, handleBalanceChange);
      onData.on(MessageTypes.AVATAR, handleAvatarChange);
    };

    const initializeDatabase = async () => {
      console.log("Loading Database…");
      try {
        const SQL = await initializeSQL();
        if (!SQL) {
          throw new Error("Failed to initialize SQL.js");
        }

        const buffer = await fetch(`${process.env.PUBLIC_URL}/database/quintogames.db`)
          .then(async response => {
            if (!response.ok) {
              throw new Error(`Failed to fetch database file: ${response.statusText}`);
            }
            return await response.arrayBuffer();
          });

        db = new SQL.Database(new Uint8Array(buffer));
        if (db) {
          console.log('Database is Loaded');
        } else
          throw new Error("Unable to load database…");
        
        connectSocket();

      } catch (error) {
        console.log("Error initializing database:", error);
      }
    };

    initializeDatabase();

    return () => {
      onData.removeAllListeners();
      if (ws) ws.close();
    };
  }, []);

  const handleBalanceChange = () => {
    const formatBalance = (value: Decimal): string => {
      // Truncate to 2 decimal places and return as a string
      const truncatedValue = value.toDecimalPlaces(2, Decimal.ROUND_DOWN);
    
      return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(Number(truncatedValue));
    };
    
    if (!db) throw new Error("SQLite database instance not available");

    const query = `
      SELECT SUM(
        COALESCE(exchange_rate, 0) * COALESCE(balance, 0)
      ) as quintoshis 
      FROM 
        client_balances
      WHERE
        status LIKE 'ACTIVE%' OR acronym = 'QVC';
    `;

    try {
      const stmt = db.prepare(query);
      const row = stmt.getAsObject([]);
      stmt.free();

      if (!row) return;
      console.log("Query result on balance", row);
      quintoshi.balance = new Decimal(roundDown(row.quintoshis, 8));      
      quintoshi.text = formatBalance(quintoshi.balance);
      
      onBalanceChange.emit(MessageTypes.DATA);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const handleAvatarChange = (data: IData) => {
    if (mii && mii.client_ref && mii.client_ref === data.row.client_ref) {
      console.log("Client references match. Updating mii...");
      mii = {
        ...mii,
        profile: {
          ...mii.profile,
          name: data.row.user_name ?? mii.profile.name,
          ndx: utils.asInt(data.row.avatar ?? DEFAULT_AVATAR),
        },
      };
      onData.emit(MessageTypes.MII, mii);
    }
  };
  
  const onOpen = () => {
    console.log("Websocket is connected…");
    ws?.removeEventListener("open", onOpen);

    apiMessage(api.OPEN_SESSION);
  };

  const onMessage = (event: MessageEvent) => {
    processMessage(event.data);
  };

  const onClose = () => {
    console.warn("WebSocket connection closed.");
    db.close();
  };

  const handleClientChange = () => {
    if (!db) throw new Error("SQLite database instance not available");

    const query = `
      SELECT 
        c.client_ref, 
        COALESCE(a.user_name, c.status) as user_name, 
        anonymous, 
        avatar, 
        status,

        authentication, totp_qrcode, totp_secret, 
        email_address, email_opt_out,
        reward_pct, reward_progress
      FROM
        client c
      LEFT JOIN avatars a USING (client_ref);
    `;

    try {
      const stmt = db.prepare(query);
      const row = stmt.getAsObject([]);
      console.log("Reading Client Data(DEFAULT_AVATAR)", row, DEFAULT_AVATAR);
      stmt.free();

      if (!row) return null;

      let status: ClientStatus = utils.enumValueOf(ClientStatus, row.status) as ClientStatus;
      console.log("Initalize mii status:", status);
      mii = {
        client_ref: row.client_ref ?? undefined,
        profile: {
          name: row.user_name ?? "",
          ndx: utils.asInt(row.avatar ?? DEFAULT_AVATAR),
        },
        anonymous: !!row.anonymous, // assuming 1 for true, 0 for false
        status: status,

        email_address: row.email_address ?? undefined,
        authentication: row.authentication,
        totp_qrcode: row.totp_qrcode ?? undefined,
        totp_secret: row.totp_secret ?? undefined,
        reward_pct: row.reward_pct ?? undefined,
        reward_progress: row.reward_progress ?? undefined,
        email_opt_out: row.email_opt_out === 1, // assuming 1 for true, 0 for false
      };
      onData.emit(MessageTypes.MII, mii);
    } catch (error) {
      console.error("Error fetching profile:", error);
      throw error;
    }
  };

  return <React.Fragment>{loading ? <PreLoader /> : children}</React.Fragment>;
};

export { apiMessage, apiRawMessage, db, onBalanceChange, onData };
export default WebSocketProvider;
