import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPlayer } from "./TrimorraBase";
import QSymbol from "../../utils/QSymbol";
import AvatarImage from "../../elements/AvatarImage";
import "./Trimorra.css"; // Importing the CSS file

interface IGamePlayerProps {
  player: IPlayer;
}

const maxRollValue = Math.pow(2, 28); // 268435456; // 2^28

const GamePlayer: React.FC<IGamePlayerProps> = ({ player }) => {
  const { t } = useTranslation(); // Hook for translation

  const [simulatedRoll, setSimulatedRoll] = useState<number | undefined>(undefined);

  useEffect(() => {
    console.log("Player data:", player); // Log player data for debugging

    let interval: NodeJS.Timeout | null = null;
    
    if (player.roll === undefined) {
      interval = setInterval(() => {
        setSimulatedRoll(Math.floor(Math.random() * maxRollValue)); // Rolling effect between 1 and 2^28
      }, 50); // Update every 50ms for a rolling effect
    } else {
      setSimulatedRoll(player.roll);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [player.roll]);

  return (
    <div className="player-container">
      <p>{player.profile.name}</p>
      <div className="avatar-container">
        <AvatarImage
          profile={player.profile}
          className={`avatar-image player-avatar ${player.winner ? 'winner':''}`}
        />
      </div>
      <p className="roll-label">{t('_trimorra.games.roll')}</p>
      <p className="roll">{simulatedRoll !== undefined ? simulatedRoll : 'Rolling...'}</p>
      {player.payout !== undefined && (
        <p className={`payout ${player.winner ? 'winner' : ''}`}>
          <QSymbol value={player.payout} />
        </p>
      )}
    </div>
  );
};

export default GamePlayer;
