import { Link } from "react-router-dom";
import { images } from "../../helper";

export const CasinoBanner = (props) => {
  return (
    <Link to="/rewards">
      <section className="casino_banner pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img
                src={
                  props.image == undefined
                    ? images.svg_banner_play_more_win_more
                    : props.image
                }
                className="img-fluid"
                alt="casino game"
              />
            </div>
          </div>
        </div>
      </section>
    </Link>
  );
};
