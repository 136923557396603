import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import GameContainer from "./GamePlayContainer";
import { db, onData } from "../../WebSocketProvider";
import { MessageTypes } from "../../utils/Enums";
import { dbITrimorraGame } from "./TrimorraDBI";
import { GameStateTypes, IGame, mapGame } from "./TrimorraBase";

const GamePlayManager: React.FC = () => {
  const { t } = useTranslation();  
  const [games, setGames] = useState<IGame[]>([]);

  useEffect(() => {
    const handleGame = (data: {row: dbITrimorraGame} ) => {
      console.log(`RECIEVED GAME DATA FOR ${data.row.game_id}`, data.row);

      if (data.row.game_state !== GameStateTypes.OFFICIAL) return;
      if (!!data.row.completed) return;

      // Change the game state to START
      // Ensure completed is treated as a boolean
      const dbGame: dbITrimorraGame = {
        ...data.row,
        game_state: GameStateTypes.START,
        completed: !!data.row.completed        
      };
      
      const game = mapGame(dbGame);
      setGames((prevGames) => [game, ...prevGames]);
    };
    
    const unplayedGames = () => {
      if (!db) throw new Error("Database instance not available");
      const startTime = performance.now();
   
      const query = `
      SELECT 
        game_id, room_id, game_time, game_state, game_hash, 
        game_seed, completed
      FROM
        trimorra_games
      WHERE
        completed = 0  -- Using 0 to represent FALSE
      ORDER BY
        game_time;
      `;
    
      try {
        const stmt = db.prepare(query);
        let foundGames = false;
    
        console.info("Executing SQL Query:", query);
    
        while (stmt.step()) {
          foundGames = true;
          const row = stmt.getAsObject() as dbITrimorraGame;

          // Ensure completed is treated as a boolean
          const data: dbITrimorraGame = {
            ...row,
            game_state: GameStateTypes.START,
            completed: !!row.completed
          };
   
          const game: IGame = mapGame(data);   
          setGames((prevGames) => [game, ...prevGames]);
        }
        stmt.free();
    
        if (!foundGames) {
          console.info("No unplayed games found.");
        }
      } catch (error) {
        console.error("Error fetching unplayed games:", error);
      }
    
      const endTime = performance.now();
      console.log(`unplayedGames took ${endTime - startTime} milliseconds`);
    };
    
    
    unplayedGames();

    onData.on(MessageTypes.TRIMORRA_GAME, handleGame);
    return () => {
      onData.off(MessageTypes.TRIMORRA_GAME, handleGame);
    };
  }, []);

  const handleCompletedGame = (game: IGame) => {
    setGames((prevGames) => prevGames.filter(item => item.id !== game.id));
  };

  return (
    <div className="game-play-manager">
      <h2 className="game-section-header">
        {t("_trimorra.games.title")}
      </h2> 

      {games.map((game) => (
        <GameContainer key={game.id} game={game} onGameComplete={handleCompletedGame} />
      ))}
    </div>
  );
};

export default GamePlayManager;
