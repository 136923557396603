import React from "react";
import ReactDOM from "react-dom/client";
import StartApp from "./App";
import Decimal from "decimal.js";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "react-toastify/dist/ReactToastify.css";

import "./i18n";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ClientStatus } from "./components/utils/Enums";
import { asInt } from "./components/utils/Utils";

window.config = process.env.REACT_APP_CONFIG;
console.log("Window Config:", window.config); // Check the config value

window.WS_BASE = "wss://qa.quinto.games"; // Replace with your actual WebSocket URL
console.log("Assigned window.WS_BASE:", window.WS_BASE);

// Assign the value to the global constants
(globalThis as any).DEFAULT_AVATAR = asInt(1);
console.log("Assigned DEFAULT_AVATAR");

(globalThis as any).mii = {
    client_ref: undefined,
    avatar: {
      user_name: undefined,
      ndx:  DEFAULT_AVATAR
    },
    anonymous: false,

    status: ClientStatus.OBSERVER,

    authentication: 'SIMPLE',
    totp_qrcode: undefined,
    totp_secret: undefined,

    email_address: undefined,
    email_opt_out: false,

    reward_pct: 0,
    reward_progress: 0
  };

(globalThis as any).quintoshi = { 
  balance: new Decimal(0),
  text: '0.00' 
};

const rootElement = document.getElementById("root");
console.log("Root Element:", rootElement); // Check if root element is found

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <StartApp />
      </I18nextProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(ODS))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
