import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { images } from "../../../../helper";
import JackpotAnimator, { IJackpotAnimator } from "../../../elements/JackpotAnimator";
import { CasinoBanner } from "../../../inc/CasinoBanner";
import WindfallPayouts from "./WindfallPayouts";
import PresaleList from "./PresaleList";
import { db, onData } from "../../../WebSocketProvider";
import { formatValue } from "../../../utils/Round";
import { getTabRefId as getTabRefId } from "../../../utils/Utils";
import { MessageTypes, TabTypes } from "../../../utils/Enums";
import QSymbol from "../../../utils/QSymbol";
import TrackPage from "../../../inc/TrackPage";
import { ILotteryGame } from "../LotteryBase";
import PresalePurchase from "./PresalePurchase";
import './Windfall.css';

const PresalePage: React.FC = () => {
  TrackPage();

  const { t } = useTranslation();

  const [aggregate, setAggregate] = useState<IJackpotAnimator>({
    init: {
      value: 0,     // Initial value
      precision: 0, // Number of decimal points
      symbol: true, // Display currency symbol
      time: 4.250     // Time to achieve the target value in seconds
    },
    target: 0       // Target value to reach
  });

  const [defId, setDefId] = useState<string | null>(null); // Storage of the Lottery Def Id
  const [game, setGame] = useState<ILotteryGame | null>(null);

  useEffect(() => {
    const initialize = () => {
      if (!db) throw new Error("Database instance not available");

      const id = getTabRefId(TabTypes.WINDFALL, TabTypes.LOTTERY);
      console.log(`Loading PresalePage.getRefId(${id})`);
      
      if (!id) {
        throw new Error(`Lottery game should already be loaded`);
//        setTimeout(initialize, 500);
//        return;
      } 
      setDefId(id);

      const query = `
        SELECT 
          *
        FROM
          lottery_games
        WHERE
          def_id = ?;
      `;

      const stmt = db.prepare(query);
      const row = stmt.getAsObject([id]);
      console.log(`PresalePage.tsx Data(${id})`, row);
      stmt.free();

      setGame(row);

      setAggregate((prevProps) => ({
        ...prevProps,
        init: {
          ...prevProps.init,
          value: row.aggregate,
        },
        target: row.aggregate,
      }));          
    };

    initialize();
  }, []);

  return (
    <section className="presale">
      <div className="presale-rect-top">
        <div className="inner-view"></div>
      </div>
      <div className="container">
        <div className="text-center windfall-logo">
          <img src={images.svg_img_windfall_presale} alt="Windfall Presale" />
        </div>
        <div className="white-card drag-to-logo">
          <h1>{t('_lottery.windfall.total_prizes')}</h1>
          <div className="aggregate-value">
          <QSymbol value={(formatValue(52000000,0))}/>
          </div>
          <br />
          <WindfallPayouts />
        </div>
        <br />
        <div className="card-blue">
          <h2>{t('_lottery.windfall.presale_tickets')}</h2>
          {game && <PresalePurchase init={game} />}
        </div>
        <br />
        <div className="presale-event">
          <h1>{t('_lottery.windfall.about_presale_guarantee')}</h1>
          <div className="hl" />
          <p className="text-start">{t('_lottery.windfall.welcome_wording')}</p>
          <br />
          {game && <PresaleList def_id={game.def_id} />}
          <br /><br />
        </div>
      </div>
      <div className="top-prices">
        <br /><br /><br />
        <div className="container">
          <h1>{t('_lottery.windfall.top_prizes')}</h1>
          <div className="card-blue">
            <div className="hl-pink" />
            <div className="text-center pb-3">
              <h3>
                <Trans i18nKey="_lottery.windfall.est_jackpot_winner" 
                  components={{ 
                    amount: <QSymbol value={formatValue(game ? game.jackpot_amt : 0, 0)} />
                  }}
                />                
              </h3>
              <h3>
                <Trans i18nKey="_lottery.windfall.ten_winners" 
                  components={{ 
                    amount: <QSymbol value={formatValue(1000000, 0)} />
                  }}
                />                
              </h3>
              <h3>
                <Trans i18nKey="_lottery.windfall.secondary_winners" 
                  components={{
                    winners: <span>354</span>,
                    amount: <QSymbol value={formatValue(10000, 0)}/>
                  }}
                />                
              </h3>
            </div>
            <div className="hl-pink" />
            <small>
              <Trans i18nKey="_lottery.windfall.jackpot_description" components={{ amount: <QSymbol value={formatValue(52000000, 0)} /> }} />
            </small>
          </div>
          <br />
          <div className="text-start">
            <p>{t("_lottery.windfall.act_fast")}</p>
            <br />
            <p>{t("_lottery.windfall.all_participants")}</p>
          </div>
          <br />
          <p className="text-start">
            {t("_lottery.windfall.offical_rules")}
          </p>
        </div>
      </div>
      <br /><br />
      <CasinoBanner />
    </section>
  );
};

export default PresalePage;
