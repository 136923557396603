export const QCardHeaders = (props) => {
  return (
    <div className="card-header-bar balance_contant two_factor_title_profile d-flex">
      <span className="me-5">{props.title || ""}</span>
      <span className="d-flex mt-md-0 mt-3">
        <div></div>
        <p className="ms-3"></p>
        <div className="card-header-bar-desc">
          <h4 className="d-block pb-2">{props.subTitle || ""}</h4>
          <p>{props.desc || ""}</p>
        </div>
        <p></p>
      </span>
    </div>
  );
};
