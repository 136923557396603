import React, { useEffect, useState, ChangeEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { images } from "../../../../helper";
import { apiMessage, onData } from "../../../WebSocketProvider";
import AlertMessage, { AlertMessageType } from "../../../elements/AlertMessage";
import JackpotAnimator, { IJackpotAnimator } from "../../../elements/JackpotAnimator";
import QButton from "../../../elements/QButton";
import { ClientStatus, MessageTypes, api } from "../../../utils/Enums";
import QSymbol from "../../../utils/QSymbol";
import { formatValue } from "../../../utils/Round";
import * as utils from "../../../utils/Utils";
import { ILotteryGame, IPresale } from "../LotteryBase";

interface PresalePurchaseProps {
  init: ILotteryGame;
}

const PresalePurchase: React.FC<PresalePurchaseProps> = ({ init }) => {
  const { t } = useTranslation();

  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState("");
  const [qty, setQty] = useState(1);
  const [progress, setProgress] = useState(0);
  const [results, setResults] = useState<IPresale>({
    def_id:'',
    client_ref:'',
    purchased:new Date(),
    ticket_qty:0,
    trx_id:''
  });
  const [game, setGame] = useState<ILotteryGame>(init);
  const [remaining, setRemaining] = useState<IJackpotAnimator>({
    init: {
      value: init.remaining,       // Initial value
      precision: 0,   // Number of decimal points
      symbol: false,  // Display currency symbol
      time: 7.50      // Time to achieve the target value in seconds
    },
    target: init.remaining // Target value to reach
  });

  let replyId: number | null = null;
  useEffect(() => {
    const handleGame = (data: IData) => {
      const update = data.row as ILotteryGame;
      console.log(`Received handleGame def_id ${update.def_id} we need ${game.def_id}`);
      if (game.def_id !== update.def_id) return;
      
      setRemaining((prevProps) => ({
        ...prevProps,
        target: update.remaining,
      }));

      setGame(update);
    }
    
    onData.on(MessageTypes.LOTTERY_GAME, handleGame);

    return () => {
      onData.off(MessageTypes.LOTTERY_GAME, handleGame);
    };
  }, [game, progress]);

  const confirmTicket = () => {
    setProgress(1);
  };

  const cancelTicketBook = () => {
    setProgress(0);
  };

  const doPurchase = () => {
    const handleReply = (data: IData) => {
      console.log(`Received handleReply and replyId ${data.id} we need ${replyId}`, data)
      if ( data.id !== replyId) return;

      const update = data.row as IPresale;
      setResults(update);
      setProgress(2);

      onData.off(MessageTypes.PRESALE, handleReply);
    };

    onData.on(MessageTypes.PRESALE, handleReply);
    replyId = apiMessage(api.LOTTERY_PRESALE, { quantity: qty });
  };

  const generatePurchaseString = (qty:number, cost:number) => {
    const ticketWord = qty === 1 ? t('_lottery.single_ticket') : t('_lottery.multi_tickets');
    const countWord = utils.toWords(qty);
    return (
      <Trans
        i18nKey="_lottery.windfall.purchase.purchase"
        count={qty}
        values={{ count: countWord, ticketCount: ticketWord }}
        components={{ amount: <QSymbol value={formatValue(cost)} /> }}
      />
    );
  };

  const generateComfirmationString = (qty:number) => {
    const ticketWord = qty === 1 ? t('_lottery.single_ticket') : t('_lottery.multi_tickets');
    const countWord = utils.toWords(qty);
    return (
      <Trans
        i18nKey="_lottery.windfall.purchase.confirmation.purchase"
        count={qty}
        values={{ count: countWord, ticketCount: ticketWord }}
      />
    );
  };
  
  const generateThankYouString = (qty:number) => {
    const ticketWord = qty === 1 ? t('_lottery.single_ticket') : t('_lottery.multi_tickets');
    const countWord = utils.toWords(qty);
    return (
      <Trans
        i18nKey="_lottery.windfall.purchase.confirmation.reply.thank_you"
        count={qty}
        values={{ count: countWord, ticketCount: ticketWord }}
      />
    );
  };
    
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let vl = parseFloat(e.target.value) || 1;
    vl = vl <= 0 ? 1 : vl;
    setQty(vl);
  };

  const isPurchaseDisabled = (check:number) => {
//    !utils.isAuthenticated() || (qty * game.buyin) > quintoshi.balance || progress === 0    

    let result: boolean = true;
    if (utils.isAuthenticated() && (progress === check) )
      result = !utils.sufficientFunds(qty * game.buyin);

    console.log(`lottery.windfall.isPruchaseDisabled… Result: ${result}`)
    return result;
  };

  return (
    <>
      <div className="ticket-remaining-section">
        <div className="tr-left">
          <div className="inp-wapper">
            <label>{t('_lottery.windfall.purchase.ticket_qty')}</label>
            <div className="qstepview">
              <input
                max={game.max_picks}
                value={qty}
                min={1}
                step={1}
                disabled={progress !== 0}
                onChange={handleChange}
                type="number"
              />
            </div>
          </div>
          <div>
            <button 
              className="default-submit-button purchase-button" 
              onClick={confirmTicket} 
              disabled={isPurchaseDisabled(0)}>
              {generatePurchaseString(qty, qty * game.buyin)}
            </button>        
          </div>

          <br />
          <p>{t("_lottery.windfall.purchase.no_refund")}</p>
        </div>
        <div className="tr-vline"></div>
        <div className="tr-right">
          <div className="remaining">
            <JackpotAnimator {...remaining} />
            <h3>{t("_lottery.windfall.remaining_tickets")}</h3>
          </div>
        </div>
      </div>

      {progress === 0 && (
        <>
          <div className="ticket-info-section">
            <div>
              <strong>{t("_lottery.windfall.purchase.minimum_purchase")}</strong>
              <br />
              <label>{game.max_games}</label>
            </div>
            <div className="divider"></div>
            <div>
            <strong>{t("_lottery.windfall.purchase.maximum_purchase")}</strong>
              <br />
              <label>{game.max_picks}</label>
            </div>
            <div className="divider"></div>
            <div>
              <strong>{t("_lottery.windfall.purchase.cost")}</strong>
              <br />
              <label>
                {game ? <QSymbol value={formatValue(game ? game.buyin : 0)} /> : "N/A"}
              </label>
            </div>
          </div>

          <div className="ticket-info-section">
            <div>
              <strong>{t("_lottery.windfall.purchase.presale_sold")}</strong>
              <br />
              <label>{formatValue(game.ticket_qty, 0)}</label>
            </div>
            <div className="divider"></div>
            <div className="last-item">
              <a className="white-link-underlined">
                {t('_lottery.windfall.purchase.seize')}
              </a>
            </div>
          </div>
        </>
      )}

      {progress === 1 && (
        <div className="purchase-process text-center">
          <h2>Windfall Lottery Presale Purchase</h2>
          <br />
          <div className="confirm-info">
            <div className="cnt d-flex align-items-center justify-content-center">
              <h4>{generateComfirmationString(qty)}</h4>
            </div>
            <div className="hdivider"></div>
            <div className="cnt">
              <h4>{t("_lottery.windfall.purchase.confirmation.cost_of_purchase")}</h4>
              <h5><QSymbol value={formatValue(qty * game.buyin)}/></h5>
            </div>
          </div>
          <AlertMessage
            pClass="text-white"
            className="mt-4"
            type={messageType}
            message={message}
          />
          <br />
          <button 
              className="default-submit-button confirm-button" 
              onClick={doPurchase} 
              disabled={isPurchaseDisabled(1)}>
              {t("_lottery.windfall.purchase.confirmation.confirm")}
            </button>        
          <br /><br />
          <a onClick={cancelTicketBook} className="link-black">Cancel</a>
        </div>
      )}

      {progress === 2 && (
        <div className="purchase-process purchase-complete text-center">
          <h2>{generateThankYouString(results ? results.ticket_qty : 0)}</h2>
          <br /><br />
          <div className="bordered-info">
            <div className="w-100 fw-400">
              <Trans i18nKey="_lottery.windfall.purchase.confirmation.reply.confirmation" 
                components={{ 
                  txn: <span>{results.trx_id}</span>
                }}
              />                     
            </div>
          </div>
          <br />
          <div className="bordered-info">
            <div className="w-100 fw-400">
              {t("_lottery.windfall.purchase.confirmation.reply.email")}
            </div>
          </div>
          <br />
          <a onClick={cancelTicketBook} className="link-black">Close</a>
        </div>
      )}
    </>
  );
};

export default PresalePurchase;
