import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { images } from "../../../helper";

export const AboutUs = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="pages page-bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading">About Us</h1>

            <br />
            <p>
              Quinto Games is operated by Quinto, S.A. (Sociedad Anónima), a
              Costa Rican corporation that stands as an independent entity,
              unaffiliated with any online casino consortium.
            </p>
            <br />
            <p>
              Our commitment at Quinto Games is centered on the player. As
              players ourselves, we grasp the essentials that matter most to our
              valued customers. We prioritize reliability, fair competition,
              prompt support, and the delivery of quality games. Our dedication
              is to offer an immersive gaming experience, allowing players to
              engage and connect seamlessly on any device for their convenience.
            </p>
            <br />
            <p>
              At Quinto Games, we acknowledge the changing dynamics of online
              casino gaming, placing a significant focus on provable fairness.
              We recognize that players value the integrity of the games they
              play. That's why all Quinto Games are carefully designed with a
              100% provably fair framework. This commitment allows our players
              to verify the fairness of each game, creating an environment built
              on transparency and trust.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
