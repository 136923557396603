import initSqlJs from "sql.js";

/* eslint-disable import/no-webpack-loader-syntax */
const sqlWasmPath: string = require("!!file-loader?name=./static/js/sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm").default;

/**
 * Normalize the given path to ensure it starts with './'.
 * @param {string} path - The path to normalize.
 * @returns {string} The normalized path.
 */
const normalizePath = (path: string): string => {
  if (path.startsWith('../')) {
    return './' + path.slice(3);
  }
  if (!path.startsWith('./')) {
    return './' + path;
  }
  return path;
};

const sqlWasm = normalizePath(sqlWasmPath);
console.log("WASM file path from sqlWasmLoader:", sqlWasm); // Log the normalized path of the WASM file

/**
 * Initialize the SQL.js library.
 * @returns {Promise<any>} A promise that resolves to the initialized SQL.js library.
 */
const initializeSQL = async () => {
  console.log("Loading WASM…");
  return await initSqlJs({ locateFile: () => sqlWasm });
};

export { initializeSQL };
