import React, { useEffect, useState } from "react";
import participantImage from "../../../assets/images/qcoin.svg";
import { onData } from "../../WebSocketProvider";
import { MessageTypes } from "../../utils/Enums";
import "./Trimorra.css";
import { IRoom } from "./TrimorraBase";
import TrimorraWagerButton from "./WagerButton";

interface IWagerButtonsProps {
  room: IRoom;
  isSelected: boolean;
  onClick: (room: IRoom, isDoubleClick: boolean) => void;
}

const WagerButtonManger: React.FC<IWagerButtonsProps> = ({
  room,
  isSelected,
  onClick
}) => {
  const [waiting, setWaiting] = useState<number>(room.waiting);
  const [participant, setParticipant] = useState<boolean>(!!room.participant);

  useEffect(() => {
    const handleUpdate = (data: IData) => {
      if (data.row.room_id !== room.id) return;

      setWaiting(data.row.waiting);
      setParticipant(!!data.row.participant);

//      console.log(`TrimorraWagerContainer.Buyin: ${room.buyin} Old Waiting: ${waiting}, New Waiting: ${data.row.waiting}, Participant: ${participant}`);
    };

    onData.on(MessageTypes.TRIMORRA_ROOMS, handleUpdate);
    return () => {
      onData.off(MessageTypes.TRIMORRA_ROOMS, handleUpdate);
    };
  }, [waiting, participant]);

  return (
    <div className="wager-container">
      <TrimorraWagerButton
        room={room}
        isSelected={isSelected}
        onClick={onClick}  // Pass the handler directly
      />
      {participant && (<img src={participantImage} alt="pi" className="wager-participant-image" />)}
      
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{
            width: `${(waiting / room.tier.players) * 100}%`,
            position: 'relative',
          }}
        ></div>
      </div>
    </div>
  );
};

export default WagerButtonManger;
