import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import QButton from '../../elements/QButton';
import { copyTextToClipboard, images } from '../../../helper';
import { db, apiMessage, onData } from '../../WebSocketProvider';
import { FundTypes, MessageTypes, api } from '../../utils/Enums';
import AlertMessage, { AlertMessageType } from '../../elements/AlertMessage';
import * as utils from '../../utils/Utils';
import { validateCryptoAddress } from '../../utils/CryptoValidator';
import { NumberInput } from "../../elements/NumberInput";
import SelectorCryptoAddress from '../../elements/SelectorCryptoAddress';
import { ICryptoProps } from '../AccountBase';
import { toast } from 'react-toastify';
import "../Accounts.css"

const CryptoWithdraw: React.FC<ICryptoProps> = ({ currency }) => {
  const { t } = useTranslation();

  // Error Handling
  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const [addresses, setAddresses] = useState<ICryptoAddress[]>([]);
  const [selected, setSelected] = useState<ICryptoAddress | undefined>(undefined);
  const [exclusive, setExclusive] = useState<boolean>(false);

  const [address, setAddress] = useState<string | undefined>(undefined);
  const [amount, setAmount] = useState<string | undefined>(undefined);
  
  const accountType = "WITHDRAWAL";
  let loading:number | null = null;

  useEffect(() => {
    const fetchAddresses = (addr: string | null) => {
      const query = `
        SELECT 
          acronym, address, nickname, last_used, exclusive
        FROM 
          withdrawal_addresses
        WHERE 
          acronym = $currency
        ORDER BY 
          last_used IS NOT NULL, last_used DESC
      `;
  
      try {
        const rows: ICryptoAddress[] = [];
        let exclusiveCheck: boolean = false;
        console.log(`Initialize Unused: ${exclusiveCheck}`);
        const stmt = db.prepare(query);
        stmt.bind({$currency: currency.acronym});
        while (stmt.step()) {
          const data = stmt.getAsObject();
          exclusiveCheck = exclusiveCheck || !!data.exclusive;
          const row: ICryptoAddress = {
            acronym:  data.acronym,
            address:  data.address,
            qrcode:   data.nickname,
            lastUsed: data.last_used
          };

          if (addr && (row.address === addr)) {
            console.log(`Setting default address to ${addr}`);
            setSelected(row);
          }
          rows.push(row);
        }
        stmt.free();
        setAddresses(rows);
        setExclusive(exclusiveCheck);
  
      } catch (error) {
        console.error('Error querying deposit addresses:', error);
      }
    };

    const handleData = (data: IData) => {
      if (!!loading && loading !== data.id) return;
      loading = null;
      fetchAddresses(data.row.address);
    };

    const handleError = (data: IError) => {
      //console.log('TrimorraWagerSelection.handleError data:', data);

      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason || "An error occurred while retriving address.");
      setTimeout(() => setMessage(undefined), 5000); // Clear the message after 5 seconds
    };

    fetchAddresses(utils.readParams(currency.acronym, accountType, null));

    const addr = utils.readParams(currency.acronym, accountType, null);
    console.log(`Default address for ${currency.acronym}: ${addr}`)

    if (addr) {
      // Find currency matching the acronym
      const dfltAddr = addresses.find(address => address.address === addr);
      if (dfltAddr) {
        console.log("Setting initial address:", dfltAddr);
        setSelected(dfltAddr);
      }
    }

    onData.on(MessageTypes.CRYPTO_ADDRESS, handleData);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      onData.off(MessageTypes.CRYPTO_ADDRESS, handleData);
      onData.off(MessageTypes.ERROR, handleError);
    };
  }, []);  

  useEffect(() => {
    console.log(`CryptoDeposit Currency Update`);
  }, [currency]);


  const handleAddressSelect = (data: ICryptoAddress) => {
    utils.writeParams(currency.acronym, accountType, data.address);    
    setSelected(data);
  };  
  
  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddress(value);
    
    // Validate address
    if (!validateCryptoAddress(currency, value)) {
      setMessageType(AlertMessageType.ERROR);
      setMessage(t('Invalid address'));
      setTimeout(() => { 
        setMessage(undefined); 
      }, 3500);       
//      setIsButtonDisabled(true);
    } else {
      setMessage(undefined);
      //setIsButtonDisabled(false);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Regular expression to match a number with up to currency.scale decimal places
    const regex = new RegExp(`^\\d*\\.?\\d{0,${currency.scale}}$`);

    if (regex.test(value)) {
      setAmount(value);
    }
  };

  const manualWithdrawal = (
    <div className="withdraw-form">
      <h5>{t("Manual Withdrawal")}</h5>
      <div className="outlet-inner">
        <br/>
        <p>
          {t('_ACCOUNTING.withdraw.crypto.address.title', { acronym: currency.acronym })}        
        </p>
        <input
          className="form-control"
          type="text"
          value={address}
          onChange={handleAddressChange}
          placeholder={t('_ACCOUNTING.withdraw.crypto.address.placeholder', { acronym: currency.acronym })}
        />
        <br/>
        <p>{t('_ACCOUNTING.withdraw.crypto.amount.title')}</p>
        <input
          className="form-control"
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder={t('_ACCOUNTING.withdraw.crypto.amount.placeholder')}
        />
      </div>
    </div>
  );

  return (
    <>
      {!!addresses.length ? (

        <SelectorCryptoAddress
          label={t('Select Address')}
          addresses={addresses}
          onSelect={handleAddressSelect}
          preSelect={selected}
          accountType={accountType}
        />
      ) : (
        manualWithdrawal        
      )}

      <br />      
      {message && (<AlertMessage type={messageType} message={message} />)}
    </>
  );
};
export default CryptoWithdraw;
