import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { images } from "../../helper";
import { apiMessage, onData } from "../WebSocketProvider";
import { dbICurrency, fetchCurrencies } from "../accounts/AccountBase";
import AlertMessage, { AlertMessageType } from "../elements/AlertMessage";
import QButton from "../elements/QButton";
import SelectorCurrency from "../elements/SelectorCurrency";
import { FundTypes, MessageTypes, api } from "../utils/Enums";
import { QCardHeaders } from "./QCardHeaders";


interface CurrencyOption {
  icon: string;
  value: number;
  label: string;
  symbol: string;
  unused: boolean;
  data: any;
  isDisabled: boolean;
  balance: number;
}

interface Payload {
  acronym: string;
  address: string;
  nickname: string;
  exclusive: boolean;
  doomsday: boolean;
  code?: string | null;
}

const SecureAddresses: React.FC = () => {
  const { t } = useTranslation();

  const [code, setCode] = useState<string | null>(null);
  const [replyId, setReplyId] = useState<number | null>(null);
  const [toggleAddAddress, setToggleAddAddress] = useState<boolean>(false);

  const [selected, setSelected] = useState<ICurrency | undefined>(undefined);
  
  const [address, setAddress] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [excusive, setExcusive] = useState<boolean>(false);
  const [doomsday, setDoomsday] = useState<boolean>(false);

  const [currencies, setCurrencies] = useState<ICurrency[]>([]);  
  // Filter currencies for withdrawal (only those with balance)
  const withdrawCurrencies = currencies.filter(currency => (currency.fundType === FundTypes.CRYPTO));
 const accountType="WITHDRAWAL";

  useEffect(() => {
    const handleData = (data: {row: dbICurrency}) => {
      const newList = fetchCurrencies();
      if (currencies.length !== newList.length)
        setCurrencies(newList);
    };
    
    setCurrencies(fetchCurrencies());
    onData.on(MessageTypes.BALANCE, handleData);
    return () => {
      onData.off(MessageTypes.BALANCE, handleData);
    };
  }, []);

  const handleCurrencySelect = (currency: ICurrency) => {
    console.log("Selected currency:", currency); // Debugging log
    //utils.writeParams(accountType, "acronym", currency.acronym);
    setSelected(currency);
  };
  
  
  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string>("");

  const handleSubmit = () => {
    setMessageType(AlertMessageType.WARNING);

    if (!address) {
      setMessage("Please enter address");
      return;
    }

    if (!nickname) {
      setMessage("Please enter nickname");
      return;
    }

    if (selected) {
      const params: Payload = {
        acronym: selected.acronym,
        address: address,
        nickname: nickname,
        exclusive: !!excusive,
        doomsday: !!doomsday,
      };

      setReplyId(apiMessage(api.SECURE_ADDRESS, params));
    }
  };

  useEffect(() => {
    const handleData = (arg: any) => {
      if (arg.id === replyId) {
        setReplyId(null);
        if (arg.hasOwnProperty("error")) {
          setMessageType(AlertMessageType.WARNING);
          setMessage(arg.error.reason);
        } else {
          toast.success("Address saved successfully");
          resetAddData();
        }
      }
    };

    onData.addListener(MessageTypes.TODO, handleData);
    return () => {
      onData.removeListener(MessageTypes.TODO, handleData);
    };
  }, [replyId]);

  const resetAddData = () => {
    setAddress("");
    setNickname("");
    setExcusive(false);
    setDoomsday(false);
    setToggleAddAddress(!toggleAddAddress);
    setMessage("");
  };

  return (
    <div className="outlet-inner">
      <div className="card user-account-tabs-card">
        <div className="card-body">
          <QCardHeaders
            title="WITHDRAWAL ADDRESSES"
            subTitle="Enter withdrawal addresses for ease of use"
            desc="You can edit and delete addresses"
          />

          <div className="card-content">
          <SelectorCurrency
            label="Select Currency"
            currencies={withdrawCurrencies}
            onSelect={handleCurrencySelect}
            preSelect={selected}
            accountType={accountType}
          />

          {selected && (
            <>
                <br />
                <div className="withdrawal-profile">
                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div className="">
                      <h4>
                        <span style={{ textTransform: "uppercase" }}>
                          {selected.descr}
                        </span>{" "}
                        ({selected.acronym})
                      </h4>
                    </div>
                    <div>
                      <QButton
                        onClick={() => {
                          setToggleAddAddress(!toggleAddAddress);
                        }}
                        noIcon
                        className="btn-sm btn-mini align-items-end"
                        text="Add Address"
                      />
                    </div>
                  </div>

                  <hr />
                  {/* < AddressList ws={ws} ctype={ctype}> */}
                </div>

                {toggleAddAddress && (
                  <>
                    <br />
                    <div className="withdrawal-profile">
                      <div className="d-flex justify-content-center align-items-center">
                        <h5>Enter The Withdrawal Address</h5>
                      </div>

                      <div className="withdrawal-profile-inner-content">
                        <div className="row align-items-center">
                          <div className="col-md-10">
                            <img
                              className="w-30 mr-10"
                              src={`/currencies/${selected.acronym}.svg`}
                              alt="Currency"
                            />
                            <strong>
                              {selected.descr} ({selected.acronym})
                            </strong>
                          </div>
                          <div className="col-md-2 text-end">
                            <img
                              onClick={() => {
                                resetAddData();
                              }}
                              src={images.svg_ic_close}
                              className="close-icon"
                              alt="Close"
                            />
                          </div>
                        </div>
                        <br />
                        <div className="d-flex align-items-center">
                          <h5 className="col-md-3">Enter Address</h5>
                          <input
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Enter address"
                          />
                        </div>
                        <br />
                        <div className="d-flex align-items-center">
                          <h5 className="col-md-3">Address nickname</h5>
                          <input
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Enter address nickname"
                          />
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-check-box">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  checked={excusive}
                                  onChange={(e) => setExcusive(e.target.checked)}
                                  type="checkbox"
                                  role="switch"
                                  id="onlyAddressAllowed"
                                />
                                <label
                                  style={{ fontSize: "18px" }}
                                  className="form-check-label m-0"
                                  htmlFor="onlyAddressAllowed"
                                >
                                  Only Address Allowed
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="d-flex align-items-start">
                              <img
                                style={{
                                  width: "20px",
                                  marginRight: "10px",
                                  marginTop: "5px",
                                }}
                                src={images.svg_ic_info_dark}
                                alt="Info"
                              />
                              <p style={{ fontSize: "16px" }}>
                                Once you set this address, it will become your
                                permanent withdrawal address, and no other
                                addresses will be allowed for use
                              </p>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-check-box">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={doomsday}
                                  onChange={(e) => setDoomsday(e.target.checked)}
                                  role="switch"
                                  id="DoomsdayAddress"
                                />
                                <label
                                  style={{ fontSize: "18px" }}
                                  className="form-check-label m-0"
                                  htmlFor="DoomsdayAddress"
                                >
                                  Doomsday Address
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="d-flex align-items-start">
                              <img
                                style={{
                                  width: "20px",
                                  marginRight: "10px",
                                  marginTop: "5px",
                                }}
                                src={images.svg_ic_info_dark}
                                alt="Info"
                              />
                              <p style={{ fontSize: "16px" }}>
                                In the event of a site takedown or permanent
                                closure, this address will enable us to return
                                funds to our members
                              </p>
                            </div>
                          </div>
                        </div>
                        <br />

                        <AlertMessage
                          className="mt-4"
                          type={messageType}
                          message={message}
                        />
                        <br />
                        <QButton
                          className={`default-selection-button request-button`}
                          onClick={handleSubmit} 
                          block 
                          text="Submit Address" 
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default SecureAddresses;