import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { images } from "../../../helper";
import { MessageTypes, TabTypes } from "../../utils/Enums";
import { db, onData } from "../../WebSocketProvider";
import JackpotAnimator, { IJackpotAnimator } from "../../elements/JackpotAnimator";
import * as utils from "../../utils/Utils";
import QSymbol from "../../utils/QSymbol";
import ViewSelector from "./ViewSelector";
import PreLoader from "../../PreLoader";
import TrackPage from "../../inc/TrackPage";
import "./Trimorra.css";

/**
 * Trimorra Component
 * This component displays the Trimorra game page with estimated jackpot and tier selection.
 */
const Trimorra: React.FC = () => {
  TrackPage();
  
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [defId, setDefId] = useState<string | null>(null); // Storage of the Trimorra Def Id
  const [estJackpot, setEstJackpot] = useState<number>(0); // State for storing estimated jackpot
  const [params, setParams] = useState<IJackpotAnimator>({
    init: {
      value: 0,     // Initial value
      precision: 0, // Number of decimal points
      symbol: true, // Display currency symbolwe 
      time: 10.00    // Time to achieve the target value in seconds
    },
    target: 0       // Target value to reach
  });

  useEffect(() => {
    /**
     * Handles changes by updating the estimated jackpot
     */
    const updateJackpot = (data: IData) => {
      if (data.id) {
        setJackpot();
        return;
      }

      console.log("Trimorra data", data.row);
      
      setEstJackpot(data.row.est_jackpot);
      setParams((prevProps) => ({
        ...prevProps,
        target: data.row.est_jackpot,
      }));      
    };

    const setJackpot = () => {
      if (!db) throw new Error("Database instance not available");

      const query = `
      SELECT 
        est_jackpot
      FROM
        trimorra_defs;
      `;

      const stmt = db.prepare(query);
      const data = stmt.getAsObject({});
      stmt.free();

      const jackpot:number = Number(data.est_jackpot);
      console.log(`Trimorra.setJackpot ${jackpot}`);

      setEstJackpot(jackpot);
      setParams((prevProps) => ({
        ...prevProps,
        init: {
          ...prevProps.init,
          value: jackpot,
        },
        target: jackpot,
      }));

      console.log("Trimorra data is loaded");      
      setLoaded(true);
    }        

    onData.on(MessageTypes.TRIMORRA, updateJackpot);

    const id = utils.getTabRefId(TabTypes.TRIMORRA);
    setDefId(id);
    if (!id)
      throw new Error("Trimorra Tab Information Unavailable");

    const reply = utils.doSubscribed(id);
    console.log(`Trimorra defIf ${id} is Loaded ${!reply} `);
    if (!reply ) 
      setJackpot();


    return () => {
      onData.off(MessageTypes.TRIMORRA, updateJackpot);
    };
  }, []);

  if (!loaded) {
    return <PreLoader />
  }

  return (
    <section className="trimorra">
      <div className="container">
        <div className="d-flex justify-content-center">
          <img
            src={images.svg_trimorra_game_logo}
            alt="Trimorra Logo"
            width="100%"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div className="jackpot-payout-section">
            <div className="jackpot-payout-box">
              <div className="jackpot-payout-border">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h1>{t('_trimorra.jackpot')}</h1>
                  <div className="presale-counter">
                    <JackpotAnimator {...params} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>
          <small>
            <Trans
              i18nKey="_trimorra.jackpot_statement"
              components={{ symbol: <QSymbol /> }}
            />
          </small>
        </p>
      </div>
      
      <div className="container mt-5">
        <ViewSelector />
      </div>
    </section>
  );
};

export default Trimorra;
