import axios from "axios";
import { useState } from "react";
import Helmet from "react-helmet";

export const KlaviyoSignUpForm = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // const requestOptions = {
    //   method: "POST",
    //   headers: ,
    //   data:

    // };

    axios.post(
      "https://a.klaviyo.com/api/v2/list/QYXgm4/members?api_key=pk_193dcb887145ca732242f372367a2a6610",
      {
        data: {
          profiles: [
            {
              email: email,
            },
          ],
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "cache-control": "no-cache",
          "Access-Control-Allow-Origin": "true",
        },
      },
    );

    // fetch(
    //   "https://a.klaviyo.com/api/v2/list/QYXgm4/members?api_key=pk_193dcb887145ca732242f372367a2a6610",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((res) => console.log(res));
  };

  return (
    <>
      <section className="newsletter pb-5 spacerT">
        <div className="container">
          <h4>Be part of the game</h4>
          <h1>Sign up for updates</h1>

          {/* <form action="" onSubmit={handleSubmit} >
                        <div className="input-group">
                            <input type="email" value={email} onChange={(e)=> setEmail(e.target.value)} placeholder="Enter your email" className="form-control" />
                            <button type="submit" className="btn btn-success">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1.3em" viewBox="0 0 512 512">
                                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
                                </svg>
                                SIGN UP FOR NEWSLETTER
                            </button>
                        </div>
                    </form> */}

          <div className="klaviyo-form-XBYumB"></div>
        </div>
      </section>

      <Helmet>
        <script
          async
          type="text/javascript"
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XZy4H3"
        ></script>
      </Helmet>
    </>
  );
};
