import React, { useEffect, useState } from "react";
import TierButton from "./TierButton";
import participantImage from "../../../assets/images/qcoin.svg";
import { ITier, getTierImage } from "./TrimorraBase";
import { db, onData } from "../../WebSocketProvider";
import { MessageTypes } from "../../utils/Enums";
import "./Trimorra.css";


interface ITierButtonsProps {
  tier: ITier;
  isSelected: boolean;
  onClick: (tier: ITier) => void;
}

const TierButtonManger: React.FC<ITierButtonsProps> = ({
  tier,
  isSelected,
  onClick
}) => {
  const [participant, setParticipant] = useState<boolean>(false);  

  const color = "white";

  useEffect(() => {
    const handleUpdate = (data: IData) => {
      if (data.row.tier !== tier.tier) return;
      participantCheck();
    };

    const participantCheck = () => {
      if (!db) throw new Error("Database instance not available");
      const startTime = performance.now();

      const query = `
      SELECT 
        count(*) as participating
      FROM
        trimorra_rooms
      WHERE
        tier = $tier AND participant = TRUE;
      `;

      const stmt = db.prepare(query);
      const result = !!stmt.getAsObject({ $tier: tier.tier }).participating;
      stmt.free();

      if (result !== participant) setParticipant(result);
    };

    participantCheck();

    onData.on(MessageTypes.TRIMORRA_ROOMS, handleUpdate);
    return () => {
      onData.off(MessageTypes.TRIMORRA_ROOMS, handleUpdate);
    };


  }, [participant]);

  return (
    <div className="tier-container">
      <TierButton
        tier={tier}
        isSelected={isSelected}
        onClick={onClick}
      />
      <div
        style={{ color }}
        dangerouslySetInnerHTML={{ __html: getTierImage(tier.ord, color) }}
        className="tier-image"
      />
      {participant && (<img src={participantImage} alt="pi" className="tier-participant-image" />)}      
    </div>

  );
};

export default TierButtonManger;
