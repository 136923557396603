interface CryptoAddressValidator {
  acronym: string;
  regex: RegExp;
}

const cryptoValidators: CryptoAddressValidator[] = [
  { acronym: 'BTC', regex: /^(1|3|bc1)[a-zA-Z0-9]{25,39}$/ }, // Bitcoin
  { acronym: 'BCH', regex: /^(bitcoincash:)?(q|p)[a-zA-Z0-9]{41}$/ }, // Bitcoin Cash
  { acronym: 'BSV', regex: /^(1|3|bc1)[a-zA-Z0-9]{25,39}$/ }, // Bitcoin SV
  { acronym: 'LTC', regex: /^(L|M|ltc1)[a-zA-Z0-9]{25,39}$/ }, // Litecoin
  { acronym: 'DASH', regex: /^X[1-9A-HJ-NP-Za-km-z]{33}$/ }, // Dash
  { acronym: 'DOGE', regex: /^D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}$/ }, // Dogecoin
  { acronym: 'ETH', regex: /^0x[a-fA-F0-9]{40}$/ }, // Ethereum
  { acronym: 'BNB', regex: /^0x[a-fA-F0-9]{40}$/ }, // Binance Coin
  { acronym: 'XRP', regex: /^r[0-9a-zA-Z]{24,34}$/ }, // Ripple
  { acronym: 'ADA', regex: /^addr1[a-zA-Z0-9]{58}$/ }, // Cardano
  { acronym: 'DOT', regex: /^1[a-zA-Z0-9]{45,}$/ }, // Polkadot
  { acronym: 'ZEC', regex: /^t1[a-zA-Z0-9]{33}$/ }, // Zcash
  { acronym: 'USDC', regex: /^0x[a-fA-F0-9]{40}$/ }, // USD Coin
  { acronym: 'USDT', regex: /^0x[a-fA-F0-9]{40}$/ }, // Tether
  { acronym: 'BUSD', regex: /^0x[a-fA-F0-9]{40}$/ }, // Binance USD
  // Add more validators as needed
];

export const validateCryptoAddress = (currency: ICurrency, address: string): boolean => {
  const validator = cryptoValidators.find(v => v.acronym === currency.acronym);
  if (validator) {
    return validator.regex.test(address);
  }
  return false;
};
