import { Link } from "react-router-dom";
import { images } from "../../../helper";

export const TermsAndConditions = () => {
  return (
    <section className="pages page-bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading">Terms and Conditions</h1>
            <br />
            <strong className="">
              Presented by Quinto S.A.Revised on July 26, 2023
            </strong>
            <br />
            <br />
            <br />
            <h5 className="text-primary">General</h5>
            <br />
            <ol type="A">
              <li>
                The terms and conditions ("Terms and Conditions") govern the use
                of games provided through this website, other URLs licensed by
                Quinto, S.A. ("the Company"), and the software provided ("the
                Software"). Quinto, S.A., a Costa Rican company, reserves the
                right to modify the games available on the Software at its
                discretion.
              </li>{" "}
              <br />
              <li>
                The Member should read the Terms and Conditions in their
                entirety before clicking the acceptance box. If the Member does
                not agree with any provision of these Terms and Conditions, the
                Software should not be used.
              </li>{" "}
              <br />
              <li>
                {" "}
                Acceptance of the Terms and Conditions is confirmed by clicking
                the corresponding box during the registration process. The
                Member, by clicking this box, acknowledges that they have read,
                understood, and agreed to the Terms and Conditions. The use of
                any part of the Software signifies agreement with these Terms
                and Conditions.
              </li>{" "}
              <br />
              <li>
                {" "}
                The Member agrees to be bound by the Terms and Conditions, which
                may be amended by the Company from time to time.
              </li>{" "}
              <br />
              <li>
                The Company will notify the Member in case of any amendments to
                these Terms and Conditions that could potentially limit the
                Member's rights or have detrimental effects. The Member will be
                required to re-confirm acceptance in such a scenario. If the
                Member does not agree to the updated Terms and Conditions, the
                Software must not be used.
              </li>
              <br />
              <li>
                {" "}
                The Company may publish these Terms and Conditions in multiple
                languages for the Member's convenience. However, only the
                English version forms the legal basis of the relationship
                between the Member and the Company. In the event of a
                discrepancy between the English version and any other language
                version of these Terms and Conditions, the English version
                prevails.
              </li>{" "}
              <br />
              <li>
                If the Company's Terms and Conditions conflict with or are less
                comprehensive than those of the game providers, the latter's
                terms shall supersede the Company's.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Member Account</h5>
            <br />
            <ol type="A">
              <li>
                Registration and Account Opening:
                <br />
                <ol type="i">
                  <br />
                  <li>
                    {" "}
                    To place bets using the Software, the Member must personally
                    register and open a Member account.
                  </li>{" "}
                  <br />
                  <li>
                    Each Member is permitted to have only one Member account.
                    Any attempts to open multiple Member accounts may result in
                    all associated accounts being blocked or closed, and any
                    bets may be voided.
                  </li>{" "}
                  <br />
                  <li>
                    The Member is required to provide valid email, unique
                    username, and a password. The Member must keep this login
                    information secure and not share it with anyone. The Company
                    is not responsible for any misuse of the Member account
                    resulting from the disclosure of login details.
                  </li>{" "}
                  <br />
                  <li>
                    {" "}
                    The Company will not except vulgar or derogatory usernames
                    in any form or language. Company reserves the right to carry
                    out verification of username and if in violation may block
                    or close the Member account.
                  </li>{" "}
                  <br />
                  <li>
                    The Member is solely responsible for the accuracy and
                    completeness of this information. If the Member provides
                    false or misleading information, the Company reserves the
                    right to carry out verification procedures and may block or
                    close the Member account.
                  </li>{" "}
                  <br />
                  <li>
                    If a Member discovers multiple registered Member accounts
                    under their name, the Member must notify the Company
                    immediately. Failure to do so may lead to the Member account
                    being blocked.
                  </li>{" "}
                  <br />
                  <li>
                    The Company reserves the right to refuse or close a Member
                    account at its discretion. However, any contractual
                    obligations already made by the Company will be honored.
                  </li>{" "}
                  <br />
                  <li>
                    The Company may, at its discretion, declare a wager void in
                    full or in part if there is an obvious error, mistake,
                    misprint, or technical error on the pay-table, odds, or
                    software.
                  </li>{" "}
                  <br />
                </ol>
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Member Obligations</h5>
            <br />
            <ol type="A">
              <li>
                As a Member, you affirm that: <br />
                <ol type="i">
                  <br />
                  <li>
                    You are at least 18 years of age or older, depending on the
                    legal age of majority in your jurisdiction, and are legally
                    permitted to participate in the games offered on the
                    Website;
                  </li>{" "}
                  <br />
                  <li>
                    Your participation in the games is purely for recreational
                    and entertainment purposes, and not for professional use;
                  </li>{" "}
                  <br />
                  <li>
                    You are participating in the games on your own behalf, not
                    for anyone else;
                  </li>
                  <br />
                  <li>
                    All the information provided to the Company during your term
                    of membership is accurate, complete, and current. If there
                    are any changes to this information, you must notify the
                    Company immediately;
                  </li>{" "}
                  <br />
                  <li>
                    You are solely responsible for declaring and paying any
                    taxes on winnings as required by your local laws;
                  </li>{" "}
                  <br />
                  <li>
                    Any funds deposited into your Member account are legal and
                    not linked to any illegal activities or sources;
                  </li>{" "}
                  <br />
                  <li>
                    You understand that participation in the games may lead to a
                    loss of funds deposited into your Member account;
                  </li>{" "}
                  <br />
                  <li>
                    You agree not to engage in any fraudulent, collusive, or
                    illegal activities during your or any third party’s
                    participation in the games. You also agree not to use any
                    software-assisted methods, techniques, or hardware devices
                    for your participation in the games. The Company reserves
                    the right to nullify any wagers in case of such behavior;
                  </li>{" "}
                  <br />
                  <li>
                    All funds in your Member account are legally yours.
                  </li>{" "}
                  <br />
                </ol>
              </li>
              <li>
                {" "}
                The Company or its affiliates own the computer software,
                graphics, user interface, and the Software provided to you, and
                these are protected by copyright laws. You are only allowed to
                use the Software for personal, recreational purposes in
                compliance with all rules, terms, conditions established by the
                Company, and applicable laws and regulations.{" "}
              </li>{" "}
              <br />
              <li>
                The Company expects all Members of its website to refrain from
                using offensive comments during gameplay. The Company reserves
                the right to terminate Members accounts if such behavior is
                discovered.
              </li>{" "}
              <br />
              <li>
                The Company retains the right to refuse and/or limit wagers.{" "}
              </li>{" "}
              <br />
              <li>
                The Company retains the right to change or end any games and/or
                events offered on the Website.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary"> Privacy Policy</h5>
            <br />
            <ol type="A">
              <li>
                {" "}
                The Member acknowledges and agrees that the Company can, if
                deemed necessary, gather and utilize Member data for the purpose
                of enabling access to and use of the Software, as well as
                facilitating game participation and relevant operations.{" "}
              </li>{" "}
              <br />
              <li>
                The Company acknowledges its obligations under relevant data
                protection laws when collecting Member information. It commits
                to preserving Member privacy and protecting personal information
                following best business practices and applicable laws.
              </li>{" "}
              <br />
              <li>
                The Company will use Member data to inform Members of changes,
                new services, and promotions that may be of interest. Members
                can opt out of these direct marketing communications.
              </li>{" "}
              <br />
              <li>
                Member data will not be disclosed to third parties unless
                necessary for processing game participation requests or when
                mandated by law. However, the Company may disclose specific
                personal data for promotional purposes related to jackpot
                winnings. Personal data may also be shared with the Company's
                business partners, suppliers, or service providers for the
                overall functioning of the Website. The Company's employees,
                including Customer Support and the payment team, may have access
                to Member data to execute their duties and provide optimum
                assistance. By agreeing to these terms, Members consent to such
                disclosures.
              </li>{" "}
              <br />
              <li>
                The Company will preserve all provided personal data. Members
                have the right to access their personal data held by the
                Company. The Company will retain data as long as necessary for
                its operations, unless law requires its destruction.
              </li>{" "}
              <br />
              <li>
                {" "}
                The Company uses cookies to enhance Member experience, monitor
                Software visits, and improve its services. Members can choose to
                disable cookies; however, doing so may limit the Software's
                functionality.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Complaints</h5>
            <br />
            <ol type="A">
              <li>
                {" "}
                If Members have complaints about the services, they can reach
                out to the Customer Support team via email
                (support@quinto.games) or using WhatsApp +506 7258 7215.
              </li>
              <br />
              <li>
                {" "}
                The Company will endeavor to promptly address and resolve all
                reported issues.{" "}
              </li>
              <br />
            </ol>
            <h5 className="text-primary"> Miscarried and Aborted Games</h5>
            <br />
            <ol type="A">
              <li>
                The Company bears no liability for any downtime, server
                disruptions, lagging, or any technical or political disturbance
                to the gameplay. Any refunds will be given solely at the
                discretion of the Company management.
              </li>{" "}
              <br />
              <li>
                The Company accepts no liability for damages or losses alleged
                to have resulted from or in connection with the Website or its
                content. This includes, but is not limited to, operational or
                transmission delays or interruptions, data loss or corruption,
                communication failures, misuse of the Website or its content by
                any person, or content errors or omissions.
              </li>{" "}
              <br />
              <li>
                In case of a system malfunction, all wagers are deemed void.
              </li>{" "}
              <br />
              <li>
                If a game starts but fails due to a system malfunction, the
                Company will refund the Member's wager to their Member account.
                If the account no longer exists, the refund will be issued in an
                approved manner. If the Member had accrued credit at the time of
                the game failure, the monetary value of the credit will be
                credited to the Member's account or, if the account no longer
                exists, paid out in an approved manner.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Handling of Funds</h5>
            <br />
            <ol type="A">
              <li>
                All funds deposited into a Member’s account are maintained in
                their original form.
              </li>
              <br />
              <li>
                The Company maintains updated exchange rates for all accepted
                currencies on the platform, approximately refreshed every six
                minutes.
              </li>{" "}
              <br />
              <li>
                All wagers amounts on the platform are presented exclusively
                using the site’s internal currency, known as Quintoshi ("
                <span className="q-symbol">&#xE1E0;</span>"), which holds parity
                with the US Dollar.
              </li>{" "}
              <br />
              <li>
                When a wager is placed, the required amount of funds is
                converted from the deposited currency or currencies into
                Quintoshi’s, based on the current exchange rates.
              </li>
              <br />
              <li>
                A wager may be fulfilled using multiple currencies. If the
                Member's account has insufficient funds to fulfill a wager, the
                Member will be notified accordingly.
              </li>
              <br />
              <li>
                Winnings from a wager will be allocated back to the Member's
                account based on the currency used to fulfill the wager.
                <br />
                <ol type="i">
                  <br />
                  <li>
                    {" "}
                    In the case of multiple currencies used to fulfill a wager,
                    winnings will be allocated proportionally to each currency's
                    contribution.
                  </li>
                </ol>
              </li>{" "}
              <br />
              <li>
                In the event of a winning wager, the exchange rates at the time
                of the wager placement and at the time of payout are compared.
                The higher of the two exchange rates is used to calculate the
                payout amount, which will be converted back to the original
                currency used for the wager.
              </li>{" "}
              <br />
              <li>
                {" "}
                The Company reserves the right to hold winnings for up to 72
                hours for verification purposes if the winning amount exceeds
                500 times the wager amount.
              </li>{" "}
              <br />
              <li>
                The Company does not provide any form of credit for game
                participation.
              </li>{" "}
              <br />
              <li>
                Members have the ability to transfer funds from their account to
                the account of another Member.
              </li>{" "}
              <br />
              <li>
                The Member agrees that funds deposited into the Member account
                will not be associated with any illegal activities or sources.
              </li>{" "}
              <br />
              <li>
                The Company reserves the right to perform additional
                verification procedures to verify the Member's identity for
                deposits and withdrawals made to and from the Member's account.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Deposits</h5>
            <br />
            <ol type="A">
              <li>
                The Company accepts deposits in the following fiat currencies:
                USD, CAD, EUR, GBP, AUD, NZD, KRW, JPY, CHF. The minimum deposit
                for fiat currencies is{" "}
                <span className="q-symbol">&#xE1E0;</span>25.00.
              </li>{" "}
              <br />
              <li>
                The Company also accepts the following cryptocurrencies with no
                minimum deposit: USDC, USDT, BUSD, BTC, LTC, BCH, DASH, DOGE,
                XMR, ETH, BNB, XRP, ADA, DOT, ZEC.
              </li>{" "}
              <br />
              <li>
                For cryptocurrency deposits, the Member must request a deposit
                address within the Software, specific to the cryptocurrency
                being deposited. This address is unique to the Member's Member
                account. The Member may request a new deposit address only after
                using the currently assigned deposit address at least once.
              </li>{" "}
              <br />
              <li>
                There is no imposed minimum deposit amount. However, the Member
                should deposit a reasonable amount of funds to offset any
                potential transaction fees. Deposited funds will be held by the
                Company until all necessary confirmations have been received.
              </li>
              <br />
            </ol>
            <h5 className="text-primary">Withdrawals</h5>
            <br />
            <ol type="A">
              <li>
                Withdrawals can be requested in any of the accepted fiat and
                cryptocurrencies.
              </li>
              <br />
              <li>
                Withdrawals will be processed to the respective account
                associated with the selected currency. For cryptocurrencies,
                this would mean your corresponding wallet. For fiat currencies,
                this would be the bank account or payment method used for
                deposit.
              </li>
              <br />
              <li>
                {" "}
                Withdrawals in fiat currencies may be subject to a fee of up to
                10% depending on the withdrawal method.
              </li>{" "}
              <br />
              <li>
                Cryptocurrency withdrawals are processed in batches
                approximately every 4 hours.
              </li>
              <br />
              <li>
                Withdrawals in cryptocurrencies are subject to mining fees,
                which will be deducted from the withdrawal amount. The minimum
                withdrawal for cryptocurrencies is three times the applicable
                mining fee.
              </li>{" "}
              <br />
              <li>
                Prior to processing a withdrawal, all deposits must have cleared
                and confirmed completely. For cryptocurrencies, a minimum number
                of confirmations on the blockchain are required as follows:
              </li>{" "}
              <br />
              <table className="mini-table">
                <thead>
                  <th>Currency</th>
                  <th>Name</th>
                  <th>Confirmations</th>
                </thead>
                <tbody>
                  <tr>
                    <td>BNB</td>
                    <td>Binance Coin</td>
                    <td> 12</td>
                  </tr>
                  <tr>
                    <td>BUSD</td>
                    <td>Binance USD</td>
                    <td> 30</td>
                  </tr>
                  <tr>
                    <td>BTC</td>
                    <td>Bitcoin Coin</td>
                    <td> 12</td>
                  </tr>
                  <tr>
                    <td>BNB</td>
                    <td>Binance Coin</td>
                    <td> 6</td>
                  </tr>
                  <tr>
                    <td>BCH</td>
                    <td> Bitcoin Cash</td>
                    <td> 6</td>
                  </tr>
                  <tr>
                    <td>ADA</td>
                    <td> Cardano</td>
                    <td> 15</td>
                  </tr>
                  <tr>
                    <td>DASH</td>
                    <td> Cash</td>
                    <td> 6</td>
                  </tr>
                  <tr>
                    <td>DOGE</td>
                    <td> Dogecoin</td>
                    <td> 6</td>
                  </tr>
                  <tr>
                    <td>ETH</td>
                    <td> Ethereum</td>
                    <td> 30</td>
                  </tr>
                  <tr>
                    <td>LTC</td>
                    <td> Litecoin</td>
                    <td> 12</td>
                  </tr>
                  <tr>
                    <td>DOT</td>
                    <td> Polkadot</td>
                    <td> 50</td>
                  </tr>
                  <tr>
                    <td>XRP</td>
                    <td> Ripple</td>
                    <td> Upon Consensus</td>
                  </tr>
                  <tr>
                    <td>USDT</td>
                    <td> Tether</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>USDC</td>
                    <td> USD Coin</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>ZEC</td>
                    <td>Zcash</td>
                    <td>6</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <li>
                In case of erroneous credit to your account, the Company
                reserves the right to correct such errors by removing any
                winnings that do not belong to you from your Member account. If
                such funds have been withdrawn before the Company becomes aware
                of the error, you are obliged to return the funds. Otherwise,
                the amount will be treated as a debt owed by you to the Company.
                You are also required to notify the Company immediately of any
                such errors.
              </li>{" "}
              <br />
              <li>
                Additional verification procedures may be carried out by the
                payment team at the Company when processing a withdrawal.
                Transactions may be checked to prevent money laundering. Any
                suspicious activity relating to any of the games should be
                reported to the Company immediately.
              </li>{" "}
              <br />
              <li>
                The Company may suspend, block or close a Member account and
                withhold funds if required under applicable anti- money
                laundering laws. Enhanced due diligence may be done in respect
                of withdrawals of funds not used for wagering.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Quinto Rewards Program</h5>
            <br />
            <ol type="A">
              <li>
                Initial Rate: Upon membership enrollment, all members will be
                allocated an initial Quinto Rewards rate of 5% (five percent).
                This rate has the potential to increase, ultimately reaching a
                maximum of 25% (twenty-five percent).
              </li>{" "}
              <br />
              <li>
                Calculation: Quinto Rewards are computed based on the company's
                revenue generated from the member's wagers, rather than the
                wagered amounts themselves.
              </li>{" "}
              <br />
              <li>
                Currency: All members' rewards are based on the currency in
                which the wager was fulfilled. Thus, rewards credit can span
                multiple currencies.
              </li>{" "}
              <br />
              <li>
                Percentage Increase: Members can enhance their Quinto Rewards in
                increments of 0.5% (half a percent) by achieving rewards
                milestones.{" "}
                <ol type="i">
                  {" "}
                  <br />
                  <li>
                    {" "}
                    Milestones{" "}
                    <ol type="1">
                      {" "}
                      <br />
                      <li>
                        {" "}
                        Milestone Formula:{" "}
                        <ol type="a">
                          {" "}
                          <br />
                          <li>
                            {" "}
                            The initial earning requirement starts at{" "}
                            <span className="q-symbol">&#xE1E0;</span>625.00 and
                            increases by 9.93% with each increment.{" "}
                          </li>{" "}
                          <br />
                          <li>
                            {" "}
                            To calculate the player's threshold requirement:{" "}
                            <ul>
                              <li>
                                Divide the Reward percentage by (100% minus the
                                Reward percentage).
                              </li>{" "}
                              <br />
                              <li>
                                Multiply the result by the Earning requirement.
                              </li>
                            </ul>
                            <ol type="i">
                              <br />
                              <li>
                                For example, to move up from 5.0% to 5.5%, a
                                player must earn{" "}
                                <span className="q-symbol">&#xE1E0;</span>32.89
                                in Quinto Reward. Calculation:{" "}
                                <ul>
                                  <br />
                                  <li>
                                    5% / (100% - 5%) = 0.05 / 0.95 = 0.5263
                                  </li>
                                  <br />
                                  <li>0.5263 * 625.00 = 32.89.</li>
                                </ul>
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>{" "}
                      <br />
                      <li>
                        {" "}
                        Milestone Currency: All milestone calculations are
                        conducted in Quintoshis to ensure fairness.
                      </li>{" "}
                      <br />
                      <li>
                        Milestone Achievement: When the milestone threshold is
                        reached, the member's Quinto Rewards percentage will
                        automatically increase by 0.5% (half a percent), not
                        exceeding 25% (twenty-five percent).
                      </li>{" "}
                      <br />
                      <li>
                        Milestone Reset: If members reward percentage has not
                        been capped, then upon each increase in the member's
                        Quinto Rewards will be assigned a new milestone
                        threshold. Please note that any excess funds from
                        achieving a previous milestone will be credited towards
                        the next milestone threshold.
                      </li>{" "}
                      <br />
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                Posting of Rewards{" "}
                <ol type="i">
                  {" "}
                  <br />
                  <li>
                    Schedule: Quinto Rewards are posted every Monday, starting
                    at 1:00 am UTC. Please be aware that the posting process may
                    take several hours.
                  </li>{" "}
                  <br />
                  <li>
                    {" "}
                    Crediting: Quinto Rewards are credited in the same currency
                    in which your wagers were placed.
                  </li>{" "}
                  <br />
                </ol>
              </li>
            </ol>
            <h5 className="text-primary">Game Participation and Refunds</h5>
            <br />
            <ol type="A">
              <li>
                Be advised that our games are played and consumed in real-time.
                Therefore, we are unable to provide returns, refunds, or
                cancellation of service once a game has been played. During
                gameplay, the applicable currency, will be deducted from your
                Member account instantly.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Closure of Member Accounts</h5>
            <br />
            <ol type="A">
              <li>
                If you desire to close your Member account, you may do so
                anytime by contacting our customer support at
                support@quinto.games in written form. The actual closure of the
                account corresponds to the termination of these Terms and
                Conditions.
              </li>{" "}
              <br />
              <li>
                Members who seek to recover funds held in a closed, locked, or
                excluded account are recommended to contact Customer Support.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Restricted Countries</h5> <br />
            <ol type="A">
              <li>
                {" "}
                Since our company is incorporated, registered, and operates
                gaming from Costa Rica, we are obliged to adhere to regulations
                that restrict us from offering our online gaming services to
                individuals residing within Costa Rica.
              </li>{" "}
              <br />
              <li>
                Additionally, residents from the following countries are
                currently not permitted to participate in our games: Iran, Iraq,
                United Arab Emirates, Qatar, Brunei, Cambodia, North Korea,
                Lebanon, Poland.
              </li>{" "}
              <br />
              <li>
                The Company reserves the right to make revisions to Section 12
                of its Terms and Conditions without prior notice. Users are
                encouraged to regularly review this section to stay informed
                about any updates or changes that may affect their use of our
                services.
              </li>{" "}
              <br />
            </ol>
            <h5 className="text-primary">Quinto Trademarks and Copyrights</h5>{" "}
            <br />
            <ol type="A">
              <li>
                Copyrights{" "}
                <ol type="i">
                  <br />
                  <li>
                    Copyright is defined as the rights bundle that protects
                    original works of authorship fixed in any tangible medium of
                    expression, including but not limited to computer programs
                    and coding.
                  </li>{" "}
                  <br />
                  <li>
                    The Company's software, website design, imaging, and coding
                    are considered its Copyrights.
                  </li>{" "}
                  <br />
                </ol>
              </li>
              <li>
                Trademarks{" "}
                <ol type="i">
                  {" "}
                  <br />
                  <li>
                    Quinto, Quinto Games, Quinto Lottery, Windfall Lottery,
                    Trimorra, 3-Up Bingo, and Quintoshi are all trademarks of
                    the Company. Unauthorized use or duplication of this
                    material without express and written permission from the
                    Company is strictly prohibited.
                  </li>{" "}
                  <br />
                </ol>
              </li>
            </ol>
            <h5 className="text-primary">Limitation of Liability</h5> <br />
            <ol type="A">
              <li>
                The Member acknowledges that the Game is for entertainment
                purposes only. The Member participates in the game and accesses
                the Website at their own risk. The Software and Game are
                provided without any express or implied warranties.
              </li>{" "}
              <br />
              <li>
                Without prejudice to the generality of the preceding provision,
                the Company, its directors, employees, partners, and service
                providers do not warrant that the Software and Game are fit for
                purpose, error-free, or accessible without interruptions. The
                Member agrees to hold the Company harmless and fully indemnify
                it from any costs, expenses, liabilities, and damages arising
                from the Member's use of the Website, Software, participation in
                the Game, acceptance of any Prize, inexperience with internet
                and gaming common practices, or inadequate or outdated operating
                systems, firewalls, and/or antivirus protection.
              </li>{" "}
              <br />
              <li>
                In the event of a Game or interoperability malfunction, all
                associated wagers are void. Funds obtained from a malfunctioning
                Game are considered void, including subsequent game rounds
                played with such funds.
              </li>{" "}
              <br />
              <li>
                The maximum liability of the Company, irrespective of the cause
                (whether in contract, tort, breach of warranty, or otherwise),
                will not exceed the aggregate amount the Member wagered within a
                game.
              </li>{" "}
              <br />
            </ol>
            <h5 className="mb-2">Breaches, Penalties, and Termination</h5>
            <p className="fs-1rem">
              If the Member breaches any provision of these Terms and
              Conditions, or if the Company has reasonable grounds to suspect a
              breach, the Company reserves the right to not open, suspend or
              close the Member's account, or withhold payment of the Member's
              winnings and apply such funds toward damages incurred due to the
              Member's actions.
            </p>
            <br />
            <h5 className="mb-2">Severability</h5>
            <p className="fs-1rem">
              If any provision of these Terms and Conditions is found to be
              illegal or unenforceable, that provision will be severed from
              these Terms and Conditions, and all other provisions will remain
              in effect.
            </p>
            <br />
            <h5 className="mb-2">Assignment</h5>
            <p className="fs-1rem">
              The Company reserves the right to assign or lawfully transfer this
              agreement. The Member may not assign or otherwise transfer this
              agreement.
            </p>
            <br />
            <h5 className="mb-2">Entire Agreement</h5>
            <p className="fs-1rem">
              These Terms and Conditions constitute the entire agreement between
              the Member and the Company concerning the Software and supersede
              all prior or contemporaneous communications and proposals, whether
              electronic, oral, or written, between the Member and the Company
              with respect to the Software.
            </p>
            <br />
            <h5 className="text-primary">
              Applicable Law and Jurisdiction
            </h5>{" "}
            <br />
            <ol type="A">
              <li>
                These Terms and Conditions are governed by the laws of Costa
                Rica. By participating in the games, the Member acknowledges
                that, unless stated otherwise, the games are organized within
                the territory of Costa Rica. Any contractual relationships
                between the Member and the Company shall be considered to have
                been initiated and executed in Costa Rica, at the registered
                address of the Company.
              </li>{" "}
              <br />
              <li>
                All disputes, controversies, or claims arising from or related
                to these Terms and Conditions, or their breach, termination, or
                invalidity, shall be exclusively subjected to the jurisdiction
                of the courts of Costa Rica.
              </li>{" "}
              <br />
            </ol>
            <br />
            <Link className="back-to-doc text-decoration-underline" to="/">
              <img src={images.svg_ic_arrow_left_black} alt="TODO: Image Title"/>
              Back to home page
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
