import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import TrackPage from "../inc/TrackPage";
import ProfileHeader from "../profile/ProfileHeader";
import { useTranslation } from 'react-i18next';
import { fetchCurrencies, dbICurrency, mapCurrency } from "./AccountBase";
import { onData } from "../WebSocketProvider";
import { MessageTypes } from "../utils/Enums";
import './Accounts.css';

const Accounts: React.FC = () => {
  TrackPage();

  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);

  useEffect(() => {
    const handleData = (data: {row: dbICurrency}) => {
      const newList = fetchCurrencies();
      if (currencies.length !== newList.length)
        setCurrencies(newList);
    };

    setCurrencies(fetchCurrencies());
    onData.on(MessageTypes.BALANCE, handleData);
    return () => {
      onData.off(MessageTypes.BALANCE, handleData);
    };
  }, []);

  return (
    <>
      <ProfileHeader />

      <section>
        <div className="container p-0">
          <div className="accounting-tabs">
            <div className="tab">
              <NavLink to="AccountBalance" className={({ isActive }) => isActive ? "active" : undefined}>
                {t('_ACCOUNTING.tabs.balance')}
              </NavLink>
            </div>
            <div className="tab">
              <NavLink to="Deposit" className={({ isActive }) => isActive ? "active" : undefined}>
                {t('_ACCOUNTING.tabs.deposit')}
              </NavLink>
            </div>
            <div className="tab">
              <NavLink to="Withdraw" className={({ isActive }) => isActive ? "active" : undefined}>
                {t('_ACCOUNTING.tabs.withdraw')}
              </NavLink>
            </div>
            <div className="tab">
              <NavLink to="Transactions" className={({ isActive }) => isActive ? "active" : undefined}>
                {t('_ACCOUNTING.tabs.transactions')}
              </NavLink>
            </div>
          </div>

          <div className="outlet">
            <Outlet context={{ currencies }} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Accounts;
