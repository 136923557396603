import { MathJaxContext } from "better-react-mathjax";
import MathJax from "better-react-mathjax/MathJax";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ensureArray } from "../../../utils/Utils";
import { IPlaceholder, renderPlaceholders } from "../../GameBase";
import "./Windfall.css";

const ProvablyFairTech = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  // Section 1
  const section1Title = t('_lottery.windfall.provablyFairTech.section-1.title');
  const section1Paragraph1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.paragraph-1', { returnObjects: true })).join('');  
  const section1Paragraph2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.paragraph-2', { returnObjects: true })).join('');

  // Section 1.1
  const section1_1Title = t('_lottery.windfall.provablyFairTech.section-1.section-1.title');
  const section1_1Paragraph1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.paragraph-1', { returnObjects: true })).join('');
  const section1_1Paragraph2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.paragraph-2', { returnObjects: true })).join('');
  
  const section1_1Link1Text = t('_lottery.windfall.provablyFairTech.section-1.section-1.links.link1Text');
  const section1_1Link1Url = t('_lottery.windfall.provablyFairTech.section-1.section-1.links.link1Url');
  const section1_1Footnote = t('_lottery.windfall.provablyFairTech.section-1.section-1.footnote');
  const section1_1Placeholders: { [key: string]: IPlaceholder } = {
    footnote: {
      type: 'footnote',
      href: "#bib-FIPS202",
      content: section1_1Footnote,
    },
    link: {
      type: 'link',
      href: section1_1Link1Url,
      content: section1_1Link1Text,
    },
  };

  // Section 1.1.1
  const section1_1_1Title = t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.title');
  const section1_1_1Paragraph1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.paragraph-1', { returnObjects: true })).join('');
  const section1_1_1Paragraph2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.paragraph-2', { returnObjects: true })).join('');
  const section1_1_1Footnote = t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.footnote');
  const section1_1_1Placeholders: { [key: string]: IPlaceholder } = {
    footnote: {
      type: 'footnote',
      href: "#bib-IEEE754-2019",
      content: section1_1_1Footnote,
    }
  }

  // Section 1.1.1 - Algorithm 1
  const algorithm1Title = t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.algorithm.title');
  const algorithm1Item1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.algorithm.item.1', { returnObjects: true })).join('');
  const algorithm1Item2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.algorithm.item.2', { returnObjects: true })).join('');
  const algorithm1Item3 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.algorithm.item.3', { returnObjects: true })).join('');
  const algorithm1Item4 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.algorithm.item.4', { returnObjects: true })).join('');
  const algorithm1Item5 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-1.section-1.algorithm.item.5', { returnObjects: true })).join('');


// Section 1.2
const section1_2Title = t('_lottery.windfall.provablyFairTech.section-1.section-2.title');
const section1_2Paragraph1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.paragraph-1', { returnObjects: true })).join('');
const section1_2Paragraph2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.paragraph-2', { returnObjects: true })).join('');

  // Section 1.2.1
  const section1_2_1Title = t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.title');
  const section1_2_1Paragraph1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.paragraph-1', { returnObjects: true })).join('');
  const section1_2_1Footnote = t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.footnote');  
  const section1_2_1Placeholders: { [key: string]: IPlaceholder } = {
    footnote: {
      type: 'footnote',
      href: "#bib-TAOCP2",
      content: section1_2_1Footnote,
    }
  }

  // Section 1.2.1 - Algorithm 2
  const algorithm2Title = t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.algorithm.title');
  const algorithm2Item1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.algorithm.item.1', { returnObjects: true })).join('');
  const algorithm2Item2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.algorithm.item.2', { returnObjects: true })).join('');
  const algorithm2Item3 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.algorithm.item.3', { returnObjects: true })).join('');
  const algorithm2Item4 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.algorithm.item.4', { returnObjects: true })).join('');
  const algorithm2Item5 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.algorithm.item.5', { returnObjects: true })).join('');
  const algorithm2Item6 = ensureArray(t('_lottery.windfall.provablyFairTech.section-1.section-2.section-1.algorithm.item.6', { returnObjects: true })).join('');

// Section 2
const section2Title = t('_lottery.windfall.provablyFairTech.section-2.title');
const section2Paragraph1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-2.paragraph-1', { returnObjects: true })).join('');
const section2Paragraph2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-2.paragraph-2', { returnObjects: true })).join('');
const section2Paragraph3 = ensureArray(t('_lottery.windfall.provablyFairTech.section-2.paragraph-3', { returnObjects: true })).join('');  
const section2Footnote = t('_lottery.windfall.provablyFairTech.section-2.footnote');  
const section2Placeholders: { [key: string]: IPlaceholder } = {
  footnote: {
    type: 'footnote',
    href: "#bib-TAOCP2",
    content: section2Footnote,
  }
}    

// Section 2 - Algorithm 3
const algorithm3Title = t('_lottery.windfall.provablyFairTech.section-2.algorithm.title');
const algorithm3Paragraph1 = t('_lottery.windfall.provablyFairTech.section-2.algorithm.paragraph');
const algorithm3Item1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-2.algorithm.item.1', { returnObjects: true })).join('');
const algorithm3Item2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-2.algorithm.item.2', { returnObjects: true })).join('');
const algorithm3Item3 = ensureArray(t('_lottery.windfall.provablyFairTech.section-2.algorithm.item.3', { returnObjects: true })).join('');
const algorithm3Item4 = ensureArray(t('_lottery.windfall.provablyFairTech.section-2.algorithm.item.4', { returnObjects: true })).join('');


// Section 3
const section3Title = t('_lottery.windfall.provablyFairTech.section-3.title');
const section3Paragraph1 = ensureArray(t('_lottery.windfall.provablyFairTech.section-3.paragraph-1', { returnObjects: true })).join('');
const section3Paragraph2 = ensureArray(t('_lottery.windfall.provablyFairTech.section-3.paragraph-2', { returnObjects: true })).join('');
const section3Paragraph3 = ensureArray(t('_lottery.windfall.provablyFairTech.section-3.paragraph-3', { returnObjects: true })).join('');  
const section3Paragraph4 = ensureArray(t('_lottery.windfall.provablyFairTech.section-3.paragraph-4', { returnObjects: true })).join('');

  // Footnotes
  const footnoteTitle = t('_lottery.windfall.provablyFairTech.footnote.title');
  const footnoteItem1 = ensureArray(t('_lottery.windfall.provablyFairTech.footnote.1', { returnObjects: true })).join('');
  const footnoteItem2 = ensureArray(t('_lottery.windfall.provablyFairTech.footnote.2', { returnObjects: true })).join('');
  const footnoteItem3 = ensureArray(t('_lottery.windfall.provablyFairTech.footnote.3', { returnObjects: true })).join('');

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, [i18n.language]);

  return (
    <MathJaxContext>
        <section className="pages page-bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="heading">{t('_lottery.windfall.provablyFairTech.title')}</h1><br/>

                <p><strong>{t('_lottery.windfall.provablyFairTech.technicalVersion')}</strong></p><br/>

                <h3 className="mb-3">{section1Title}</h3>
                <p>{section1Paragraph1}</p><br/>
                <p>{section1Paragraph2}</p><br/>

                <div style={{ paddingLeft: '20px' }}>
                  <h5 className="mb-3">{section1_1Title}</h5>
                  <p>{renderPlaceholders(section1_1Paragraph1, section1_1Placeholders, navigate)}</p><br/>
                  <p>{section1_1Paragraph2}</p><br/>

                  <div style={{ paddingLeft: '20px' }}>
                    <h5 className="mb-3">{section1_1_1Title}</h5>
                    <p>{section1_1_1Paragraph1}</p><br/>
                    <p>{renderPlaceholders(section1_1_1Paragraph2, section1_1_1Placeholders, navigate)}</p><br/>

                    <p><strong>{algorithm1Title}</strong></p><br/>
                    <div style={{ paddingLeft: '20px' }}>
                      <ol>
                        <li><p><MathJax>{renderPlaceholders(algorithm1Item1, {}, navigate)}</MathJax></p></li>
                        <li><p>{renderPlaceholders(algorithm1Item2, {}, navigate)}</p></li>
                        <li><p>{renderPlaceholders(algorithm1Item3, {}, navigate)}</p></li>
                        <li><p>{renderPlaceholders(algorithm1Item4, {}, navigate)}</p></li>
                        <li><p>{algorithm1Item5}</p></li>
                      </ol>
                    </div>
                  </div>

                  <h5 className="mb-3">{section1_2Title}</h5>
                  <p>{section1_2Paragraph1}</p><br/>
                  <p>{section1_2Paragraph2}</p><br/>

                  <div style={{ paddingLeft: '20px' }}>
                    <h5 className="mb-3">{section1_2_1Title}</h5>
                    <p>{renderPlaceholders(section1_2_1Paragraph1, section1_2_1Placeholders, navigate)}</p><br/>

                    <p><strong>{algorithm2Title}</strong></p><br/>
                    <div style={{ paddingLeft: '20px' }}>
                      <ol>
                        <li><p>{renderPlaceholders(algorithm2Item1, {}, navigate)}</p></li>
                        <li><p>{renderPlaceholders(algorithm2Item2, {}, navigate)}</p></li>
                        <li><p>{renderPlaceholders(algorithm2Item3, {}, navigate)}</p></li>
                        <li><p>{renderPlaceholders(algorithm2Item4, {}, navigate)}</p></li>
                        <li><p>{renderPlaceholders(algorithm2Item5, {}, navigate)}</p></li>
                        <li><p>{renderPlaceholders(algorithm2Item6, {}, navigate)}</p></li>
                      </ol>
                    </div>
                  </div>

                </div>

                <h3 className="mb-3">{section2Title}</h3>
                <p>{renderPlaceholders(section2Paragraph1, section2Placeholders, navigate)}</p><br/>
                <p><strong>{algorithm3Title}</strong></p><br/>
                <div style={{ paddingLeft: '20px' }}>
                  <p>{renderPlaceholders(algorithm3Paragraph1, {}, navigate)}</p><br/>
                  <ol>
                    <li><p>{renderPlaceholders(algorithm3Item1, {}, navigate)}</p></li>
                    <li><p>{renderPlaceholders(algorithm3Item2, {}, navigate)}</p></li>
                    <li><p>{renderPlaceholders(algorithm3Item3, {}, navigate)}</p></li>
                    <li><p>{renderPlaceholders(algorithm3Item4, {}, navigate)}</p></li>
                  </ol>
                </div>

                <p>{renderPlaceholders(section2Paragraph2, {}, navigate)}</p><br/>
                <p>{renderPlaceholders(section2Paragraph3, {}, navigate)}</p><br/>

                <h3 className="mb-3">{section3Title}</h3>
                <p>{renderPlaceholders(section3Paragraph1, {}, navigate)}</p><br/>
                <p>{renderPlaceholders(section3Paragraph2, {}, navigate)}</p><br/>
                <p>{renderPlaceholders(section3Paragraph3, {}, navigate)}</p><br/>
                <p>{renderPlaceholders(section3Paragraph4, {}, navigate)}</p><br/>

                <p><strong>{footnoteTitle}</strong></p><br/>
                <div className="footnote">
                  <ol>
                    <li><p>{footnoteItem1}</p></li>
                    <li><p>{footnoteItem2}</p></li>
                    <li><p>{footnoteItem3}</p></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
    </MathJaxContext>
  );
};

export default ProvablyFairTech;
