/**
 * @file Round.ts
 * @description
 * Utilities for rounding and formatting numeric values for display.
 *
 * In the context of Quinto Games, these functions are used to ensure that displayed
 * monetary values accurately represent the amounts that users can interact with.
 * For example, when displaying currency values, fractional cents may be rounded
 * down to ensure that users do not inadvertently wager amounts higher than what
 * they have available, minimizing the risk of insufficient funds errors.
 *
 * These utilities also contribute to the overall professionalism of the platform
 * by providing uniformity in currency display across different sections of the
 * application. By adhering to consistent formatting standards, users can
 * confidently navigate the interface and make informed decisions based on the
 * displayed values.
 */

/**
 * Formats a numeric value as a string with a specified precision.
 * @param {number} value - The numeric value to format.
 * @param {number} [precision=2] - The number of decimal places to include in the formatted value.
 * @returns {string} The formatted value as a string.
 * @throws Will throw an error if precision is not an integer between 0 and 12.
 */
export const formatValue = (value: number, precision: number = 2): string => {
  precisionCheck(precision);
  return new Intl.NumberFormat(undefined, { minimumFractionDigits: precision, maximumFractionDigits: precision }).format(value);
};

/**
 * Rounds a numeric value down to a specified precision.
 * @param {number} value - The numeric value to round down.
 * @param {number} [precision=2] - The number of decimal places to round down to.
 * @returns {number} The rounded down value.
 * @throws Will throw an error if the value is negative, null, or undefined, or if precision is not an integer between 0 and 12.
 */
export const roundDown = (value: number, precision: number = 2): number => {
  precisionCheck(precision);
  if (value === null || value === undefined || value < 0)
    throw new Error("roundDown should never have a negative value or null");

  // Flooring the value to the specified precision
  const factor = Math.pow(10, precision);
  const result = Math.trunc(value * factor) / factor;

  return result;
};

/**
 * Rounds a numeric value down to a specified precision.
 * @param {number} value - The numeric value to round down.
 * @param {number} [precision=2] - The number of decimal places to round down to.
 * @returns {number} The rounded up value.
 * @throws Will throw an error if the value is negative, null, or undefined, or if precision is not an integer between 0 and 12.
 */
export const roundUp = (value: number, precision: number = 2): number => {
  precisionCheck(precision);
  if (value === null || value === undefined || value < 0)
    throw new Error("roundDown should never have a negative value or null");

  // Flooring the value to the specified precision
  const factor = Math.pow(10, precision);
  const result = Math.ceil(value * factor) / factor;

  return result;
};

/**
 * Checks if a given precision value is a valid integer between 0 and 12.
 * @param {number} value - The precision value to check.
 * @throws Will throw an error if precision is not an integer between 0 and 12.
 * @private
 */
const precisionCheck = (value: number): void => {
  // Check if precision is an integer
  if (!Number.isInteger(value) || value < 0 || value > 12)
    throw new Error("Precision must be an integer between 0 and 12.");
};
