import { useState, useEffect } from "react";
import { images } from "../../helper";
import { Link } from "react-router-dom";

export const QLinkButton = (props) => {
  const [block, setBlock] = useState(props.block || false);
  const [progress, setProgress] = useState(props.progress || false);
  const [icon, setIcon] = useState(props.icon || images.svg_ic_mail);
  const [isIcon, setIsIcon] = useState(
    props.noIcon === undefined ? true : false,
  );
  const [className, setClassName] = useState(props.className || "");

  useEffect(() => {
    setBlock(props.block || false);
    setProgress(props.progress || false);
    setIcon(props.icon || images.svg_ic_mail);
    setIsIcon(props.noIcon === undefined ? true : false);
    setClassName(props.className || "");
  }, [props]);

  return props.text ? (
    <Link
      onClick={(e) => {
        props.onClick && props.onClick(e);
      }}
      to={props.link || ""}
      className={`btn btn-quinto ${block ? "btn-black" : ""} ${progress ? "btn-progress" : ""} ${className || ""}`}
    >
      {isIcon ? icon && <img src={icon} alt="TODO: Image Title"/> : ""}

      <span>{props.text}</span>
    </Link>
  ) : (
    ""
  );
};
