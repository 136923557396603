import { images } from "../../../../helper";
import PasswordElement from "../../../elements/PasswordElement";
import QButton from "../../../elements/QButton";
import TicketQuantity from "../../../elements/TicketQuantity";
import Pools from "./Pools";

const LotteryPoolTickets = () => {
  return (
    <div>
      <div className="create-lottery-pool mt-4">
        <div className="create-lottery-section">
          <div className="create-lottery-box d-flex justify-content-around align-items-around py-3 px-2">
            <div>
              <label>Game Number</label>
              <label>1</label>
            </div>
            <div className="border mx-2"></div>
            <div>
              <label>Game Hash</label>
              <label>
                1e41e611f4b66041f7d7e5d4556e8603ee8736bcc931784ae4c6c40e428d3bc2
              </label>
            </div>
            <div className="border mx-2"></div>
            <div>
              <label>Game Status</label>
              <label>
                {" "}
                <label className="circle-status mx-2"></label> Open
              </label>
            </div>
          </div>
          <QButton className="create-lottery-button my-3" noIcon={false}>
            CREATE LOTTERY POOL
          </QButton>

          <QButton className="nexus-box-game my-3" noIcon={false}>
            NEXUS BOX Game
          </QButton>

          <div className="row">
            <div className="col-lg-5 col-md-12 mt-2">
              <TicketQuantity
                label_1="Minimum Ticket Quantity"
                label_2="Minimum purchase requirement per player"
              />
            </div>
            <div className="col-lg-5 col-md-12 mt-2">
              <TicketQuantity
                label_1="Maximum Ticket Quantity"
                label_2="Maximum purchases allowed (blank = unlimited)"
              />
            </div>
            <div className="col-lg-2 col-md-4 mt-2 checkbox-section d-flex align-items-center">
              <input type="checkbox" />{" "}
              <label className="px-2">Private pool</label>
            </div>
          </div>

          <PasswordElement />
          <div className="subtext">
            Your Pool Details and Password will be provided in mail that Quinto
            will send you on mail associated to your account.
          </div>
          <div className="cost-lottery-box py-4 px-3">
            <div>
              <p>Minimum tickets to participate per player</p>
            </div>
            <div className="border mx-2"></div>
            <div>
              <p>Max Ticket Quantity In Pool</p>
            </div>
            <div className="border mx-2"></div>
            <div>
              <p>Unlimited number of players in Pool</p>
            </div>
          </div>

          <QButton className="create-lottery-button my-3" noIcon={false}>
            Cost for Creating Lottery Pool &nbsp;{" "}
            <img src={images.svg_quintos} 
                alt="TODO: Image Title"
              /> 2.50{" "}
          </QButton>
          <div className="nexusbox-pool p-2">
            <h4 className="my-3 text-center">
              Please confirm you would like to Create NexusBox Pool
            </h4>
            <QButton className="mx-3 my-3" icon={images.svg_windfall_Vector}>
              YES! LET’S GO!
            </QButton>
            <p className="my-3">Cancel</p>
          </div>
        </div>
      </div>

      <Pools />
    </div>
  );
};

export default LotteryPoolTickets;
