import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { images } from "../../helper";
import LangDropDownMenu from "../elements/LangDropDownMenu";
import { ClientStatus } from "../utils/Enums";
import { NotificationBell } from "./NotificationBell";
import ProfileNavigation from "./ProfileNavigation";

const TopNav: React.FC = () => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    console.log(`TopNav Data: status: ${mii.status}, check:`, mii.status === ClientStatus.OBSERVER);
    
  }, [mii.status]);

  const handleLinkClick = () => {
    // Handle link click if needed
  };

  return (
    <div className="info_bar">
      <div className="container">
        <div className="row">
          <div className="d-flex align-items-center col-6 info_barLeft">
            <Link
              to="/support"
              className="d-flex h_support bothside_common h_text align-items-center"
            >
              <div>
                <img src={images.png_headphones} alt="" height="100%" />
              </div>
              <p className="">{t("_top_nav.support")}</p>
            </Link>
            <div className="d-flex bothside_common h_contact h_text align-items-center">
              <div className="">
                <img src={images.png_mail} alt="" height="100%" />
              </div>
              <p>{t("_top_nav.contact_us")}</p>
            </div>
          </div>
          <div className="d-flex align-items-center col-6 info_barRight justify-content-end">
            <LangDropDownMenu support />

            {(mii.status === undefined || mii.status === ClientStatus.OBSERVER) ? (
              <Link
                to="/login"
                className="text-dark d-flex h_text h_contact bothside_common align-items-center link-login-btn"
              >
                <div className="">
                  <img src={images.png_user} alt="" height="100%" />
                </div>
                <p>{t("_top_nav.login")}</p>
              </Link>
            ) : mii.status === ClientStatus.BLOCKED ? (
              <div className="">
                <img src={images.png_user} alt="" height="100%" />
                {t("_common.blocked")}
              </div>
            ) : (
              <div className="d-flex h_text h_contact bothside_common align-items-center">
                <NotificationBell />
                <ProfileNavigation onLinkClicked={handleLinkClick} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
