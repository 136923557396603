import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { db, onData } from "../../WebSocketProvider";
import Paginations from "../../elements/Paginations";
import AvatarImage from "../../elements/AvatarImage";
import { MessageTypes } from "../../utils/Enums";
import { toWords, capitalizeFirstLetter, asInt } from "../../utils/Utils";
import { IParticipant } from './TrimorraBase';

interface IWaitingProps {
  room_id: string;
}

const WaitingList: React.FC<IWaitingProps> = ({ room_id }) => {
  const { t } = useTranslation();
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  
  const fetchWaitingList = (count: number | undefined) => {
    if (!db) throw new Error("Database instance not available");      
    const startTime = performance.now();

    const query = `
    SELECT
      client_ref, avatar as ndx, user_name as name
    FROM
      trimorra_waiting
    WHERE
      room_id = $id         
    ORDER BY
      when_joined DESC;
  `;
  
    const rows: IParticipant[] = [];
    
    const stmt = db.prepare(query);
    stmt.bind({ $id: room_id });
    while (stmt.step()) {
      let data = stmt.getAsObject();
      const participant: IParticipant = {
        clientRef: data.client_ref,
        profile: {
          name: data.name,
          ndx: asInt(data.ndx), // Assuming ndx needs to be converted to Integer
        }
      };

      rows.push(participant);
    }
    stmt.free();

    setParticipants(rows);
    if (count && rows.length !== count) 
      console.error(`Waiting List Query Results… Mismatch Expected: ${count}, Got: ${rows.length}`);

    const endTime = performance.now();
    //console.log(`Waiting List Fetching Players… Executed in ${endTime - startTime} milliseconds`);
  };

  useEffect(() => {
  }, [participants]);

  useEffect(() => {
    const handleUpdate = (data: IData) => {
      if (data.row.room_id !== room_id) return;
      fetchWaitingList(data.row.waiting);
    };

    fetchWaitingList(undefined);
    
    onData.on(MessageTypes.TRIMORRA_ROOMS, handleUpdate);
    return () => {
      onData.off(MessageTypes.TRIMORRA_ROOMS, handleUpdate);
    };
  }, [room_id]);

  const generateHeader = () => {
    const count = participants.length;
    const waitingWord = count === 1 ? t('_trimorra.waiting-player') : t('_trimorra.waiting-players');
    const countWord = capitalizeFirstLetter(toWords(count));

    return (
      <Trans
        i18nKey="_trimorra.waiting-header"
        count={count}
        values={{ count: countWord, wait: waitingWord }}
      />
    );
  };

  const renderWaitingData = (data: IParticipant[]) => (
    <>
      {data.map((participant) => (
        <tr key={participant.clientRef} className="waiting-list-item">
          <td>
            <AvatarImage
              profile={participant.profile}
              className="avatar-image avatar-tiny m-2"
            />
            <span>{participant.profile.name}</span>
          </td>            
        </tr>
      ))}
    </>        
  );

  return (
    <div className="waiting-list-container">
      <h3>{generateHeader()}</h3>
      <Paginations
        data={participants}
        header={undefined}
        className="waiting-list-table"
        tableHeaders={<tr></tr>} // Pass an empty row for headers
        jsxData={renderWaitingData}

        recordsPerPage={9}
        useCircles={true} // Show dots instead of page numbers
      />
    </div>
  );
};

export default WaitingList;
