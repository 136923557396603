import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertMessage, { AlertMessageType } from "../elements/AlertMessage";
import PasswordElement from "../elements/PasswordElement";
import QButton from "../elements/QButton";
import TwoFAElement from "../elements/TwoFAElement";
import { QCardHeaders } from "./QCardHeaders";
import { api, MessageTypes } from "../utils/Enums";
import { is2FA } from "../utils/Utils";
import { apiMessage, onData } from "../WebSocketProvider";

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();

  const [password, setPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [confPassword, setConfPassword] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string | undefined>(undefined);

  let replyId: number | null = null;

  useEffect(() => {
    const handleChange = (data: IData) => {
      if (data.id !== replyId) return;

      setPassword(null);
      setNewPassword(null);
      setConfPassword(null);
      setCode(null);

      setMessageType(AlertMessageType.SUCCESS);
      setMessage(t('_profile.password.message'));
      setTimeout(() => { 
        setMessage(undefined); 
        replyId = null;
      }, 3500);      

    };

    const handleError = (data: IError) => {
      if (replyId !== data.id) return;

      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason);

      setTimeout(() => { 
        setMessage(undefined); 
        replyId = null;
      }, 3500);            
    };

    onData.on(MessageTypes.REPLY, handleChange);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      onData.off(MessageTypes.REPLY, handleChange);
      onData.off(MessageTypes.ERROR, handleError);
    };
  }, []);

  const handleOld = (value: string) => {
    if (value) setPassword(value);
    else setMessage("Please enter current password");
  };

  const handleNew = (value: string) => {
    if (value) setNewPassword(value);
    else setMessage("Please enter new password");
  };

  const handleConfirm = (value: string) => {
    if (newPassword === value) setConfPassword(value);
    else setMessage("Password Mismatch, Please try again.");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage(undefined);

    if (!password) {
      setMessage("Please enter current password");
      return;
    }

    if (!newPassword) {
      setMessage("Please enter new password");
      return;
    }

    if (!confPassword) {
      setMessage("Please enter confirm new password");
      return;
    }

    if (newPassword !== confPassword) {
      setMessage("Password Mismatch, Please try again.");
      return;
    }

    if (is2FA() && !code) {
      setMessage("Please enter 2FA code");
      return;
    }

    const params: { [key: string]: any } = {
      old_password: password,
      password: newPassword,
    };
    if (code) params.code = code;

    replyId = apiMessage(api.CHANGE_PASSWORD, params);
  };

  return (
    <div className="card user-account-tabs-card password-card">
      <div className="card-body">
        <QCardHeaders
          title="CHANGE PASSWORD"
          subTitle="Password Update"
          desc="It is good to change your password every few months for added protection."
        />

        <div className="card-content">
          <div className="col-xl-5 col-lg-6 col-md-12 flex-column justify-content-center mb-5 mb-lg-0">
            <form onSubmit={handleSubmit}>
              <PasswordElement
                onPasswordChange={handleOld}
                className="password"
                prefill={password || ''}
                placeholder={t("_profile.change_password.plc.old")}
              />

              <PasswordElement
                onPasswordChange={handleNew}
                className="password"
                prefill={newPassword || ''}
                placeholder={t("_profile.change_password.plc.new")}
              />

              <PasswordElement
                onPasswordChange={handleConfirm}
                prefill={confPassword || ''}
                placeholder={t("_profile.change_password.plc.conf")}
              />

              <TwoFAElement
                value={code ? code : ''}
                onInput={(value) => setCode(value)}
              />

              <AlertMessage
                className="mt-4"
                type={messageType}
                message={message ? message : ''}
              />

              {!!replyId === false && (
                <QButton
                  className={`default-selection-button request-button`}
                  submit
                  block
                  text={t("_profile.btn.change_password")}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
