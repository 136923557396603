import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import replayImage from "../../../assets/images/replay.svg";

import { db } from "../../WebSocketProvider";
import { formatValue, roundDown } from "../../utils/Round";
import * as utils from "../../utils/Utils";
import GamePlayer from "./GamePlayer";
import PayoutTable from "./PayoutTable";
import "./Trimorra.css"; // Importing the CSS file
import { GameStateTypes, IGame, IPlayer } from "./TrimorraBase";

interface GamePlayProps {
  game: IGame;
  onGameComplete: (completedGame: IGame) => void;
}

const GamePlay: React.FC<GamePlayProps> = ({ game, onGameComplete }) => {
  const { t } = useTranslation();

  const [players, setPlayers] = useState<IPlayer[]>([]); // State for players
  const [gameState, setGameState] = useState<GameStateTypes>(game.state as GameStateTypes);
  const [message, setMessage] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number | null>(null); // State for countdown

  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the timer ID
  const countdownRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the countdown interval ID  
  const round = useRef<Integer>(utils.asInt(1)); // Always start with round 1
  const maxRounds = useRef<Integer>(utils.asInt(0));
  const iteration = useRef<Integer>(utils.asInt(0));

  function mapPlayer(id: string): IPlayer {
    return {
      profile: utils.getProfile(id),
      roll: undefined,
      payout: undefined,
      winner: false
    };
  }

  const getMaxRounds = () => {
    if (!db) throw new Error("Database instance not available");

    const query = `
      SELECT
        max(round) as round
      FROM
        trimorra_grouping
      WHERE
        game_id = $id;
    `;

    const stmt = db.prepare(query);
    const result: JsonObject = stmt.getAsObject({ $id: game.id }) as JsonObject;
    console.log(`getMaxRounds Query returned ${result.round === undefined}`, result);    
    const maxRoundValue = result && result.round !== undefined ? utils.asInt(result.round) : utils.asInt(0);
    stmt.free();

    return maxRoundValue;
  };

  const initializeRound = () => {
    const getRoundIterations = () => {
      if (!db) throw new Error("Database instance not available");

      const query = `
        SELECT
          max(iteration) as iteration
        FROM
          trimorra_grouping
        WHERE
          game_id = $id AND round = $round;
      `;

      console.log(`getRoundIterations… Game: ${game.id}, round: ${round.current}`);

      const stmt = db.prepare(query);
      const row: JsonObject = stmt.getAsObject({ $id: game.id, $round: round.current }) as JsonObject;
      console.log(`getRoundIterations… Query returned ${row.iteration === undefined}`, row);

      iteration.current = row && row.iteration !== undefined ? utils.asInt(row.iteration) : utils.asInt(0);
      stmt.free();

      console.log("Set iteration:", iteration.current);
    };

    
    const roundPlayers = () => {
      if (!db) throw new Error("Database instance not available");

      const query = `
        SELECT
          position, client_ref
        FROM
          trimorra_grouping
        WHERE
          game_id = $id and round = $round
        GROUP BY
          position, client_ref
        ORDER BY
          position;
      `;

      const rows: IPlayer[] = [];
      const stmt = db.prepare(query);
      stmt.bind({ $id: game.id, $round: round.current });
      while (stmt.step()) {
        rows.push(mapPlayer(stmt.getAsObject().client_ref));
      }
      stmt.free();
      console.log("Fetched round players:", rows);
      return rows;
    };

    const playersData = roundPlayers();
    setPlayers(playersData);
    console.log("Set players:", playersData);

    getRoundIterations();
  };

  const processPayouts = () => {
    const getPlayerPayouts = () => {
      if (!db) throw new Error("Database instance not available");
  
      const query = `
        SELECT
          position, payout, winner
        FROM
          trimorra_grouping
        WHERE
          game_id = $id and round = $round and iteration = 0
        ORDER BY
          position;
      `;
  
      const stmt = db.prepare(query);
      stmt.bind({ $id: game.id, $round: round.current });
      while (stmt.step()) {
        const data = stmt.getAsObject();
        const position = data.position;
        let payout: string | undefined = undefined;
        if (data.payout) {
          const precision = roundDown(data.payout) === data.payout ? 2 : 3;
          payout = formatValue(data.payout, precision);
        }
  
        setPlayers(prevPlayers => {
          const updatedPlayers = [...prevPlayers];
          updatedPlayers[position] = { 
            ...prevPlayers[position], 
            payout: payout,
            winner: !!data.winner // Inline conversion to boolean
          };
          console.log(`Updated player at position ${position} with payout: ${payout} and winner: ${!!data.winner}`);
          return updatedPlayers;
        });
      }
      stmt.free();
    };
    getPlayerPayouts();
    checkGameState();
  };
  

  const processIteration = () => {
    console.log(`Doing Rolls Iteration ${iteration.current}`);

    let position = 0;
    const interval = setInterval(() => {
      console.log(`Position tick: position = ${position}, players.length = ${players.length}`);
  
      const getPlayerRoll = () => {
        if (!db) throw new Error("Database instance not available");
  
        const query = `
          SELECT
            roll
          FROM
            trimorra_grouping
          WHERE
            game_id = $id and round = $round and iteration = $iteration and position = $position;
        `;
  
        const stmt = db.prepare(query);
        const result: JsonObject = stmt.getAsObject({ $id: game.id, $round: round.current, $iteration: iteration.current, $position: position }) as JsonObject;
        console.log(`Fetching roll data: ${position}:`, result);
        if (result.roll === undefined)
          throw new Error("Roll data was not retreived");
        
        const roll = utils.asInt(result.roll);
        stmt.free();
  
        return roll;
      };
      
      const roll = getPlayerRoll(); // Fetch and update the roll for the current position
      setPlayers(prevPlayers => {
        const updatedPlayers = [...prevPlayers];
        updatedPlayers[position] = { ...prevPlayers[position], roll: roll };
        console.log(`Updated player at position ${position} with roll: ${roll}`);
        return updatedPlayers;
      });
      position++;
      console.log(`Checking position after doing roll: ${position} === ${players.length} : ${position === players.length}`);
      if (3 === position) {
        console.log("Stopping interval timer");
        clearInterval(interval);

        if (iteration.current === 0) {
          console.log("Completed Round");
          processPayouts();
        }
        else {
          iteration.current--;
          setMessage(t('_trimorra.games.reroll'));

          setTimeout(() => {
            setPlayers(prevPlayers => prevPlayers.map(player => ({...player, roll: undefined})));
          }, 1000);
          
          setTimeout(() => {
            setMessage(null);
            processIteration(); // Continue to the next iteration
          }, 1250);
        }
      }
    }, 750);
  };
  
  const checkGameState = () => {
    console.log("Checking game progression...");
    setMessage(null);

    if (round.current < maxRounds.current) {
      setGameState(GameStateTypes.ROUND);

      timeoutRef.current = setTimeout(() => {
        handleNextRound();
      }, 3000); // 3 seconds delay for next round
      setCountdown(3);
      countdownRef.current = setInterval(() => {
        setCountdown(prev => (prev !== null ? prev - 1 : null));
      }, 1000);
    } else {
      setGameState(GameStateTypes.FINISHED);

      console.log("Game finished, setting Finish / Close message...");
      timeoutRef.current = setTimeout(() => {
        onGameComplete(game);
      }, 30000);
      setCountdown(30);
      countdownRef.current = setInterval(() => {
        setCountdown(prev => (prev !== null ? prev - 1 : null));
      }, 1000);
    }
  };
  
  const playRound = () => {
    console.log(`Initializing round ${round.current}...`);
    initializeRound();
    console.log(`Round ${round.current} will have ${players.length} players`);
    processIteration(); 
    console.log(`Round ${round.current} will have ${iteration.current+1} rolls`);    
  }

  const doReplay = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
      countdownRef.current = null;
    }

    round.current = utils.asInt(1);
    setGameState(GameStateTypes.PLAYING);    
    playRound();
  }

  const handleNextRound = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
      countdownRef.current = null;
    }

    setGameState(GameStateTypes.PLAYING);
    round.current = utils.asInt(++round.current);
    playRound();
  }

  useEffect(() => {
    if (maxRounds.current === 0) {
      maxRounds.current = getMaxRounds();
      console.log(`Max rounds set to: ${maxRounds.current}`);
    }

    if (gameState === GameStateTypes.PLAYING) {
      playRound();
    }    
  }, [game]);
  
  return (
    <div className="game-play">
      <div className="layout">
        <div className="payout-table">
          <PayoutTable room={game.room} round={round.current} />
        </div>
        <div className="players-container">
          {players.map(player => (
            <GamePlayer
              key={player.profile.name}
              player={player}
            />
          ))}
        </div>
      </div>
      <div className="bottom-container">
        {message && <p className="play-message">{message}</p>}
  
        {gameState === GameStateTypes.FINISHED && (
          <div className="button-group">
            <div className="replay-button-container">
              <button className="replay-button" onClick={doReplay}>
                <img src={replayImage} alt="Replay" className="replay-image" />
                <div className="replay-text">{t('_trimorra.games.replay')}</div>
              </button>
            </div>
  
            <div className="button-container">
              <button
                className="default-selection-button button"
                onClick={() => onGameComplete(game)}>
                {t('_trimorra.games.finish')} {countdown !== null && `(${utils.formatCountdown(countdown)})`}
              </button>
            </div>
          </div>
        )}
        
        {gameState === GameStateTypes.ROUND && (
          <div className="button-group">          
            <div className="button-container">
              <button
                className="default-selection-button button"
                onClick={handleNextRound}>
                {t('_trimorra.games.next')} {countdown !== null && `(${utils.formatCountdown(countdown)})`}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  
};

export default GamePlay;
