import React from "react";
import { useTranslation } from "react-i18next";
import QSymbol from "../../utils/QSymbol";
import WindfallPayoutsTable from "../../games/lottery/windfall/WindfallPayoutsTable";

const WhyWindfallLottery: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="pages page-bg-white">
      <div className="position-relative">
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <label className="small_heading">Windfall Lottery</label>
              <h1 className="heading">Game Play and Rules</h1>
              <br />
              <p>
                The Windfall Lottery is a little different from a traditional
                lottery. In traditional lotteries, you select from one or two
                groups of numbers—the ordering within each group does not
                matter. In contrast, the Windfall Lottery uses a deck of cards,
                and the selection order of your cards does matter.
              </p>
              <br />
              <p>
                Windfall Lottery play consists of five (5) cards from a 52-card
                deck. Even though two picks may have the same cards, they are
                different because of the order of the cards.
              </p>
              <br />
              <p>
                Windfall Lottery has 2,500,000 tickets, each with 125 unique
                picks. There are 312,500,000 picks within the lottery. The
                Windfall Lottery is unique because it will generate every
                possible play permutation throughout all the tickets sold;
                meaning there will be at least one jackpot winner.
              </p>
              <br />
              {/* <p>{t("_page_why_windfall.line_1")}</p>
              <br />
              <p>{t("_page_why_windfall.line_1")}</p> */}
              <p>
                The Windfall Lottery does not depend on the blockchain or the
                use governmental lottery drawings to determine the winner.
                Windfall Lottery is a 100% Provably Fair game as neither
                participants nor staff members of the Quinto, S.A. ("The
                Company") can predetermine a drawing.
              </p>
              <br />
              <p>
                The Windfall Lottery drawing will take place within minutes of
                the last ticket being sold. Please be aware that, given our
                status as a new site with a limited customer base, the
                commencement of the first Windfall Lottery game may experience a
                notable delay.
              </p>
              <br />
              <p>
                The Company wagering is in units called Quintoshi symbolized by
                (<QSymbol />) and is equivalent to one US dollar ($1.00).
                Windfall Lottery ticket price is <QSymbol />25.00 (
                <QSymbol />0.20 per pick) and it doesn’t matter if your wager
                was in dollars, euros, or any other currency as The Company uses
                the prevailing exchange rate at the time of purchase.
              </p>
              <br />
              <p>
                Windfall Lottery offers some of the best odds in the industry
                with a 1 in 0.125 chance of winning any prize on a single ticket
                and a 1 in 2,495,001.60 chance of winning the Jackpot Prize of
                an estimated <QSymbol />15,000,000.00.
              </p>
              <br />
              <p>
                There are 17 different payout levels based on the number of
                matching cards and the correct position for the lottery draw
                (see Windfall Lottery payouts below). To have a guaranteed win,
                you must match a minimum of two cards on a play to the lottery
                drawing result. To win a jackpot, you must match all cards on a
                single play in the exact order.
              </p>
              <br />

              <p>
                Windfall Lottery has over 20 million winning play combinations
                with a total aggregate pool of <QSymbol />52,000,000. There is a
                high statistical probability that every ticket has at least one
                winning pick. The minimum payout for a winning pick is
                <QSymbol />0.25, with the top non-jackpot winner paying
                <QSymbol />1,000,000. In the rare case that a ticket has no
                winning picks, it still returns <QSymbol />25.00, which is the
                purchase price of the ticket.
              </p>
              <br />
              <p>
                Windfall Lottery is an aggregate pool meaning the jackpot winner
                will receive any remaining funds after paying all the lower
                prizes.
              </p>
              <br />

              <h4>Basic Rules</h4>
              <ol className="lst">
                <li>
                  You are at least 18 years of age or older, depending on the
                  legal age of majority in your jurisdiction, and are legally
                  permitted to participate in any lottery.
                </li>
                <li>
                  All lottery purchases are exclusively funded by members'
                  deposited funds.
                </li>
                <li>
                  You acknowledge that once a purchase is confirmed, there are
                  absolutely No Refunds. The only exception being the
                  cancellation of the lottery game.
                </li>
                <li>
                  You are solely responsible for covering any and all taxes,
                  fees, or other associated charges related to any winnings
                  obtained from the lottery.
                </li>
                <li>
                  Each Windfall Lottery ticket consists of 125 unique, randomly
                  selected picks, and members are assigned the next available
                  ticket in sequence.
                </li>
                <li>
                  Windfall Lottery has 2,500,000 tickets available for purchase.
                  The drawing for the Windfall Lottery will occur once the last
                  lottery ticket is purchased.
                </li>
                <li>
                  Windfall Lottery Pool:
                  <ol type="a">
                    <li>
                      Windfall Lottery Pool names cannot be vulgar or derogatory
                      words.
                    </li>
                    <li>
                      The Windfall Lottery Pool allows the creation of private
                      lottery pools, by adding a password.
                    </li>
                    <li>
                      The Windfall Lottery Pool has a fee of <QSymbol />2.50,
                      paid by the creator.
                    </li>
                    <li>
                      The Windfall Lottery Pool creator will recoup the fee from
                      any pool winnings.
                    </li>
                    <li>
                      The Company manages all payouts within each specific
                      Windfall Lottery Pool to ensure fair distribution. Some
                      pools may have varying ticket quantities making payouts
                      more complex, so all scenarios are handled.
                    </li>
                  </ol>
                </li>
              </ol>
              <br />
              <h3>Windfall Lottery Payouts</h3>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <WindfallPayoutsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWindfallLottery;
