import OTPInput from "react-otp-input";
import { images } from "../../helper";
import * as utils from '../utils/Utils';
import './TwoFAElement.css'; // Import the new CSS file

interface TwoFAElementProps {
  value: string;
  onInput: (value: string) => void;
  className?: string;
  label?: string; // Optional label prop
  override?: boolean; // Override teh shouldDisplay2FA
}

const TwoFAElement: React.FC<TwoFAElementProps> = ({ value, onInput, className, label, override }) => {
  
  const shouldDisplay2FA = override || !utils.isAuthenticated() || (utils.isAuthenticated() && utils.is2FA());

  return shouldDisplay2FA ? (
    <div className={`tfa-auth ${className || ""}`}>
      <div className="tfa-content">
        <img src={images.png_2FA1} alt="2FA icon" className="tfa-icon" />
        <OTPInput
          value={value || ""}
          onChange={onInput}
          numInputs={6}
          shouldAutoFocus={override}
          inputStyle="otp-input"
          renderSeparator={<span>&nbsp;&nbsp;</span>}
          renderInput={(props, index) => <input {...props} key={index} />}
        />
      </div>
      {label && <label className="tfa-text">{label}</label>}
    </div>
  ) : null;
};

export default TwoFAElement;
