import { useTranslation } from 'react-i18next';
import { db } from '../../WebSocketProvider';
import * as i from "./TrimorraDBI";
import { asInt } from '../../utils/Utils';

export enum ViewTypes {
  HISTORY = 'HISTORY',
  PURCHASE = 'PURCHASE'
}

export enum GameStateTypes {
  OFFICIAL= "OFFICIAL",

  START   = "START",
  PLAYING = "PLAYING",
  FINISHED= "FINISHED",
  ROUND   = "ROUND"
}

// Define the ITier interface
export interface ITier {
  ord: number;
  tier: string;
  players: number;
}

// Define the IRoom interface
export interface IRoom {
  id: string;
  tier: ITier;
  buyin: number;
  waiting: number;
  aggregate: number;

  hash: string;
  participant: boolean;  
}

export interface IParticipant {
  clientRef: string;
  profile: IUserProfile;
}

export interface IHistory {
  game_id: string;
  tier: ITier;
  profile: IUserProfile;
  
  buyin: number;
  payout: number;
  when: Date;
}

export interface IGame {
  id:   string;
  room: IRoom;
  when: Date;
  
  state: string;
  hash: string;
  seed: string;
};

export interface IPlayer {
  profile: IUserProfile;
  roll: Integer | undefined;
  payout: string | undefined;
  winner: boolean;
}

const createSvgPath = (path: string) => `
  <svg viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 100%; height: 100%;">
    ${path}
  </svg>
`;

const faucetPath = `
  <ellipse cx="20.5" cy="3.80435" rx="3.80435" ry="3.80435" fill="{color}"/>
  <circle cx="25.8261" cy="12.9348" r="3.80435" fill="{color}"/>
  <ellipse cx="15.1739" cy="12.9348" rx="3.80435" ry="3.80435" fill="{color}"/>
  <ellipse cx="20.5" cy="22.0652" rx="3.80435" ry="3.80435" fill="{color}"/>
  <ellipse cx="31.1522" cy="22.0652" rx="3.80435" ry="3.80435" fill="{color}"/>
  <ellipse cx="9.84784" cy="22.0652" rx="3.80435" ry="3.80435" fill="{color}"/>
  <circle cx="15.1739" cy="31.1956" r="3.80435" fill="{color}"/>
  <circle cx="25.826" cy="31.1956" r="3.80435" fill="{color}"/>
  <ellipse cx="36.4783" cy="31.1956" rx="3.80435" ry="3.80435" fill="{color}"/>
  <circle cx="4.52176" cy="31.1956" r="3.80435" fill="{color}"/>
`;

// Array of tier images with
export const svgPaths: { [key: number]: string } = {
  0: createSvgPath(faucetPath),
  1: createSvgPath(`
      <ellipse cx="20.5" cy="3.80435" rx="3.80435" ry="3.80435" fill="{color}"/>
      <circle cx="25.8261" cy="12.9348" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
      <path d="M18.3735 12.9348C18.3735 14.7019 16.941 16.1344 15.1739 16.1344C13.4068 16.1344 11.9742 14.7019 11.9742 12.9348C11.9742 11.1677 13.4068 9.73514 15.1739 9.73514C16.941 9.73514 18.3735 11.1677 18.3735 12.9348Z" stroke="{color}" stroke-width="1.20942"/>
      <path d="M23.6996 22.0652C23.6996 23.8323 22.2671 25.2648 20.5 25.2648C18.7329 25.2648 17.3004 23.8323 17.3004 22.0652C17.3004 20.2981 18.7329 18.8656 20.5 18.8656C22.2671 18.8656 23.6996 20.2981 23.6996 22.0652Z" stroke="{color}" stroke-width="1.20942"/>
      <path d="M34.3518 22.0652C34.3518 23.8323 32.9193 25.2648 31.1522 25.2648C29.385 25.2648 27.9525 23.8323 27.9525 22.0652C27.9525 20.2981 29.385 18.8656 31.1522 18.8656C32.9193 18.8656 34.3518 20.2981 34.3518 22.0652Z" stroke="{color}" stroke-width="1.20942"/>
      <path d="M13.0475 22.0652C13.0475 23.8323 11.6149 25.2648 9.84784 25.2648C8.08073 25.2648 6.6482 23.8323 6.6482 22.0652C6.6482 20.2981 8.08073 18.8656 9.84784 18.8656C11.6149 18.8656 13.0475 20.2981 13.0475 22.0652Z" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="15.1739" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="25.826" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
      <path d="M39.678 31.1956C39.678 32.9628 38.2454 34.3953 36.4783 34.3953C34.7112 34.3953 33.2787 32.9628 33.2787 31.1956C33.2787 29.4285 34.7112 27.996 36.4783 27.996C38.2454 27.996 39.678 29.4285 39.678 31.1956Z" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="4.52176" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/> 
  `),

  2: createSvgPath(`
      <ellipse cx="20.5" cy="3.80435" rx="3.80435" ry="3.80435" fill="{color}"/>
      <circle cx="25.8261" cy="12.9348" r="3.80435" fill="{color}"/>
      <ellipse cx="15.1739" cy="12.9348" rx="3.80435" ry="3.80435" fill="{color}"/>
      <path d="M23.6996 22.0652C23.6996 23.8323 22.2671 25.2648 20.5 25.2648C18.7329 25.2648 17.3003 23.8323 17.3003 22.0652C17.3003 20.2981 18.7329 18.8656 20.5 18.8656C22.2671 18.8656 23.6996 20.2981 23.6996 22.0652Z" stroke="{color}" stroke-width="1.20942"/>
      <path d="M34.3517 22.0652C34.3517 23.8323 32.9192 25.2648 31.1521 25.2648C29.385 25.2648 27.9525 23.8323 27.9525 22.0652C27.9525 20.2981 29.385 18.8656 31.1521 18.8656C32.9192 18.8656 34.3517 20.2981 34.3517 22.0652Z" stroke="{color}" stroke-width="1.20942"/>
      <path d="M13.0474 22.0652C13.0474 23.8323 11.6149 25.2648 9.8478 25.2648C8.08069 25.2648 6.64817 23.8323 6.64817 22.0652C6.64817 20.2981 8.08069 18.8656 9.8478 18.8656C11.6149 18.8656 13.0474 20.2981 13.0474 22.0652Z" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="15.1739" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="25.826" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
      <path d="M39.678 31.1956C39.678 32.9628 38.2454 34.3953 36.4783 34.3953C34.7112 34.3953 33.2787 32.9628 33.2787 31.1956C33.2787 29.4285 34.7112 27.996 36.4783 27.996C38.2454 27.996 39.678 29.4285 39.678 31.1956Z" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="4.52174" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
    `),

  3: createSvgPath(`
      <ellipse cx="20.5" cy="3.80435" rx="3.80435" ry="3.80435" fill="{color}"/>
      <circle cx="25.8261" cy="12.9348" r="3.80435" fill="{color}"/>
      <ellipse cx="15.1739" cy="12.9348" rx="3.80435" ry="3.80435" fill="{color}"/>
      <ellipse cx="20.5" cy="22.0652" rx="3.80435" ry="3.80435" fill="{color}"/>
      <ellipse cx="31.1521" cy="22.0652" rx="3.80435" ry="3.80435" fill="{color}"/>
      <ellipse cx="9.84781" cy="22.0652" rx="3.80435" ry="3.80435" fill="{color}"/>
      <circle cx="15.1739" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="25.826" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
      <path d="M39.678 31.1956C39.678 32.9628 38.2454 34.3953 36.4783 34.3953C34.7112 34.3953 33.2787 32.9628 33.2787 31.1956C33.2787 29.4285 34.7112 27.996 36.4783 27.996C38.2454 27.996 39.678 29.4285 39.678 31.1956Z" stroke="{color}" stroke-width="1.20942"/>
      <circle cx="4.52174" cy="31.1956" r="3.19964" stroke="{color}" stroke-width="1.20942"/>
  `),
  4: createSvgPath(faucetPath)
};

// export const tierImages: string[] = [tier4Image, tier1Image, tier2Image, tier3Image, tier4Image];

export const getTierImage = (ord: number, color: string): string => {
  if (typeof ord !== 'number') {
    console.error(`Invalid ordinal type: ${typeof ord}`);
    throw new Error(`Invalid tier ordinal type: ${typeof ord}`);
  }
  
  if (ord < 0 || ord >= Object.keys(svgPaths).length) 
    throw new Error(`Invalid tier ordinal: ${ord}`);

  const svgPath = svgPaths[ord];
  if (!svgPath) 
    throw new Error(`SVG path not found for ordinal: ${ord}`);

  return svgPath.replace(/{color}/g, color);
};

export const getTier = (tier: string): ITier => {
  if (!db) throw new Error("Database instance not available");

  const query = `
    SELECT 
      ord, tier, players
    FROM
      trimorra_tiers
    WHERE
      tier = $tier;
  `;

  const stmt = db.prepare(query);
  const result = stmt.getAsObject({ $tier: tier }) as ITier;
  stmt.free();

  if (!result) 
    throw new Error(`Tier ${tier} not found`);

  return result;
};

// Function to get tier name
export const getTierName = (ord: number, t: Function): string => {
  return ord === 0 ? t('_trimorra.faucet') : `${t("_trimorra.tier")} ${ord}`;
};

export const getRoom = (room_id: string, tier?: ITier, strict: boolean = true): IRoom | null => {
  if (!db) throw new Error("Database instance not available");
  const startTime = performance.now();

  const query = `
  SELECT
    *
  FROM
    trimorra_rooms
  WHERE
    room_id = $id;
  `;

  const stmt = db.prepare(query);
  const data = stmt.getAsObject({$id: room_id}) as i.dbITrimorraRoom;
  stmt.free();

  if (!data && strict) 
    throw new Error(`TrimorraWagerSelection: Unable to locate room data for room_id: ${room_id}`);

  return data ? mapRoom(data, tier) : data;
};

export function mapRoom(data: i.dbITrimorraRoom, tier?: ITier): IRoom {
  const roomTier = tier || getTier(data.tier) as ITier;
  
  return {
    id: data.room_id,
    tier: roomTier,
    buyin: data.buyin,
    aggregate: data.aggregate,
    waiting: data.waiting,
    hash: data.game_hash,
    participant: data.participant
  };
}

export function mapGame(data: i.dbITrimorraGame): IGame {
  const gameRoom = getRoom(data.room_id) as IRoom;

  return {
    id: data.game_id,
    room: gameRoom,
    when: data.game_time, 
    state: data.game_state,
    hash: data.game_hash,
    seed: data.game_seed
  };
}

export function mapHistory(data: i.dbITrimorraHistory): IHistory {
  const roomTier = getTier(data.tier) as ITier;
  const winAvatar: Integer = asInt(data.avatar || DEFAULT_AVATAR);

  return {
    game_id: data.game_id,
    tier: roomTier,
    profile: {
      name: data.user_name,
      ndx:  winAvatar      
    },
    buyin: data.buyin,
    payout: data.payout,
    when: data.game_time
  };
};
