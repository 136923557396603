import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../WebSocketProvider';
import "../../assets/css/avatar.css";

interface AvatarImageProps extends React.HTMLAttributes<HTMLImageElement> {
  profile: IUserProfile;
}

const AvatarImage: React.FC<AvatarImageProps> = ({ profile, ...imgProps }) => {
  const [avatarData, setAvatarData] = useState<string | null>(null);
  const [imageFormat, setImageFormat] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAvatar = useCallback((ndx: number) => {
    if (!db) {
      console.error("Database instance not available");
      throw new Error("Database instance not available");
    }
    const startTime = performance.now();
    setLoading(true);

    const query = `
      SELECT 
        base64,
        format
      FROM
        avatar_images
      WHERE
        id = $id;
    `;

    let stmt;
    try {
      stmt = db.prepare(query);
      const row = stmt.getAsObject({ $id: ndx }) as { base64: string, format: string };
      
      if (row && row.base64 && row.format) {
        setAvatarData(row.base64);
        setImageFormat(row.format);
        console.log(`Fetched avatar for id ${ndx}: format = ${row.format}, base64 = ${row.base64.substring(0, 30)}...`);
      } else {
        console.error(`Avatar with id ${ndx} not found or format is missing.`);
        setError(`Avatar with id ${ndx} not found or format is missing.`);
      }
    } catch (err) {
      console.error(`Error fetching avatar with id ${ndx}:`, err);
      setError(`Error fetching avatar with id ${ndx}`);
    } finally {
      const endTime = performance.now();
      console.log(`Fetch Avatar Image executed in ${endTime - startTime} milliseconds`);      
      if (stmt) stmt.free();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAvatar(profile.ndx);
  }, [profile.ndx, fetchAvatar]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!avatarData || !imageFormat) {
    return <></>;
  }

  const mimeType = imageFormat === 'svg' ? 'image/svg+xml' : `image/${imageFormat}`;

  // Debug messages to validate format and <img> tag source
  console.log(`Image Format: ${imageFormat}`);
  console.log(`MIME Type: ${mimeType}`);
  console.log(`Image Source: data:${mimeType};base64,${avatarData.substring(0, 30)}...`);

  return (
    <img
      {...imgProps}
      src={`data:${mimeType};base64,${avatarData}`}
      alt={profile.name}
    />
  );
};

export default AvatarImage;
