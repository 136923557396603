import React, { useState } from "react";
import JackpotAnimator from "../../../elements/JackpotAnimator";
import LotteryPoolTickets from "./LotteryPoolTickets";
import PersonalTickets from "./PersonalTickets";
import { images } from "../../../../helper";

const WindfallTickets = () => {
  const [activeColor, setActiveColor] = useState(1);

  return (
    <section className="windfall">
      <div className="lottery_windfall">
        <div className="lottert_gradient"></div>
        <div className=" container py-5">
          <div className="d-flex justify-content-center flex-column align-items-center windfall_image">
            <img src={images.svg_windfall_lp} alt="TODO: Image Title"/>
            <h4 className="lottery_Remaining">REMAINING TICKETS</h4>
          </div>
          <div className="d-flex justify-content-center">
            <JackpotAnimator value={1234567} />
          </div>
        </div>
      </div>
      <div className="lottery-pool-section">
        <div className="container">
          <div className=" lottery-offers">
            <div className="d-flex align-items-start flex-column lottery-offers-div pt-2">
              <h5 className="mb-5">
                Windfall lottery offers two purchase types
              </h5>
              <p>
                The Windfall Lottery is a little different from a traditional
                lottery. In traditional lotteries, you select from one or two
                groups of numbers—the ordering within each group does not
                matter. In contrast, the Windfall Lottery uses a deck of cards,
                and the selection order of your cards does matter.
              </p>
            </div>

            <div
              className="nav nav-tabs lottery-pool-ticket mt-4"
              role="tablist"
            >
              <div className="lottery-pool text-center">
                <h3>Lottery Pool Tickets</h3>
                <div
                  className="nav-item lottery-pool-button my-2"
                  role="presentation"
                >
                  <a
                    className="active"
                    data-bs-toggle="tab"
                    href="#personal_tickets"
                    role="tab"
                    aria-selected="true"
                  >
                    <button
                      className={`${activeColor === 1 ? "button_active" : ""}`}
                      onClick={() => setActiveColor(1)}
                    >
                      Personal Tickets
                    </button>
                  </a>
                  <a
                    className=""
                    data-bs-toggle="tab"
                    href="#lottery_pool_tickets"
                    role="tab"
                    aria-selected="false"
                    tabindex="-1"
                  >
                    <button
                      className={`${activeColor === 2 ? "button_active" : ""}`}
                      onClick={() => setActiveColor(2)}
                    >
                      Lottery Pool Tickets
                    </button>
                  </a>
                </div>
                <p>
                  A lottery pool increases your chances of winning without
                  increasing your costs. It's a collective effort where
                  participants share their lottery tickets' potential winnings.
                  However, this improved odds come with a trade-off: while your
                  chances of winning increase, individual payouts decrease. Read
                  more...
                </p>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="personal_tickets"
              role="tabpanel"
            >
              <PersonalTickets />
            </div>

            <div
              className="tab-pane fade"
              id="lottery_pool_tickets"
              role="tabpanel"
            >
              <LotteryPoolTickets />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WindfallTickets;
