import { db } from "../WebSocketProvider";
import { FundTypes } from "../utils/Enums";
import { asInt } from "../utils/Utils";
import Decimal from "decimal.js";

export const TransactionStatusType = {
  COMPLETED: "COMPLETED",
  CONFIRMING: "CONFIRMING",
  ERROR: "ERROR",
  EXPIRED: "EXPIRED",
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
};

export interface dbICurrency {
  seq_nbr: number;
  acronym: string;
  description: string;
  fund_type: string;
  exchange_rate: number;

  scale: number;
  status: string;
  balance: number;
  pending: number;
  withdrawal_fee: number;

  min_withdrawal: number;
  max_withdrawal: number;
  min_deposit: number;
  max_deposit: number;
  
  in_use: boolean;
}

export interface ICryptoProps {
  currency: ICurrency;
}

export function mapCurrency(data: dbICurrency): ICurrency {
  console.log(`AccountBase.tsx… mapping ${data.acronym}`, data);
  return {
    icon:     `/currencies/${data.acronym}.svg`,

    acronym:  data.acronym,
    descr:    data.description,
    fundType: data.fund_type as FundTypes,
    scale:    data.scale,

    status:   data.status,
    inUse:    !!data.in_use,
    exchange: new Decimal(data.exchange_rate),
    balance:  new Decimal(data.balance),
    pending:  new Decimal(data.pending),

    minDeposit:     data.min_deposit == null || data.min_deposit === 0 ? undefined : new Decimal(data.min_deposit),
    maxDeposit:     data.max_deposit == null || data.max_deposit === 0 ? undefined : new Decimal(data.max_deposit),
    withdrawalFee:  data.withdrawal_fee == null || data.withdrawal_fee === 0 ? undefined : new Decimal(data.withdrawal_fee),
    minWithdrawal:  data.min_withdrawal == null || data.min_withdrawal === 0 ? undefined : new Decimal(data.min_withdrawal),
    maxWithdrawal:  data.max_withdrawal == null ||data.max_withdrawal === 0 ? undefined : new Decimal(data.max_withdrawal),
  };
};

export const fetchCurrencies = (): ICurrency[] => {
  const query = `
    SELECT
      acronym, seq_nbr, fund_type, description, exchange_rate,
      scale, status, balance, pending, withdrawal_fee,
      min_withdrawal, max_withdrawal, min_deposit, max_deposit, in_use
    FROM
      client_balances
    ORDER BY
      seq_nbr;
  `;

  try {
    const currencies: ICurrency[] = [];

    const stmt = db.prepare(query);
    while (stmt.step()) {
      currencies.push(mapCurrency(stmt.getAsObject() as dbICurrency));
    }
    stmt.free();

    return currencies;
  } catch (error) {
    console.error("Error fetching currencies:", error);
    throw error;
  }
};
