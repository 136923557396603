import { Link } from "react-router-dom";
import { images } from "../../../helper";

export const Error400 = () => {
  return (
    <div className="error-page">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img className="img-responsive-mobile" src={images.svg_img_404} />
          </div>
          <div className="col-md-6">
            <br />
            <br />
            <br />
            <br />
            <label>Page Not Found</label>
            <br />
            <br />
            <h1>Oops!</h1>
            <br />
            <h2>Looks like Our Math Doesn't Add Up :(</h2>
            <div className="divider" />

            <Link to="/">
              <img 
                src={images.svg_ic_arrow_left_colored} 
                alt="Left Arrow"
              />
              &nbsp; Back to home page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
