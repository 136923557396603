import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import WindfallPayoutsTable from "./WindfallPayoutsTable";
import QSymbol from "../../../utils/QSymbol";
import { formatValue } from "../../../utils/Round";

const WindfallPayouts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a className="blue-link-underlined" onClick={handleShow} role="button">
        {t('_lottery.windfall.view_prize_table')}
      </a>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="presale-modal"
        centered
        size="lg"
        keyboard={false}
      >
        <div className="row">
          <div className="col-md-12 text-end">
            <button
              onClick={handleClose}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
        </div>

        <Modal.Header>
          <Modal.Title>{t('_lottery.windfall.payout.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t('_lottery.windfall.payout.description')}</p>
          <br />
          <p>{t('_lottery.windfall.payout.payout_table')}</p>
          <br />

          <WindfallPayoutsTable className="m-auto w-100p" />
          <br />

          <p>{t('_lottery.windfall.payout.odds_ticket')}</p>
          <br />
          <p>
            <Trans
              i18nKey="_lottery.windfall.payout.aggregate"
              components={{ amount: <QSymbol value={formatValue(52000000, 0)} /> }}
            />
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WindfallPayouts;
