import { useTranslation } from "react-i18next";
import { images } from "../../../helper";
import { PageHeader } from "./PageHeader";
import QButton from "../../elements/QButton";
import { CasinoBanner } from "../../inc/CasinoBanner";
import { Link } from "react-router-dom";
import { QLinkButton } from "../../elements/QLinkButton";

const ProvablyFairGeneral = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="pages">
      <PageHeader title="Provably Fair" />

      <div className="position-relative">
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-12">
              <ul>
                <li>
                  <strong>"Provably Fair"</strong> is a robust mathematical
                  process that allows players to independently verify the
                  fairness of our games. To provide a general understanding of
                  this process, here are some terms you should know.
                </li>
                <br />
                <li>
                  <strong> Hash:</strong> This refers to the process of
                  transforming a given key or a string of characters into
                  another value. A hash function is any function that can be
                  used to map data of arbitrary size to fixed-size values,
                  although there are some hash functions that support
                  variable-length output. The values returned by a hash function
                  are known as hash values, hash codes, or simply hashes.
                </li>
                <br />
                <li>
                  <strong>SHA-3:</strong> This is a secure hashing algorithm
                  used on binary data and adopted as a government standard.
                </li>
                <br />
                <li>
                  <strong> SHAKE256:</strong> This is a standard hashing
                  algorithm called Keccak, which is a critical component of
                  SHA-3.
                </li>
                <br />
                <li>
                  <strong> Digest:</strong> This refers to the output generated
                  by the SHAKE256 function, which can be initialized using a
                  given seed.
                </li>
                <br />
                <li>
                  <strong>Master Key:</strong> In some games, we utilize a daily
                  master key updated at midnight coordinated universal time
                  (UTC). This new master key is created using Java's secure
                  random number generator (referred to as the "seed"), and
                  subsequently processed through the digest to derive the master
                  key. Each game has its own distinct master key, and the seed
                  for this key is disclosed upon the conclusion of all games
                  that have been issued secret keys from this master key.
                </li>
                <br />
              </ul>
              <div className="provably-border">
                At Quinto Games, we rely on the SHAKE256, a critical component
                of the Secure Hash Algorithm-3 (SHA-3) family of functions on
                binary data, to ensure the generation of reliable random
                numbers.
              </div>
              <br />
              <ul>
                <li>
                  <strong>LOTTERY:</strong> In our lottery games, we generate a
                  document file that includes all ticket and pick information.
                  This document undergoes processing through a non-seeded
                  digest, yielding a distinctive hexadecimal value known as the
                  "Draw Hash." The Draw Hash serves as the major factor in
                  determining the final draw results. At the conclusion of each
                  lottery, Quinto Games makes the entire document publicly
                  accessible to ensure transparency.
                </li>
                <br />
                <li>
                  <strong>TRIMORRA: </strong> When the first player requests to
                  join a specific Trimorra game, a game key is derived from the
                  master key. This game key is processed through a digest, and
                  the first 16 bytes from the resulting digest are displayed in
                  hexadecimal notation (referred to as the “Hash”). After the
                  game concludes, the game's secret key is also provided in
                  hexadecimal notation, allowing players to independently
                  execute the digest algorithm locally to verify the game's
                  results for integrity, thereby ensuring fair play.
                </li>
                <br />
                <li>
                  <strong>BINGO:</strong> Upon the opening of a bingo game, a
                  game key is derived from the master key. This game key is
                  processed through a digest, and the first 16 bytes from the
                  resulting digest are displayed in hexadecimal notation
                  (referred to as the “Hash”). After the game concludes, the
                  game's secret key is also provided in hexadecimal notation,
                  allowing players to independently execute the digest algorithm
                  locally to verify the game's results for integrity, thereby
                  ensuring fair play.
                </li>
                <br />
              </ul>

              <div className="provably-bottom">
                <p>
                  For further information on how each games provably fair
                  calculation is determined, please read the provably fair
                  detail within each game.
                </p>
                <br />
                <div className="d-flex justify-content-between equal-width-three">
                  <QLinkButton
                    link="/provably-fair"
                    text="Windfall"
                    noIcon
                    className="btn-outline-primary"
                  />
                  <QLinkButton
                    text="Trimorra"
                    noIcon
                    className="btn-outline-primary"
                  />
                  <QLinkButton
                    text="3up Bingo!"
                    noIcon
                    className="btn-outline-primary"
                  />
                </div>
              </div>
            </div>
          </div>

          <CasinoBanner />
        </div>
      </div>
    </section>
  );
};

export default ProvablyFairGeneral;
