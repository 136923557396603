import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatValue } from "../../utils/Round";
import QSymbol from "../../utils/QSymbol";
import { IRoom } from "./TrimorraBase";
import "./Trimorra.css";

interface IWagerButtonProps {
  room: IRoom;
  isSelected: boolean;
  onClick: (room: IRoom, isDoubleClick: boolean) => void; // Updated to accept two arguments
}

const WagerButtonManager: React.FC<IWagerButtonProps> = ({
  room,
  isSelected,
  onClick,
}) => {
  const { t } = useTranslation();
  const clickTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleClick = () => {
    const clickTime = Date.now();
    console.log('Click detected at', clickTime);
  
    if (clickTimeout.current) {
      console.log('Double click detected');
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
      onClick(room, true); // Handle double click
    } else {
      console.log('Setting timeout for potential single click');
      clickTimeout.current = setTimeout(() => {
        console.log('Single click confirmed');
        clickTimeout.current = null;
        onClick(room, false); // Handle single click
      }, 300); // Adjust the delay to differentiate between single and double clicks
    }
  };
  

  return (
    <button
      className={`default-selection-button wager-button ${isSelected ? "selected" : ""}`}
      onClick={handleClick}
    >
      {room.buyin === 0 ? t('_common.free') : <QSymbol value={formatValue(room.buyin, room.buyin < 100 ? 2 : 0)} />}
    </button>
  );
};

export default React.memo(WagerButtonManager);
