import { Link } from "react-router-dom";
import { images } from "../../../helper";

export const Error500 = () => {
  return (
    <div className="error-page">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img className="img-responsive-mobile" src={images.svg_img_500} />
          </div>
          <div className="col-md-6">
            <br />
            <br />
            <br />
            <br />
            <label>(HTTP Error)</label>
            <br />
            <br />
            <h1>errr...500 :(</h1>
            <br />
            <h2>Internal Server Error can be a BIT tricky</h2>
            <div className="divider" />

            <Link to="/">
              <img 
                src={images.svg_ic_arrow_left_colored} 
                alt="Left Arrow"
              />
              &nbsp; Back to home page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
