import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import QButton from '../../elements/QButton';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { copyTextToClipboard, images } from '../../../helper';
import { db, apiMessage, onData } from '../../WebSocketProvider';
import { FundTypes, MessageTypes, api } from '../../utils/Enums';
import AlertMessage, { AlertMessageType } from '../../elements/AlertMessage';
import * as utils from '../../utils/Utils';
import { QRCodeCanvas } from 'qrcode.react';
import SelectorCryptoAddress from '../../elements/SelectorCryptoAddress';
import { ICryptoProps } from '../AccountBase';
import { toast } from 'react-toastify';
import "../Accounts.css"

const CryptoDeposit: React.FC<ICryptoProps> = ({ currency }) => {
  const { t } = useTranslation();

  // Error Handling
  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const [addresses, setAddresses] = useState<ICryptoAddress[]>([]);
  const [selected, setSelected] = useState<ICryptoAddress | undefined>(undefined);
  const [unused, setUnused] = useState<boolean>(true);
  const [copied, setCopied] = useState<boolean>(false);

  const accountType = "DEPOSIT";
  let loading:number | null = null;

  useEffect(() => {
    const fetchAddresses = (addr: string | null) => {
      const query = `
        SELECT 
          acronym, address, qrcode, last_used
        FROM 
          deposit_addresses
        WHERE 
          acronym = $currency
        ORDER BY 
          last_used IS NOT NULL, last_used DESC
      `;
  
      try {
        const rows: ICryptoAddress[] = [];
        let unusedCheck: boolean = false;
        console.log(`Initialize Unused: ${unusedCheck}`);
        const stmt = db.prepare(query);
        stmt.bind({$currency: currency.acronym});
        while (stmt.step()) {
          const row = stmt.getAsObject() as ICryptoAddress;
          unusedCheck = unusedCheck || (row.lastUsed === undefined || row.lastUsed == null);
          console.log(`Initialize Last Used ${row.lastUsed} Unused: ${unusedCheck}`);        
          if (addr && (row.address === addr)) {
            console.log(`Setting default address to ${addr}`);
            setSelected(row);
          }
          rows.push(row);
        }
        stmt.free();
        setAddresses(rows);
        setUnused(unusedCheck);
  
      } catch (error) {
        console.error('Error querying deposit addresses:', error);
      }
    };

    const handleData = (data: IData) => {
      if (!!loading && loading !== data.id) return;
      loading = null;
      fetchAddresses(data.row.address);
    };

    const handleError = (data: IError) => {
      //console.log('TrimorraWagerSelection.handleError data:', data);

      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason || "An error occurred while retriving address.");
      setTimeout(() => setMessage(undefined), 5000); // Clear the message after 5 seconds
    };

    fetchAddresses(utils.readParams(currency.acronym, accountType, null));

    const addr = utils.readParams(currency.acronym, accountType, null);
    console.log(`Default address for ${currency.acronym}: ${addr}`)

    if (addr) {
      // Find currency matching the acronym
      const dfltAddr = addresses.find(address => address.address === addr);
      if (dfltAddr) {
        console.log("Setting initial address:", dfltAddr);
        setSelected(dfltAddr);
      }
    }

    onData.on(MessageTypes.CRYPTO_ADDRESS, handleData);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      onData.off(MessageTypes.CRYPTO_ADDRESS, handleData);
      onData.off(MessageTypes.ERROR, handleError);
    };
  }, []);  

  useEffect(() => {
    console.log(`CryptoDeposit Currency Update`);
  }, [currency]);

  const requestAddress = () => {
    setMessage(undefined);
    loading = apiMessage(api.REQUEST_ADDRESS, { acronym: currency.acronym });
  };

  const handleAddressSelect = (data: ICryptoAddress) => {
    utils.writeParams(currency.acronym, accountType, data.address);    
    setSelected(data);
  };  

  const handleCopy = () => {
    copyTextToClipboard(selected?.address);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <>
      <SelectorCryptoAddress
        label={t('Select Address')}
        addresses={addresses}
        onSelect={handleAddressSelect}
        preSelect={selected}
        accountType={accountType}
      />

      <div className="deposit-details">
        {selected && (
          <div className="card user-account-tabs-card twofa-card mt-10">
          <div className="card-body">
            <div className="card-content bg-transp">
              <div className="row">
                <div className="col-md-8">
                  <div className="bordered-info">
                    <div className="card-white-normal sk">
                      <label className="text-primary">
                        {currency.descr} ({currency.acronym})
                      </label>
                      <br />
                    </div>
                  </div>

                  <br />
                  <div className="bordered-info">
                    <div className="card-white-normal sk">
                      <label>Deposit Address</label>
                      <br />
                      <br />
                      <label className="mt">{selected.address}</label>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="copy-tooltip">{t(!!copied ? '_common.copied' : '_common.copy')}</Tooltip>}
                        trigger={['hover', 'focus']}
                      >
                        <img
                          className="copy-icon"
                          src={images.svg_ic_copy}
                          alt="Copy"
                          onClick={handleCopy}
                        />
                      </OverlayTrigger>                      
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="bordered-info">
                    <div className="card-white-normal qr">
                      <QRCodeCanvas size={250} value={selected.qrcode} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>          
        )}            

        <button
          className={`default-selection-button request-button`}
          onClick={() => requestAddress()}
          disabled={unused}
        >
          {t("_ACCOUNTING.deposit.newAddress")}
        </button>

        {message && (<AlertMessage type={messageType} message={message} />)}
      </div>
    </>
  );
};
export default CryptoDeposit;
