import { useEffect } from "react";

export const images = {
  // Home Page Images
  svg_ic_copy: require("./assets/images/copy-svgrepo-com.svg").default,
  svg_presale_button: require("./assets/images/presale-button.svg").default,
  
  svg_banner_icon: require("./assets/images/banner_icon.svg").default,
  svg_ic_chat: require("./assets/images/ic-chat.svg").default,
  svg_ic_medal_star_square: require("./assets/images/ic-medal-star-square.svg")
    .default,
  svg_ic_star: require("./assets/images/ic-star.svg").default,
  svg_ic_user: require("./assets/images/ic-user.svg").default,
  svg_ic_file_presentation: require("./assets/images/ic-file-presentation.svg")
    .default,
  svg_ic_settings: require("./assets/images/ic-settings.svg").default,
  svg_ic_login_3: require("./assets/images/ic-login-3.svg").default,
  svg_ic_important: require("./assets/images/ic-important.svg").default,
  svg_ic_important_2: require("./assets/images/ic-important-2.svg").default,
  svg_ic_help: require("./assets/images/ic-help.svg").default,
  svg_ic_windfall: require("./assets/images/ic-windfall.svg").default,
  svg_ic_shield_user: require("./assets/images/ic-shield-user.svg").default,
  svg_ic_clipboard: require("./assets/images/ic-clipboard.svg").default,
  svg_ic_calculator_minimalistic:
    require("./assets/images/ic-calculator-minimalistic.svg").default,
  svg_ic_fb: require("./assets/images/ic-fb.svg").default,
  svg_ic_insta: require("./assets/images/ic-insta.svg").default,
  svg_ic_tiktok: require("./assets/images/ic-tiktok.svg").default,
  svg_ic_linkedin: require("./assets/images/ic-linkedin.svg").default,
  svg_ic_x: require("./assets/images/ic-x.svg").default,
  svg_18plus: require("./assets/images/18plus.svg").default,
  svg_18plus_large: require("./assets/images/18plus-large.svg").default,
  svg_h_logo: require("./assets/images/h_logo.svg").default,
  svg_h_logo_large: require("./assets/images/h_logo_large.svg").default,
  svg_quinto_logo: require("./assets/images/quinto-logo.svg").default,
  svg_logo_q: require("./assets/images/logo-q.svg").default,
  svg_logo_q_white: require("./assets/images/logo-q-white.svg").default,
  svg_eye_off: require("./assets/images/eye-off.svg").default,
  svg_eye: require("./assets/images/eye.svg").default,
  svg_2fa_colored: require("./assets/images/2fa-colored.svg").default,
  svg_ic_mail: require("./assets/images/ic-mail.svg").default,
  svg_login_button: require("./assets/images/login_button.svg").default,
  svg_google: require("./assets/images/google.svg").default,
  svg_apple: require("./assets/images/apple.svg").default,
  svg_windfall: require("./assets/images/windfall.svg").default,
  svg_img_windfall_presale: require("./assets/images/img-windfall-presale.svg")
    .default,
  svg_notification_bell: require("./assets/images/ic-notification-bell.svg")
    .default,
  svg_arrow_down: require("./assets/images/ic-arrow-down.svg").default,
  svg_wl_tagline: require("./assets/images/wl-tagline.svg").default,
  svg_windfall_lp: require("./assets/images/windfall-lp.svg").default,
  
  
  svg_countdownnumbers: require("./assets/images/Countdownnumbers.svg").default,
  svg_ic_play: require("./assets/images/ic-play.svg").default,
  svg_ic_game_windfall: require("./assets/images/ic-game-windfall.svg").default,
  svg_ic_game_trimorra: require("./assets/images/ic-game-trimorra.svg").default,
  svg_play_icon: require("./assets/images/play_icon.svg").default,
  svg_casino_banner: require("./assets/images/casino_banner.svg").default,
  svg_banner_play_more_win_more:
    require("./assets/images/banner-play-more-win-more.svg").default,
  svg_img_fairness: require("./assets/images/img-fairness.svg").default,
  svg_img_how_it_works: require("./assets/images/img-how-it-works.svg").default,
  svg_img_fairness_hiw: require("./assets/images/img-fairness-hiw.svg").default,
  svg_img_signup_deposite: require("./assets/images/img-signup-deposit.svg")
    .default,
  svg_img_choose_games: require("./assets/images/img-choose-games.svg").default,
  svg_img_get_bonus: require("./assets/images/img-get-bonus.svg").default,
  svg_img_instant_withdraw: require("./assets/images/img-instant-withdraw.svg")
    .default,
  svg_img_support_bg: require("./assets/images/img-support-bg.svg").default,
  svg_img_404: require("./assets/images/img-404.svg").default,
  svg_img_500: require("./assets/images/img-500.svg").default,
  svg_ic_arrow_left_colored:
    require("./assets/images/ic-arrow-left-colored.svg").default,
  svg_ic_arrow_left_black: require("./assets/images/ic-arrow-left-black.svg")
    .default,
  svg_participant: require("./assets/images/participant.svg").default,
  svg_play_now_trimorra: require("./assets/images/play-now-trimorra.svg")
    .default,
  svg_play_now_3up_bingo: require("./assets/images/play-now-3up-bingo.svg")
    .default,
  svg_img_gamblers_anonymous:
    require("./assets/images/img-gamblers-anonymous.svg").default,
  svg_quinto_logo_blue: require("./assets/images/quinto-logo-blue.svg").default,
  svg_file_plus: require("./assets/images/file-plus.svg").default,
  svg_ic_repeat: require("./assets/images/ic-repeat.svg").default,
  svg_ic_repeat_white: require("./assets/images/ic-repeat-white.svg").default,
  svg_ic_plus: require("./assets/images/ic-plus.svg").default,
  svg_ic_arrow_down_alt: require("./assets/images/ic-arrow-down-alt.svg")
    .default,
  svg_ic_arrow_up_alt: require("./assets/images/ic-arrow-up-alt.svg").default,
  svg_ic_arrow_collapse: require("./assets/images/ic-arrow-collapse.svg").default,
  svg_ic_check: require("./assets/images/ic-check.svg").default,
  svg_ic_history: require("./assets/images/ic-history.svg").default,
  svg_ic_chevron_down: require("./assets/images/ic-chevron-down.svg").default,
  svg_ic_chevron_up: require("./assets/images/ic-chevron-up.svg").default,
  svg_ic_close: require("./assets/images/ic-close.svg").default,
  svg_ic_remove: require("./assets/images/ic-remove.svg").default,
  svg_ic_edit: require("./assets/images/ic-edit.svg").default,
  svg_ic_minus_circle: require("./assets/images/ic-minus-circle.svg").default,
  svg_ic_plus_circle: require("./assets/images/ic-plus-circle.svg").default,
  svg_ic_info_dark: require("./assets/images/ic-info-dark.svg").default,
  svg_ic_chevron_lw_down: require("./assets/images/ic-chevron-lw-down.svg")
    .default,
  svg_ic_chevron_lw_up: require("./assets/images/ic-chevron-lw-up.svg").default,
  svg_left_arrow_white: require("./assets/images/left_arrow_white.svg").default,
  svg_right_arrow_white: require("./assets/images/right_arrow_white.svg")
    .default,
  svg_left_arrow_dark: require("./assets/images/left_arrow_dark.svg").default,
  svg_right_arrow_dark: require("./assets/images/right_arrow_dark.svg").default,
  svg_windfall_acct: require("./assets/images/windfall_acct.svg").default,

  svg_windfall_rectangle_12: require("./assets/images/Rectangle_12.svg")
    .default,
  svg_windfall_Vector: require("./assets/images/Vector.svg").default,
  svg_windfall_search: require("./assets/images/search.svg").default,
  svg_windfall_status: require("./assets/images/Status.svg").default,
  svg_windfall_detail: require("./assets/images/Detail_view.svg").default,
  svg_windfall_jc: require("./assets/images/Jc.svg").default,
  svg_windfall_ellipse: require("./assets/images/Ellipse_3.svg").default,
  svg_windfall_x_circle: require("./assets/images/x_circle.svg").default,
  svg_trimorra_tier_1: require("./assets/images/Tier_1.svg").default,
  svg_trimorra_tier_2: require("./assets/images/Tier_2.svg").default,
  svg_trimorra_tier_3: require("./assets/images/Tier_3.svg").default,
  svg_trimorra_tier_4: require("./assets/images/Tier_4.svg").default,
  svg_trimorra_profile: require("./assets/images/profile.svg").default,
  svg_trimorra_game_logo: require("./assets/images/trimorra_game_logo.svg").default,
  svg_replay: require("./assets/images/replay.svg").default,

  svg_headphones: require("./assets/images/ic-headphones.svg").default,
  svg_mail: require("./assets/images/ic-mail.svg").default,
  svg_arrow_down_colored: require("./assets/images/ic-arrow-down-colored.svg")
    .default,

  png_headphones: require("./assets/images/headphones.png").default,
  png_mail: require("./assets/images/mail.png").default,
  png_d_errow: require("./assets/images/d_errow.png").default,
  png_country_flag: require("./assets/images/country_flag.png").default,
  png_user: require("./assets/images/user.png").default,
  png_2FA1: require("./assets/images/2FA1.png").default,
  png_ic_game_3up_bingo: require("./assets/images/ic-game-3up-bingo.png")
    .default,

  jpg_coming_soon: require("./assets/images/comming-soon.jpg").default,
};


export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
