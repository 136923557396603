import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { images } from '../../helper';
import { apiMessage, onData } from '../WebSocketProvider';
import AlertMessage, { AlertMessageType } from '../elements/AlertMessage';
import PasswordElement from '../elements/PasswordElement';
import TwoFAElement from '../elements/TwoFAElement';
import QButton from '../elements/QButton';
import { MessageTypes, api } from '../utils/Enums';
import { LoginWith } from './LoginWith';

export const Login: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [name, setName] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);

  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const [replyId, setReplyId] = useState<number | null>(null);

  const handleChange = (data: IData) => {
    setReplyId(null);
  };

  const handleReply = (data: IData) => {
    if (data.id !== replyId) return;
  };
    
  useEffect(() => {
    const handleError = (data: IError) => {
      console.log(`GET NOTIFIED ${replyId} vs ${data.id}`, data);

      if (replyId !== data.id) return;

      setReplyId(null);
      console.log(`LOGIN ERROR: ${data.err}`, data);

      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason);
    };

    onData.on(MessageTypes.CLIENT, handleChange);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      onData.off(MessageTypes.EMPTY, handleReply);
      onData.off(MessageTypes.CLIENT, handleChange);
      onData.off(MessageTypes.ERROR, handleError);
    };
  }, [replyId]);

  const handlePassword = (value: string) => {
    setPassword(value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage(undefined);

    if (!name) {
      setMessage('Please enter your email or user_name.');
      return;
    }

    if (!password) {
      setMessage('Please enter password.');
      return;
    }

    // No need to check for 2FA code here; we handle it on the server-side

    onData.on(MessageTypes.EMPTY, handleReply);
    setReplyId(apiMessage(api.LOGIN, { password, email: name, code }));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="login_main">
          <div>
            <div className="sign_title col-12 mb-md-5 mb-4">
              <p>{t('_login.welcome')}</p>
              <h2>{t('_login.sub_heading')}</h2>
            </div>
            <div className="login_page_main d-lg-flex justify-content-between">
              <div className="col-xl-5 col-lg-6 col-md-12 flex-column justify-content-center mb-5 mb-lg-0">
                <form autoComplete="off" onSubmit={handleSubmit} className="text-center">
                  <div className="login_field">
                    <input
                      autoFocus
                      className="form-control"
                      autoComplete="one-time-code"
                      type="text"
                      value={name || ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                      placeholder={`${t('_login.input.email')} / ${t('_login.input.username')}`}
                    />
                  </div>
                  <br />
                  <PasswordElement
                    onPasswordChange={handlePassword}
                    placeholder={t("_login.input.password")}
                  />
                  <br />
                  <TwoFAElement
                    value={code || ''}
                    onInput={(value) => setCode(value)}
                    label="If you have 2FA, please enter the code!"
                  />
                  <br/>
                  <AlertMessage type={messageType} message={message} />
                  <QButton
                    className={`default-selection-button request-button`}
                    submit
                    block
                    text={t('_login.signin')}
                    disabled={replyId !== null}
                  />
                </form>
                <br/>
                <div className="trouble_login trouble_login_page text-center mb-2">
                  <span>{t('_login.no_account')}</span>
                  <span>
                    <Link to="/register">{t('_login.create_here')}</Link>
                  </span>
                </div>
                <div className="trouble_login trouble_login_page text-center mb-2">
                  <span>{t('_login.forgot')} </span>
                  <span>
                    <Link to="/forgot-password">{t('_common.click_here')}</Link>
                  </span>
                </div>
              </div>
              <span className="middle_border d-none d-lg-block"></span>
              <div className="col-xl-4 col-lg-5 col-md-12 text-center">
                <div className="lo_rightside">
                  <div className=" ">
                    <img className="img-responsive" src={images.svg_windfall_acct} alt="" />
                  </div>
                  <div className="acc_border my-4"></div>
                  <LoginWith />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link className="go_back_to_main" to="/">
                  {t('_common.back-home')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
