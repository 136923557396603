import React, { useState } from "react";
import { images } from "../../helper";

const TicketQuantity = (props) => {
  const [qty, setQty] = useState(1);

  return (
    <div className="ticket-qty-section">
      <div className="ticket-qty py-2 px-3">
        <div>
          <label>{props.label_1} </label>
          <label>{props.label_2} </label>
          <p>{props.label_3}</p>
        </div>
        <input value={qty} readOnly type="text" />
      </div>
      <div className="ticket-qty-img">
        <div className="flex-fill d-flex justify-content-center align-items-center my-1">
          <img
            onClick={() => setQty(qty + 1)}
            src={images.svg_ic_chevron_lw_up}
            alt="TODO: Image Title"/>
        </div>
        <div className="flex-fill d-flex justify-content-center align-items-center">
          <img
            onClick={() => {
              if (qty > 1) {
                setQty(qty - 1);
              }
            }}
            src={images.svg_ic_chevron_lw_down}
            alt="TODO: Image Title"/>
        </div>
      </div>
    </div>
  );
};

export default TicketQuantity;
