// src/compnents/utils/QSymbol.tsx
import React from 'react';

interface QSymbolProps {
  value?: number | string;
  className?: string;
}

const QSymbol: React.FC<QSymbolProps> = ({ value, className = "q-symbol" }) => {
  return (
    <span>
      <span className={className}/>{value !== undefined && <span>{value}</span>}
    </span>
  );
};

export default QSymbol;