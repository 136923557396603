import React, { useEffect, useState, useRef } from "react";
import { formatValue } from "../utils/Round";
import QSymbol from "../utils/QSymbol";
import "./JackpotAnimator.css";

export interface IJackpotAnimator {
  init: {
    value: number; // The initial value we want to achieve
    precision?: number; // How many decimal points : DEFAULT = 0
    symbol?: boolean; // This indicates we want to display our currency symbol.
    time?: number; // How long to get the final value in seconds. : undefined = instant
  };
  target: number;
}

const JackpotAnimator: React.FC<IJackpotAnimator> = ({ init, target }) => {
  const [displayedValue, setDisplayedValue] = useState<number>(init.value);
  const currentValue = useRef<number>(init.value);
  const initialized = useRef<boolean>(false);
  const lastValue = useRef<number>(0);

  useEffect(() => {
    const duration = init.time ? init.time * 1000 : 0;

    if (!initialized.current || (init.value === target && init.value !== 0)) {
      setDisplayedValue(init.value);
      currentValue.current = init.value;
      initialized.current = true;
      return;
    }
    if (target === currentValue.current) return;

    if (duration === 0) {
      setDisplayedValue(target);
      currentValue.current = target;
      return;
    }

    const delay = 50;
    const stages = Math.round(duration / delay);
    const increment = (target - currentValue.current) / stages;

    const updateValue = () => {
      currentValue.current += increment;
      const truncValue = Math.trunc(currentValue.current);
      if (truncValue !== lastValue.current) {
        lastValue.current = truncValue;
        setDisplayedValue(truncValue);
      }

      if (
        (increment > 0 && currentValue.current < target) ||
        (increment < 0 && currentValue.current > target)
      ) {
        setTimeout(updateValue, delay);
      } else {
        currentValue.current = target;
        setDisplayedValue(target);
      }
    };

    updateValue();
  }, [init, target]);

  const formattedValue = formatValue(displayedValue, init.precision ?? 0);

  // Function to check if a character is a digit
  const isDigit = (char: string) => /\d/.test(char);

  return (
    <ul className="animator">
      {init.symbol && (<li><QSymbol /></li>)}
      {String(formattedValue)
        .split("")
        .map((val, key) => (
          <li key={`na-${key}`} className={!isDigit(val) ? "non-digit" : ""}>
            {val}
          </li>
        ))}
    </ul>
  );
};

export default JackpotAnimator;
