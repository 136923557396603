import React, { useEffect, useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { copyTextToClipboard, images } from "../../helper";
import { apiMessage, onData } from "../WebSocketProvider";
import AlertMessage, { AlertMessageType } from "../elements/AlertMessage";
import QButton from "../elements/QButton";
import TwoFAElement from "../elements/TwoFAElement";
import { MessageTypes, api } from "../utils/Enums";
import { QCardHeaders } from "./QCardHeaders";

interface ErrorData {
  id: number;
  reason: string;
}

const TwoFA: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [replyId, setReplyId] = useState<number | null>(null);
  const [copied, setCopied] = useState<boolean>(false);

  const handleChange = (data: IData) => {
    if (data.id !== replyId) return;

    setCode("");
    setReplyId(null);
    setMessageType(AlertMessageType.SUCCESS);
    navigate("/profile");
    
    unHook();
  };

  const handleError = (data: IData) => {
    if (replyId !== data.id) return;

    setMessageType(AlertMessageType.WARNING);
    setMessage(data.row.reason);
    setReplyId(null);
    setCode("");
    
    unHook();
  };

  const unHook = () => {
    onData.off(MessageTypes.CLIENT, handleChange);
    onData.off(MessageTypes.ERROR, handleError);
  };

  useEffect(() => {
    return () => {
      unHook();
    };
  }, [replyId]);

  const handleCopy = () => {
    copyTextToClipboard(mii.totp_secret);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage("");

    if (!code) {
      setMessage("Please enter 2FA code");
      return;
    }

    onData.on(MessageTypes.CLIENT, handleChange);
    onData.on(MessageTypes.ERROR, handleError);
    setReplyId(apiMessage(api.TOTP_AUTH, { code }));
  };

  return (
    <div className="card user-account-tabs-card twofa-card">
      <div className="card-body">
        <QCardHeaders
          title={t('_profile.section.2FA.title')}
          subTitle={t('_profile.section.2FA.subTitle')}
          desc={t('_profile.section.2FA.desc')}
        />

        <div className="card-content">
          <div className="text-center mb-4">
            <QRCodeCanvas size={200} value={String(mii ? mii.totp_qrcode : "")} />
            <span>
              <h4>{t('_profile.section.2FA.inputs.qrCode')}</h4>
            </span>
          </div>

          <div className="card-white-normal sk mb-4 text-center">
            <label>{t('_profile.section.2FA.inputs.secret')}</label>
            <br />
            <div className="d-inline-block">
              <label className="mt">{mii.totp_secret}</label>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="copy-tooltip">{t(!!copied ? '_common.copied' : '_common.copy')}</Tooltip>}
                trigger={['hover', 'focus']}
              >
                <img
                  className="copy-icon"
                  src={images.svg_ic_copy}
                  alt="Copy"
                  onClick={handleCopy}
                />
              </OverlayTrigger>
            </div>
            <br/>
            <br/>
            <form onSubmit={handleSubmit} className="text-center">
              <TwoFAElement
                value={code}
                onInput={(value) => setCode(value)}
                label={t('_profile.section.2FA.inputs.validate')}
                override
              />
              <br />
            </form>
            <br/>
            <QButton
              className={`default-selection-button request-button`}
              disabled={!!replyId}
              submit
              block
              text={t("_profile.btn.validate_2FA")}
            />
          </div>

          <AlertMessage className="mt-4" type={messageType} message={message} />
        </div>
      </div>
    </div>
  );
};

export default TwoFA;
