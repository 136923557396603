import { useEffect } from "react";
import { useState } from "react";

const WordLimiter = (props) => {
  const [value, setValue] = useState(props.value || "");
  const [required, setRequired] = useState(props.req);
  const [wordCount, setWordCount] = useState(0);
  const [isCharCount, setIsCharCount] = useState(props.charCount || false);

  const maxWords = (props.maxWords || -1);
  const check_words = (e) => {
    let value = e.target.value;

    setValue(value.replace(/[0-9]/gi, ""));

    if (!isCharCount) {
      var BACKSPACE = 8;
      var DELETE = 46;
      var valid_keys = [BACKSPACE, DELETE];
      var words = value.split(" ");

      if (
        maxWords != -1 &&
        words.length >= maxWords &&
        valid_keys.indexOf(e.keyCode) === -1
      ) {
        e.preventDefault();
        words.length = maxWords;
        e.target.value = words.join(" ");
      }

      if (e.target.value.length === 0) {
        setWordCount(0);
      } else {
        setWordCount(words.length);
      }
    }

    setValue(e.target.value);

    props.onChange && props.onChange(e.target.value);
  };

  useEffect(() => {
    if (value === undefined) return;

    if (isCharCount) {
      if (value.length === 0) {
        setWordCount(0);
      } else {
        setWordCount(value.length);
      }
      return;
    }

    var words = value.split(" ");

    if (value.length === 0) {
      setWordCount(0);
    } else {
      setWordCount(words.length);
    }
  }, [value]);

  useEffect(() => {
    setValue(props.value || "");
    setIsCharCount(props.charCount || false);
    setRequired(props.req === undefined ? true : props.req);
  }, [props]);

  return (
    <>
      <textarea
        placeholder={props.placeholder || ""}
        maxLength={maxWords}
        className="form-control"
        onFocus={(e) => {
        }}
        onBlur={(e) => {
        }}
        rows={props.rows || "4"}
        value={value}
        onChange={(e) => {
          check_words(e);
        }}
        required={required}
      ></textarea>
      <span className="word-limiter-show">
        <small>
          {wordCount} / {maxWords} {`${isCharCount ? "Characters" : "Words"}`}
        </small>
      </span>
    </>
  );
};

export default WordLimiter;
