import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { images } from "../../helper";
import { MessageTypes, api } from "../utils/Enums";
import { apiMessage, onData } from "../WebSocketProvider";
import AlertMessage, {AlertMessageType } from "../elements/AlertMessage";
import PasswordElement from "../elements/PasswordElement";

export const ResetPassword = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [replyId, setReplyId] = useState(false);

  const [code, setCode] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confPassword, setConfPassword] = useState(null);

  const [messageType, setMessageType] = useState(AlertMessageType.WARNING);
  const [message, setMessage] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (data) => {
    // If data is the replyId then clear all states
    if (data === replyId) {
      setCode(null);
      setReplyId(null);
      setNewPassword(null);
      setConfPassword(null);
      setMessageType(AlertMessageType.SUCCESS);
      setMessage("An email with the verification code has been sent.");
    }
  };

  const handleError = (data) => {
    if (replyId === data.id) {
      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason);
      setReplyId(null);
      setCode(null);
    }
  };

  useEffect(() => {
    onData.on(MessageTypes.TODO, handleChange);
    onData.on(MessageTypes.ERROR, handleError);

    if (searchParams.has("pin_code")) {
      setCode(searchParams.get("pin_code").replace(/ /g, ""));
    } else {
      navigate("/not-found");
    }
    return () => {
      onData.off(MessageTypes.TODO, handleChange);
      onData.off(MessageTypes.ERROR, handleError);
    };
  }, [searchParams, handleChange, handleError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage(null);

    if (!newPassword) {
      setMessage("Please enter new password");
      return;
    }

    if (!confPassword) {
      setMessage("Please enter confirm new password");
      return;
    }

    if (newPassword !== confPassword) {
      setMessage("New password & confirm new password not match.");
      return;
    }

    setReplyId(
      apiMessage(api.RESET_PASSWORD, { password: newPassword, pin_code: code }),
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="login_main">
          ""
          <div>
            <div className="sign_title text-center col-12 mb-md-5 mb-4">
              <p>{t("_reset_pwd.heading")}</p>
              <h2>{t("_reset_pwd.sub_heading")}</h2>
            </div>
            <div className="login_page_main d-lg-flex justify-content-between">
              <div className="offset-md-4 col-xl-4 col-lg-5 col-md-12 flex-column justify-content-center mb-5 mb-lg-0">
                <div className="lo_leftside">
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className="text-center"
                  >
                    <PasswordElement
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                      placeholder={t("_profile.change_password.plc.new")}
                    />
                    <br />

                    <PasswordElement
                      onChange={(e) => setConfPassword(e.target.value)}
                      value={confPassword}
                      placeholder={t("_profile.change_password.plc.conf")}
                    />
                    <br />

                    <AlertMessage type={messageType} message={message} />

                    <button
                      type="submit"
                      disabled={replyId}
                      className="login_button"
                    >
                      <span>
                        <img src={images.svg_login_button} alt="" />
                      </span>
                      <span>{t("_reset_pwd.btn_signin")}</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <Link className="go_back_to_main" to="/login">
                  {t("_forgot_pwd.back_to_login")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
