import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { writeParams } from '../utils/Utils';
import { MessageTypes } from '../utils/Enums';

const TrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    const storeLastPage = async (path: string) => {
      writeParams(MessageTypes.MII, "page", path);
    }      

    storeLastPage(location.pathname);
  }, [location.pathname]);
};

export default TrackPage;
