import React from "react";
import { Link } from "react-router-dom";
import { images } from "../../../helper";

const QRewards = () => {
  return (
    <section className="pages page-bg-white">
      <div className="position-relative">
        <div className="container">
          <div className="row ">
            <div className="col-md-12">
              <h1 className="heading">Welcome to the Quinto Rewards</h1>
              <br />

              <p>
                The Quinto Rewards program is the company’s way to say thank you
                to our valued loyal members.
              </p>
              <br />

              <h4>How It Works</h4>
              <p>
                Your rewards are based on the company's revenue generated from
                your wagers, not the amount of your wager.
              </p>
              <br />

              <h4>Initial Reward Rate</h4>
              <p>
                When you join Quinto Games, you are automatically part of Quinto
                Rewards. The initial reward rate is 5%, and it can go as high as
                25%! This means the more you contribute to the company's
                success, the more you get rewarded.
              </p>

              <br />
              <h4>Earning Your Rewards</h4>
              <p>
                It doesn’t matter if your wager was in dollars, euros, or any
                other currency as your rewards are calculated using the
                company’s internal currency of Quintoshis – ("
                <span className="q-symbol">&#xE1E0;</span>").
              </p>

              <p>
                The use of Quintoshis – ("
                <span className="q-symbol">&#xE1E0;</span>") also ensures that
                everyone has a fair shot at reaching their milestones as all
                calculations are done in Quintoshis.
              </p>
              <br />

              <h4>Leveling Up Your Rewards</h4>
              <p>
                As you play your reward earnings and rate can increase as you
                reach set Quinto Rewards milestones. These milestones are like
                checkpoints on your journey to higher rewards. To reach the next
                milestone, you'll need to earn a certain amount in Quinto
                Rewards. Don’t worry; the math is done for you!
              </p>

              <p>
                For example, to go from 5% to 5.5%, you'd need to earn ("
                <span className="q-symbol">&#xE1E0;</span>")32.89 in Quinto
                Rewards.
              </p>
              <br />
              <h4>Reaching a Milestone</h4>
              <p>
                When you reach a milestone, your reward rate automatically
                increases by 0.5% until you reach the capped rate of 25%. Any
                surplus rewards beyond your current milestone requirement are
                carried over to your next milestone, ensuring that no rewards go
                to waste.
              </p>
              <br />
              <h4>Getting Your Rewards</h4>
              <p>
                Quinto Rewards are processed every Monday, starting at 1:00 a.m.
                UTC. Please, keep in mind that it might take some time to
                process all the members’ rewards.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QRewards;
