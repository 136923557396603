import React, { useState, useEffect, CSSProperties } from 'react';
import { images } from '../../helper';

interface QButtonProps {
  submit?: boolean;
  block?: boolean;
  disabled?: boolean;
  icon?: string;
  noIcon?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  text?: string | React.ReactNode; // Allow text to be either a string or a React node
  children?: React.ReactNode;
  style?: CSSProperties; // Add this line to include the style prop
}

const QButton: React.FC<QButtonProps> = (props) => {
  const [submit, setSubmit] = useState<boolean>(props.submit || false);
  const [block, setBlock] = useState<boolean>(props.block || false);
  const [disabled, setDisabled] = useState<boolean>(props.disabled || false);
  const [icon, setIcon] = useState<string | undefined>(props.icon);
  const [isIcon, setIsIcon] = useState<boolean>(props.noIcon === undefined ? true : false);

  useEffect(() => {
    setSubmit(props.submit || false);
    setBlock(props.block || false);
    setDisabled(props.disabled || false);
    setIcon(props.icon);
    setIsIcon(props.noIcon === undefined ? true : false);
  }, [props]);

  return (
    <button
      type={submit ? 'submit' : 'button'}
      disabled={disabled}
      onClick={(e) => {
        props.onClick && props.onClick(e);
      }}
      className={`btn btn-quinto ${block ? 'btn-block' : ''} ${props.className || ''} ${
        disabled ? 'btn-disabled' : ''
      }`}
      style={props.style} // Apply the style prop here
    >
      {icon && <img src={icon} alt="icon" />}
      <span>{props.text || props.children}</span>
    </button>
  );
};

export default QButton;
