import React, { useState, KeyboardEvent, FocusEvent } from "react";
import { images } from "../../helper";
import './PasswordElement.css';

interface PasswordElementProps {
  onPasswordChange: (value: string) => void;
  className?: string;
  autoFill?: boolean;
  placeholder?: string;
  prefill?: string;
}

const PasswordElement: React.FC<PasswordElementProps> = (props) => {
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [flag, setFlag] = useState(true);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    props.onPasswordChange(password || '');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === "Tab") {
      props.onPasswordChange(password || '');
    }
  };

  return (
    <div className={`pwd-field-container ${props.className || ""}`}>
      <input
        type={flag ? "password" : "text"}
        value={password}
        className="form-control pwd-input"
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={(e) => setPassword(e.target.value)} // Update internal state
        placeholder={props.placeholder || ""}
      />
      <div
        onClick={() => {
          setFlag(!flag);
        }}
        className="pwd-hand"
      >
        <img src={flag ? images.svg_eye : images.svg_eye_off} alt="Toggle Password Visibility" />
      </div>
    </div>
  );
};

export default PasswordElement;
