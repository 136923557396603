import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IPlaceholder, renderPlaceholders } from "../../GameBase";
import { ensureArray } from "../../../utils/Utils";

import "./Windfall.css";

const ProvablyFair = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const section1Title = t('_lottery.windfall.provablyFair.section-1.title');
  const section1Paragraph1 = ensureArray(t('_lottery.windfall.provablyFair.section-1.paragraph-1', { returnObjects: true })).join('');
  const section1Paragraph2 = ensureArray(t('_lottery.windfall.provablyFair.section-1.paragraph-2', { returnObjects: true })).join('');
  const section1_1Title = t('_lottery.windfall.provablyFair.section-1.seciton-1.title');
  const section1_1Paragraph1 = ensureArray(t('_lottery.windfall.provablyFair.section-1.seciton-1.paragraph-1', { returnObjects: true })).join('');
  const section1_1Paragraph2 = ensureArray(t('_lottery.windfall.provablyFair.section-1.seciton-1.paragraph-2', { returnObjects: true })).join('');

  const section2Title = t('_lottery.windfall.provablyFair.section-2.title');
  const section2Paragraph1 = ensureArray(t('_lottery.windfall.provablyFair.section-2.paragraph-1', { returnObjects: true })).join('');
  const section2Paragraph2 = ensureArray(t('_lottery.windfall.provablyFair.section-2.paragraph-2', { returnObjects: true })).join('');

  const section3Title = t('_lottery.windfall.provablyFair.section-3.title');
  const section3Paragraph1 = ensureArray(t('_lottery.windfall.provablyFair.section-3.paragraph-1', { returnObjects: true })).join('');

  const section4Title = t('_lottery.windfall.provablyFair.section-4.title');
  const section4Paragraph1 = ensureArray(t('_lottery.windfall.provablyFair.section-4.paragraph-1', { returnObjects: true })).join('');
  
  const placeholders: { [key: string]: IPlaceholder } = {
    link: {
      type: 'link',
      href: "/windfall/provably-fair-tech",
      content: t('_lottery.windfall.provablyFair.section-4.click'),
    },
  };

  const renderedText = renderPlaceholders(section4Paragraph1, placeholders, navigate);

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, [i18n.language]);

  return (
    <section className="pages page-bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading">{t('_lottery.windfall.provablyFair.title')}</h1>
            <br />
            <h3 className="mb-3">{section1Title}</h3>
            <p>{section1Paragraph1}</p>
            <br />
            <p>{section1Paragraph2}</p>
            <br />

            <div style={{ paddingLeft: '20px' }}>
              <h5 className="mb-3">{section1_1Title}</h5>
              <p>{section1_1Paragraph1}</p>
              <br />
              <p>{section1_1Paragraph2}</p>
              <br />
            </div>

            <h3 className="mb-3">{section2Title}</h3>
            <p>{section2Paragraph1}</p>
            <br />
            <p>{section2Paragraph2}</p>
            <br />

            <h3 className="mb-3">{section3Title}</h3>
            <p>{section3Paragraph1}</p>
            <br />

            <h3 className="mb-3">{section4Title}</h3>
            <p>{renderedText}</p>
            <br />
            <button
              className={`default-selection-button wager-button`}
              onClick={() => navigate('/windfall/provably-fair-tech')}
            >
              {t('_lottery.windfall.provablyFair.tech-view')}
            </button>            
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProvablyFair;
