import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoogleOAuthProvider, googleLogout, useGoogleLogin } from "@react-oauth/google";
import { images } from "../../helper";
import { api, MessageTypes } from "../utils/Enums";
import { apiMessage, onData } from "../WebSocketProvider";
import { AlertMessageType } from "../elements/AlertMessage"; // Assuming AlertMessageType is defined here

/* 
    client id : 530897386677-88cvossrb2tljjh60s2npum424ndprg1.apps.googleusercontent.com
    client secret : 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
*/

export const GoogleLogin: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId="530897386677-88cvossrb2tljjh60s2npum424ndprg1.apps.googleusercontent.com">
      <GoogleLoginButton />
    </GoogleOAuthProvider>
  );
};

const GoogleLoginButton: React.FC = () => {
  const { t } = useTranslation();
  const [replyId, setReplyId] = useState<number | null>(null);
  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string>("");

  const handleMessage = (data: IData) => {
    if (data.id !== replyId) return;

    setReplyId(null);
    unHook();
  };

  const handleError = (data: IError) => {
    if (data.id !== replyId) return;

    setReplyId(null);
    unHook();

    setMessageType(AlertMessageType.WARNING);
    setMessage(data.reason || "An error occurred");
  };  

  const unHook = () => {
    onData.off(MessageTypes.REPLY, handleMessage);
    onData.off(MessageTypes.ERROR, handleError);
  };

  useEffect(() => {
    googleLogout();

    return () => {
      unHook();
    };
  }, [handleMessage]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const params = {
        type: codeResponse.token_type,
        token: codeResponse.access_token,
      };

      onData.on(MessageTypes.REPLY, handleMessage);
      onData.on(MessageTypes.ERROR, handleMessage);
      setReplyId(apiMessage(api.GOOGLE_LOGIN, params));
    },
    onError: (error) => {
      setMessageType(AlertMessageType.WARNING);
      setMessage(error.error_description || "An error occurred during login");
    }
  });

  const handleClick = () => {
    if (replyId) return;
    login();
  };

  return (
    <div
      onClick={handleClick}
      className={`with_log d-flex align-items-center justify-content-center ${replyId ? "disabled" : ""}`}
      style={{ pointerEvents: replyId ? "none" : "auto" }}
    >
      <span>
        <img src={images.svg_google} alt="Google Logo" />
      </span>
      <span>{t("_google")}</span>
    </div>
  );
};

export default GoogleLogin;
