import React from "react";
import { images } from "../../../../helper";
import PaginationCircle from "../../../elements/PaginationCircle";

const PoolMembers = () => {
  return (
    <div className="pool-members-box container">
      <div className="row">
        <div className="col-xl-6 col-12 my-3">
          <div className="pool-members-profile d-flex justify-content-around">
            <div className="d-flex flex-column  justify-content-center">
              <img src={images.svg_windfall_ellipse} width={100} height={100}
                alt="TODO: Image Title"
                />
            </div>
            <div className="d-flex flex-column  justify-content-around">
              <label>Name Surname</label>
              <p>Private Pool Name</p>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-12 my-3">
          <div className="pool-members-profile d-flex justify-content-around">
            <div className="d-flex flex-column  justify-content-center">
              <img src={images.svg_windfall_ellipse} width={100} height={100} 
                alt="TODO: Image Title"
              />
            </div>
            <div className="d-flex flex-column  justify-content-around">
              <label>Name Surname</label>
              <p>Private Pool Name</p>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-12 my-3">
          <div className="pool-members-profile d-flex justify-content-around">
            <div className="d-flex flex-column  justify-content-center">
              <img 
                src={images.svg_windfall_ellipse} 
                width={100} 
                height={100}                 
                alt="TODO: Image Title"
              />
            </div>
            <div className="d-flex flex-column  justify-content-around">
              <label>Name Surname</label>
              <p>Private Pool Name</p>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-12 my-3">
          <div className="pool-members-profile d-flex justify-content-around">
            <div className="d-flex flex-column  justify-content-center">
              <img src={images.svg_windfall_ellipse} width={100} height={100} 
                alt="TODO: Image Title"
              />
                
            </div>
            <div className="d-flex flex-column  justify-content-around">
              <label>Name Surname</label>
              <p>Private Pool Name</p>
            </div>
          </div>
        </div>
      </div>
      <PaginationCircle className2="page-link-circlewhite" />
    </div>
  );
};

export default PoolMembers;
