import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { images } from "../../helper";
import './Footer.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();
  const copyright = t('_footer.bottomSection.copyright', { year: currentYear });  

  const paths = [
    { path: "/playing-responsibly-matters", text: t("_footer.bottomSection.responsibility") },
    { path: "/not-an-exchange", text: t("_footer.bottomSection.exchange") },
    { path: "/terms-and-conditions", text: t("_footer.bottomSection.terms") },
    { path: "", text: t("_footer.bottomSection.contactUs") }
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-grid">
          <div className="footer-column">
            <h3>{t('_footer.topSection.about.title')}</h3>
              <li className="links li">
                <Link to="/about-us">
                  <img src={images.svg_banner_icon} alt="About Us" /> 
                  {t("_footer.topSection.about.about-us")}
                </Link>
              </li>
              <li className="links li">
                <Link to="/provably-fair-general">
                  <img src={images.svg_ic_calculator_minimalistic} alt="Provably Fair"/>{t('_footer.topSection.about.provably-fair')}
                </Link>
              </li>
          </div>
          <div className="footer-column">
            <h3>{t('_footer.topSection.account.title')}</h3>
            <li className="links li">
              <Link to="/profile/">
                <img src={images.svg_ic_user} alt="Settings"/>
                {t('_footer.topSection.account.settings')}
              </Link>
            </li>
            <li className="links li">
              <Link to="/accounts">
                <img src={images.svg_ic_file_presentation} alt="Accounting"/>
                {t('_footer.topSection.account.accounting')}
              </Link>
            </li>
            <li className="links li">
              <Link to="/logout">
                <img src={images.svg_ic_login_3} alt="Logout"/>
                {t('_footer.topSection.account.logout')}
              </Link>
            </li>
          </div>
          <div className="footer-column">
            <h3>{t('_footer.topSection.help.title')}</h3>
            <li className="links li">
              <Link to="/support">
                <img src={images.svg_ic_help} alt="Support"/>
                {t('_footer.topSection.help.support')}
              </Link>
            </li>
            <li className="links li">
              <Link to="/why-windfall-lottery">
                <img src={images.svg_ic_windfall} alt="Why Windfall Lottery"/>
                {t('_footer.topSection.help.why')}
              </Link>
            </li>
          </div>
          <div className="footer-column">
            <h3>{t('_footer.topSection.legal.title')}</h3>
            <li className="links li">
              <Link to="/privacy-policy">
                <img src={images.svg_ic_shield_user} alt="Privacy Policy"/>
                {t('_footer.topSection.legal.privacy-policy')}
              </Link>
            </li>
            <li className="links li">
              <Link to="/terms-and-conditions">
                <img src={images.svg_ic_clipboard} alt="Terms and Conditions"/>
                {t('_footer.topSection.legal.terms-conditions')}
              </Link>
            </li>
            <li className="links li">
              <Link to="/legal-age-req">
                <img src={images.svg_18plus} alt="Over 18+ Only"/>
                {t('_footer.topSection.legal.18+')}
              </Link>
            </li>
          </div>
        </div>
      </div>
      
      <br />
      <div className="h-line"></div>

      <div className="footer-sub">
        <div className="eplus">
          <img src={images.svg_18plus} alt="18+"/> &nbsp;
          <p>
            <Link className="text-white" to="/legal-age-req">
              {t("_footer.middleSection.18plus_text")}
            </Link>
          </p>
        </div>
        <ul>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/quintogames/">
              <img src={images.svg_ic_fb} alt="Facebook" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/quintogames">
              <img src={images.svg_ic_insta} alt="Instagram" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/quintogames">
              <img src={images.svg_ic_x} alt="Twitter" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/quinto-games">
              <img src={images.svg_ic_linkedin} alt="LinkedIn" />
            </a>
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@quinto_games">
              <img src={images.svg_ic_tiktok} alt="TikTok" />
            </a>
          </li>
        </ul>
      </div>

      <div className="h-line"></div>
      <br />

      <div className="copyright_bar">
        <div className="container">
          <div style={{ flex: "0 0 30%" }}>
            <p>{copyright}</p>
          </div>
          <div style={{ flex: "0 0 70%", textAlign: "right" }}>
            <ul className="list-inline">
              {paths.map((item, index) => (
                <li className="list-inline-item" key={index}>
                  <Link to={item.path}>{item.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
