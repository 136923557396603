export const ROUND_INTERVAL = 12;
export const PREPARING_ROUND_INTERVAL = 3;

export const GAME_STATES = {
  PREPARING: "PREPARING",
  PLAYING: "PLAYING",
  FINALIZING: "FINALIZING",
  PAYOUT: "PAYOUT",
  OFFICIAL: "OFFICIAL",
};

export const LOGIN_TYPE = {
  GOOGLE: "GOOGLE",
  MEMBER: "MEMBER",
};

export const disableScrollTopRoutes = [
  "/profile/ChangeEmail",
  "/profile/ChangePassword",
  "/profile/TwoFA",
  "/profile/ValidateEmail",
  "/profile/ValidatePassword",
  "/profile/Withdrawal",

  "/accounts/AccountBalance",
  "/accounts/FiatBalance",
  "/accounts/crypto/balance",
  "/accounts/crypto",
  "/accounts/crypto/deposit",
  "/accounts/crypto/withdrawal",
  "/accounts/crypto/transfer-funds",

  "/accounts/transactions",
  "/accounts/transactions/recent",
  "/accounts/transactions/pending",
];

export const protectedRoute = ["/profile/*", "/accounts/*"];

