import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { images } from "../../../helper";

export const LegalAgeReq = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="pages page-bg-white">
      <div className="container">
        <div className="row flex-reverse">
          <div className="col-md-8">
            <label className="small_heading">
              {t("_page_legal_age.sub_heading")}
            </label>
            <br />
            <br />
            <h1 className="heading">{t("_page_legal_age.heading")}</h1>

            <br />
            <p>
              <strong>{t("_page_legal_age.sub_heading")}</strong>
            </p>
            <br />

            <ol>
              <li>{t("_page_legal_age.line_1")}</li>
              <li>{t("_page_legal_age.line_2")}</li>
              <li>{t("_page_legal_age.line_3")}</li>
              <li>{t("_page_legal_age.line_4")}</li>
            </ol>
            <br />

            <p>{t("_page_legal_age.line_5")}</p>
            <br />

            <Link className="back-to-doc text-decoration-underline" to="/">
              <img src={images.svg_ic_arrow_left_black} alt="TODO: Image Title"/>
              Back to home page
            </Link>
          </div>

          <div className="col-md-4 text-center">
            <img src={images.svg_18plus_large} alt="TODO: Image Title"/>
          </div>
        </div>
      </div>
    </section>
  );
};
