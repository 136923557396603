import { useTranslation } from "react-i18next";
import { PageHeader } from "./PageHeader";
import { images } from "../../../helper";
import { Link } from "react-router-dom";

export const PlayingResponsiblyMatters = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="pages page-bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <label className="small_heading">
              {t("_page_prm.sub_heading")}
            </label>
            <br />
            <br />
            <h1 className="heading">{t("_page_prm.heading")}</h1>

            <br />
            <p>{t("_page_prm.line_1")}</p>
            <br />

            <p>
              Please consider reaching out to the National Problem Gambling
              Helpline at 1-800-522-4700, or visit{" "}
              <a href="https://www.ncpgambling.org" target="_blank">
                www.ncpgambling.org
              </a>{" "}
              for support and resources.{" "}
            </p>
            <br />

            <p>
              <strong>{t("_page_prm.line_3")}</strong>
            </p>
            <br />

            <Link className="back-to-doc text-decoration-underline" to="/">
              <img src={images.svg_ic_arrow_left_black} alt="TODO: Image Title"/>
              Back to home page
            </Link>
          </div>

          <div className="col-md-3 offset-md-1 text-center">
            <img src={images.svg_img_gamblers_anonymous} alt="TODO: Image Title"/>
          </div>
        </div>
      </div>
    </section>
  );
};
