import React, { useState } from "react";
import { images } from "../../../../helper";
import PoolTicketCompact from "./PoolTicketCompact";
import PoolTicketsCard from "./PoolTicketsCard";
import PaginationNumber from "../../../elements/PaginationNumber";

const PersonalYourTickets = () => {
  const [activeColor, setActiveColor] = useState(1);

  const data = [
    {
      id: 1,
      idid: "U7fj HJ5G",
      player: 200,
      ticket: 100,
      title: "Game #1 Windfall Lottery General Pool",
    },
    {
      id: 2,
      idid: "U7fj HJ5G",
      player: 200,
      ticket: 100,
      title: "",
    },
    {
      id: 3,
      idid: "U7fj HJ5G",
      player: 200,
      ticket: 100,
      title: "",
    },
    {
      id: 4,
      idid: "U7fj HJ5G",
      player: 200,
      ticket: 100,
      title: "Game #1 Windfall Lottery General Pool",
    },
  ];

  const addclass = (id) => {
    document.getElementById(`your_tic${id}`).classList.remove("d-none");
    document.getElementById(`your_tic1${id}`).classList.add("d-none");
  };

  const removeclass = (id) => {
    document.getElementById(`your_tic${id}`).classList.add("d-none");
    document.getElementById(`your_tic1${id}`).classList.remove("d-none");
  };

  return (
    <div className="pools pb-5">
      <div className="pools-column d-flex justify-content-between align-items-center my-4">
        <h1 className="pool-h1 ">Your Tickets (66)</h1>
        <div className="pool-search px-2 mt-2">
          <input placeholder="Search your Pool by ID" />
          <img src={images.svg_windfall_search} 
                alt="TODO: Image Title"
              />
        </div>
      </div>

      {data.map((item, i) => {
        return (
          <div className="pools-container pools-container-bg">
            <div
              className="d-flex justify-content-between align-items-center flex-wrap my-3 mx-5 py-4"
              id={`your_tic1${item.id}`}
            >
              <div className="d-flex justify-content-center flex-wrap  py-2">
                <p className="d-flex">
                  TICKETS ID 5 <div className="circle-status mx-3"></div>
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center  py-2">
                <label>09-12-2023</label>
              </div>
              <div className="py-2">
                <label>mm_dd_00:00</label>
              </div>
              <div
                className="d-flex justify-content-around align-items-center  py-2"
                onClick={() => addclass(item.id)}
              >
                <label className="detail-view">Detail view</label>
                <img src={images.svg_ic_chevron_lw_down} 
                alt="TODO: Image Title"
              />
              </div>
            </div>

            <div className="pools-box my-3 d-none" id={`your_tic${item.id}`}>
              <div className="pools-container pools-container-bg d-flex justify-content-between align-items-center flex-wrap pb-4">
                <div className="d-flex align-items-center py-1">
                  <p>TICKETS ID 5</p>
                </div>
                <div className="py-1">
                  <label>09-12-2023</label>
                </div>
                <div className="py-1">
                  <label>mm_dd_00:00</label>
                </div>
                <div
                  className="d-flex flex-wrap py-1"
                  onClick={() => removeclass(item.id)}
                >
                  <label className="detail-view">Detail view</label>
                  <img src={images.svg_windfall_x_circle} 
                alt="TODO: Image Title"
              />
                </div>
              </div>

              <div className="pool-members">
                <div
                  className="nav nav-tabs d-flex justify-content-center"
                  role="tablist"
                >
                  <div
                    className="nav-item lottery-pool-button my-2"
                    role="presentation"
                  >
                    <a
                      className="active"
                      data-bs-toggle="tab"
                      href="#compact"
                      role="tab"
                      aria-selected="true"
                    >
                      <button
                        className={`${activeColor === 1 ? "button_active" : ""}`}
                        onClick={() => setActiveColor(1)}
                      >
                        Compact
                      </button>
                    </a>
                    <a
                      className=""
                      data-bs-toggle="tab"
                      href="#cards"
                      role="tab"
                      aria-selected="false"
                      tabindex="-1"
                    >
                      <button
                        className={`${activeColor === 2 ? "button_active" : ""}`}
                        onClick={() => setActiveColor(2)}
                      >
                        cards
                      </button>
                    </a>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active pool-members-compact container"
                    id="compact"
                    role="tabpanel"
                  >
                    <PoolTicketCompact />
                  </div>

                  <div
                    className="tab-pane fade pool-members-compact container"
                    id="cards"
                    role="tabpanel"
                  >
                    <PoolTicketsCard />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <PaginationNumber />
    </div>
  );
};

export default PersonalYourTickets;
