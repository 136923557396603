import React from "react";
import { images } from "../../../../helper";
import PaginationCircle from "../../../elements/PaginationCircle";

const PoolTicketsCard = () => {
  return (
    {/*
    <div className="pool-compact-bg">
      <div className="pool-compact">
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4 ">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
        <div className="my-1">
          <div className="compact-id d-flex justify-content-center py-1 mx-4">
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
            <img src={images.svg_windfall_jc} />
          </div>
        </div>
      </div>
      <div className="py-2">
        <PaginationCircle
          className1="page-link-blue"
          className2="page-link-circleblue"
          arrowImage={true}
        />
      </div>
    </div>
  */}
  );
};

export default PoolTicketsCard;
