import React from 'react';
import { LoaderWrapper } from './elements/LoaderWrapper';

const PreLoader: React.FC = () => {
  return (
    <div className="preloader">
      <div className="spinner">
        {/* Use the LoaderWrapper component for the loading animation */}
        <LoaderWrapper />
      </div>
    </div>
  );
};

export default PreLoader;
