import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { db, onData } from "../../WebSocketProvider";
import { MessageTypes, TabTypes } from "../../utils/Enums";
import * as utils from "../../utils/Utils";
import TierButtonManger from "./TierButtonManger";
import GamePlayManager from "./GamePlayManager";
import "./Trimorra.css";
import { ITier } from "./TrimorraBase";
import WagerSelection from "./Wagers";

const Purchasing: React.FC = () => {
  const { t } = useTranslation();

  const [tiers, setTiers] = useState<ITier[]>([]);
  const [maxTier, setMaxTier] = useState<number | null>(4);
  const [selected, setSelected] = useState<number>(0);
  
  useEffect(() => {
    const handleChange = (data: any) => {
      fetchTiers();
    };

    const fetchTiers = () => {
      if (!db) throw new Error("Database instance not available");

      const query = `
      SELECT 
        ord, tier, players
      FROM
        trimorra_tiers
      ORDER BY
        ord;
      `;

      const stmt = db.prepare(query);
      const rows: ITier[] = [];
      let maxOrd: number = 0;

      while (stmt.step()) {
        const row: ITier = stmt.getAsObject() as ITier;
        rows.push(row);
        maxOrd = Math.max(maxOrd, row.ord);
      }
      stmt.free();

      setTiers(rows);
      setMaxTier(maxOrd);
      console.log("TrimorraTierSelection: tiers", tiers);

      if (rows.length > 0) {
        const ndx: number = Math.min(utils.readParams(TabTypes.TRIMORRA, "TIER", 0), rows.length - 1);
        console.log("Selected Tier", ndx);
        setSelected(ndx);
      }      
    };

    fetchTiers();

    onData.on(MessageTypes.TRIMORRA_TIERS, handleChange);

    return () => {
      onData.off(MessageTypes.TRIMORRA_TIERS, handleChange);
    }
  }, []);

  
  const handleTierClick = (tier: ITier) => {
    if (tier.ord === selected) return;

    setSelected(tier.ord);
    utils.writeParams(TabTypes.TRIMORRA, "TIER", tier.ord);
    console.log(`Tier Changed to ${tier.tier}…`, tier);
  };

  return (
    <div className="trimorra-selection">
      <div className="trimorra-title">
        <h3>{t("_trimorra.tier-selection")}</h3>
      </div>        
      <div className="trimorra-buttons">
        {tiers.map((tier) => (
          <TierButtonManger
            key={tier.tier}
            tier={tier}
            onClick={handleTierClick}
            isSelected={selected === tier.ord}
          />
        ))}
      </div>
      <hr className="section-separator" />
      {tiers.length > 0 && <WagerSelection tier={tiers[selected]} />}
      {utils.isAuthenticated() && (
        <div className="game-play-manager-section">
          <GamePlayManager />
        </div>
      )}
    </div>    
  );
};

export default Purchasing;
