import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import "./TranslationStatus.css";

interface TranslationStatusModalProps {
  show: boolean;
  handleClose: () => void;
}

const TranslationStatusModal: React.FC<TranslationStatusModalProps> = ({ show, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton style={{ borderBottom: 'none' }}>
        <Modal.Title>{t('translation_status.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('translation_status.message')}
      </Modal.Body>
    </Modal>
  );
};

export default TranslationStatusModal;
