import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { apiMessage } from "../../WebSocketProvider";
import { api } from "../../utils/Enums";
import GamePlay from "./GamePlay";
import { IGame, GameStateTypes, getTierName } from "./TrimorraBase";
import QSymbol from "../../utils/QSymbol";
import { formatValue } from "../../utils/Round";
import "./Trimorra.css"; // Importing the CSS file

interface GameContainerProps {
  game: IGame;
  onGameComplete: (completedGame: IGame) => void;
}

const GameContainer: React.FC<GameContainerProps> = ({ game, onGameComplete }) => {
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState(false);
  const [currentGame, setCurrentGame] = useState(game);

  const localTime = moment.utc(currentGame.when).local().format('D MMM / HH:mm:ss.SSS');

  useEffect(() => {
    if (currentGame.state !== game.state) {
      setCurrentGame(game);
    }
  }, [game]);

  useEffect(() => {
    console.log('game.state:', game.state);
    console.log('currentGame.state:', currentGame.state);
  }, [game, currentGame]);

  const handleGameComplete = (completedGame: IGame) => {
    setConfirm(true);
    apiMessage(api.TRIMORRA_PAYOUT, { game_id: completedGame.id });
    // Call the passed-in onGameComplete function after displaying the seed
    onGameComplete(completedGame);
  };

  const handleStartGame = () => {
    setCurrentGame((prevGame) => ({
      ...prevGame,
      state: GameStateTypes.PLAYING
    }));
  };

  return (
    <div className="trimorra-selection game-container">
      <div className="game-header">
        {currentGame.state === GameStateTypes.START ? (
          <>
            <div className="header-left">
              <h3>
                {getTierName(currentGame.room.tier.ord, t)} — {currentGame.room.buyin === 0 ? t('_common.free') : <QSymbol value={formatValue(currentGame.room.buyin, currentGame.room.buyin < 100 ? 2 : 0)} />}
              </h3>
              <p className="game-time">{localTime}</p>
            </div>
            <div className="header-right">
              <button
                className="default-selection-button start-game-button"
                onClick={handleStartGame}>
                {t('_trimorra.games.start')}
              </button>
            </div>
          </>
        ) : (
          <>
            <h3>{t('_trimorra.common.game-id')}: {currentGame.id}</h3>
            <p>
              <h3>
                {getTierName(currentGame.room.tier.ord, t)} — {currentGame.room.buyin === 0 ? t('_common.free') : <QSymbol value={formatValue(currentGame.room.buyin, currentGame.room.buyin < 100 ? 2 : 0)} />}
              </h3>
            </p>
          </>
        )}
      </div>
      {currentGame.state !== GameStateTypes.START && (
        <div className="game-details">
          <div className="game-details-left">
            <p className="game-seed">{t('_trimorra.games.seed')}: {currentGame.seed}</p>
          </div>
          <div className="game-details-right">
            <p className="game-time">{localTime}</p>
          </div>
        </div>
      )}
      {currentGame.state !== GameStateTypes.START && (
        <GamePlay game={currentGame} onGameComplete={handleGameComplete} />
      )}
    </div>
  );
  
};

export default GameContainer;
