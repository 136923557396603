import React, { useState, useEffect } from 'react';
import './Paginations.css';

interface PageNumberPaginationProps {
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
  theme: string;
}

const PageNumberPagination: React.FC<PageNumberPaginationProps> = ({ totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    if (endPage - startPage + 1 < maxPageNumbersToShow) {
      startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("…");
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("…");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((pageNumber, index) => (
      <button
        key={index}
        onClick={() => handlePageChange(Number(pageNumber))}
        className={`rounded-selection ${currentPage === pageNumber ? 'active' : ''}`}
        disabled={pageNumber === "…"}
      >
        {pageNumber}
      </button>
    ));
  };

  return (
    <div className="page-number-pagination">
      <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>&laquo;</button>
      <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>&lsaquo;</button>
      {renderPageNumbers()}
      <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>&rsaquo;</button>
      <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>&raquo;</button>
    </div>
  );
};

export default PageNumberPagination;
