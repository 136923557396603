import React from "react";
import { images } from "../../helper";

const PaginationNumber = () => {
  return (
    <ul className="pagination">
      <li className="page-item">
        <a // onClick={(e)=>changePageToStart()}
          className="page-link"
        >
          <img src={images.svg_left_arrow_white} alt="TODO: Image Title"/>
          <img src={images.svg_left_arrow_white} alt="TODO: Image Title"/>
        </a>
      </li>
      <li className="page-item">
        <a // onClick={(e)=>changePageTo(page-1)}
          className="page-link"
        >
          <img src={images.svg_left_arrow_white} alt="TODO: Image Title"/>
        </a>
      </li>

      {/* {Array.apply(0, Array(totalPages)).map((x, i) =>{
  if(showPaginationLess(i+1))
  return <li className={`page-item`}>
    <a onClick={(e)=>changePageTo(i+1)} className={`page-link ${page==i+1 ? "active" : ""}`}>{i+1}</a>
  </li>
  })} */}
      <li className={`page-item`}>
        <a className={`page-link active`}>1</a>
      </li>

      <li className="page-item">
        <a // onClick={(e)=>changePageTo(page+1)}
          className="page-link"
        >
          <img src={images.svg_right_arrow_white} alt="TODO: Image Title"/>
        </a>
      </li>
      <li className="page-item">
        <a // onClick={(e)=>changePageToEnd()}
          className="page-link"
        >
          <img src={images.svg_right_arrow_white} alt="TODO: Image Title"/>
          <img src={images.svg_right_arrow_white} alt="TODO: Image Title"/>
        </a>
      </li>
    </ul>
  );
};

export default PaginationNumber;
