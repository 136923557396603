import React from "react";
import { useTranslation, Trans } from "react-i18next";
import QButton from "../../elements/QButton";
import { ITier, getTierName } from "./TrimorraBase";
import "./Trimorra.css"; // Import the consolidated CSS file

interface ITierButtonProps {
  tier: ITier;
  isSelected: boolean;
  onClick: (tier: ITier) => void;
}

const TierButton: React.FC<ITierButtonProps> = ({ tier, onClick, isSelected }) => {
  const { t } = useTranslation();

  return (
    <button
      className={`default-selection-button tier-button ${isSelected ? "selected" : ""}`}
      onClick={() => onClick(tier)}
    >
      <div className="tier-info">
        <p className="tier-button-title">{getTierName(tier.ord, t)}</p>
        <label className="tier-button-label">
          <Trans
            i18nKey="_trimorra.player_game"
            values={{ qty: tier.players }}
          />
        </label>
      </div>
    </button>
  );
};

export default React.memo(TierButton);

