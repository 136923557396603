import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Decimal from 'decimal.js';
import { db } from "../../WebSocketProvider";
import { roundDown, formatValue, roundUp } from "../../utils/Round";
import { IRoom } from './TrimorraBase';
import './Trimorra.css';

interface IPayoutTableProps {
  room: IRoom;
  round?: Integer; // Optional property for the current round
}

interface IPayoutTable {
  round: string;
  winners: number;
  payout: number;
  paysum: number;
}

interface IPayout {
  round: string;
  winners: number;
  winnings: string;
  payoutSum: string;
}

const PayoutTable: React.FC<IPayoutTableProps> = ({ room, round }) => {
  const { t } = useTranslation();
  const [payouts, setPayouts] = useState<IPayout[]>([]);

  useEffect(() => {
    const calculatePayouts = () => {
      if (!db) throw new Error("Database instance not available");

      const query = `
        WITH Payouts AS (
          SELECT 
            round, 
            winners, 
            IFNULL(units, 0) AS units,
            (IFNULL(units, 0) * $cost) AS payout
          FROM
            trimorra_payouts
          WHERE
            tier = $tier
        ),
        AdjustedPayouts AS (
          SELECT
            round,
            winners,
            CASE
              WHEN units = 0 THEN ($aggregate - IFNULL((SELECT SUM(winners * units) * $cost FROM trimorra_payouts WHERE tier = $tier AND units IS NOT NULL), 0))
              ELSE payout
            END AS payout
          FROM
            Payouts
        )
        SELECT 
          round, 
          winners, 
          payout,
          SUM(payout) OVER (ORDER BY winners DESC) AS paysum
        FROM
          AdjustedPayouts
        ORDER BY
          winners DESC;
    `;
      const rows: IPayout[] = [];
      setPayouts([]);  // Clear the payouts array      

      const final_precision = roundDown(room.aggregate, 2) === room.aggregate ? 2 : 3;
      const stmt = db.prepare(query);
      stmt.bind({ $cost: (room.buyin == 0 ? 0.01 : room.buyin), $tier: room.tier.tier, $aggregate: room.aggregate });
      while (stmt.step()) {
        const data: IPayoutTable = stmt.getAsObject() as IPayoutTable;
        const precision = data.round.toUpperCase() === 'FINAL' ? final_precision : 2;

        const row: IPayout = {
          round: data.round,
          winners: data.winners,
          winnings: formatValue(data.payout, precision),
          payoutSum: formatValue(data.paysum, precision),
        };
        rows.push(row);
      }
      stmt.free();

      setPayouts(rows);
    };

    calculatePayouts();
  }, [room]);

  return (
    <div className="trimorra-payout-container">
      {round === undefined && (
        <h3>{t('_trimorra.payouts.title')}</h3>
      )}
      <table>
        <thead>
          <tr>
            <th>{t('')}</th>
            {round === undefined && (
              <th className="center-align">{t('_trimorra.payouts.columns.winners')}</th>
            )}
            <th className="right-align">{t('_trimorra.payouts.columns.payout')}</th>
            <th className="right-align">{t('_trimorra.payouts.columns.sum')}</th>
          </tr>
        </thead>
        <tbody>
          {payouts.map((payout, index) => {
            const shouldHighlight = Number(round) === (index + 1);
            return (
              <tr key={index} className={shouldHighlight ? 'highlight' : ''}>
                <td className="center-align">{t(`_trimorra.payouts.rounds.${payout.round}`)}</td>
                {round === undefined && (
                  <td className="center-align">{payout.winners}</td>
                )}
                <td className="right-align">{payout.winnings}</td>
                <td className="right-align">{payout.payoutSum}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PayoutTable;
