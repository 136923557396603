import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { images } from "../../helper";
import { KlaviyoSignUpForm } from "../elements/KlaviyoSignUpForm";
import JackpotAnimator, { IJackpotAnimator } from "../elements/JackpotAnimator";
import { CasinoBanner } from "../inc/CasinoBanner";
import { MessageTypes, TabTypes } from "../utils/Enums";
import { Link } from "react-router-dom";
import { db, onData } from "../WebSocketProvider";
import QSymbol from "../utils/QSymbol";
import { formatValue } from "../utils/Round";
import TrackPage from "../inc/TrackPage";
import { getTabRefId } from "../utils/Utils";

const Home: React.FC = () => {
  TrackPage();
  const { t } = useTranslation();

  const [remaining, setRemaining] = useState<IJackpotAnimator>({
    init: {
      value: 0,       // Initial value
      precision: 0,   // Number of decimal points
      symbol: false,  // Display currency symbol
      time: 7.50      // Time to achieve the target value in seconds
    },
    target: 0         // Target value to reach
  });

  let windfallDefId: string | null = null;

  useEffect(() => {
    const handleLotteryData = (data: JsonObject) => {
      if (!windfallDefId)
        throw new Error("Windfall Lottery defId is invalid");
      else if ((data.def_id !== windfallDefId)) 
        return;

      setRemaining((prevProps) => ({
        ...prevProps,
        target: data.remaining,
      }));      
    };

    const initialize = () => {
      if (!db) throw new Error("Database instance not available");

      windfallDefId = getTabRefId(TabTypes.WINDFALL, TabTypes.LOTTERY);
      console.log(`Home.tsx: initialize.windfallDefId(${windfallDefId})`);
      
      if (!windfallDefId) {
        setTimeout(initialize, 150);
        return;
      }

      const query = `
        SELECT 
          remaining
        FROM
          lottery_games
        WHERE
          def_id = ?;
      `;
      const stmt = db.prepare(query);
      const row = stmt.getAsObject([windfallDefId]);
      stmt.free();

      setRemaining((prevProps) => ({
        ...prevProps,
        init: {
          ...prevProps.init,
          value: row.remaining,
        },
        target: row.remaining,
      }));          

      onData.on(MessageTypes.LOTTERY_GAME, handleLotteryData);
    };

    initialize();

    return () => {
      onData.off(MessageTypes.LOTTERY_GAME, handleLotteryData);
    };
  }, []);

  return (
    <>
      <section className="home-section">
        <div className="container">
          <div className="intro-section">
            <div className="text-center">
              <img className="home-q-logo" src={images.svg_h_logo_large} alt="Home Logo" />
              <br />
              <img className="home-bgf" src={images.svg_wl_tagline} alt="Tagline" />
              <h1>TRIMORRA IS OPEN</h1>
            </div>
          </div>
        </div>

        <div className="home-section-sticky">
          <div className="row">
            <div className="col-md-6">
              <div className="left-section">
                <img
                  className="windfall-img"
                  src={images.svg_windfall_lp}
                  alt="windfall lottery"
                />
                <div className="text-center pb-3">
                  <h3>
                    <Trans i18nKey="_lottery.windfall.est_jackpot_winner" 
                      components={{ 
                        amount: <QSymbol value={formatValue(15000000, 0)} />
                      }}
                    />                
                  </h3>
                  <h3>
                    <Trans i18nKey="_lottery.windfall.ten_winners" 
                      components={{ 
                        amount: <QSymbol value={formatValue(1000000, 0)} />
                      }}
                    />                
                  </h3>
                  <h3>
                    <Trans i18nKey="_lottery.windfall.secondary_winners" 
                      components={{
                        winners: <span>109</span>,
                        amount: <QSymbol value={formatValue(50000, 0)}/>
                      }}
                    />                
                  </h3>
                  <br/>
                  <small>
                    <Trans i18nKey="_lottery.windfall.jackpot_description" components={{ amount: <QSymbol value={formatValue(52000000, 0)} /> }} />
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>  

        <div className="container">
          <div className="row">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <div className="right-section">
                <div className="counter_box_right">
                  <div className="ticket_pre_sale">
                    <div>
                      <img src={images.svg_presale_button} alt="" />
                    </div>
                    <span className="ticket_text">{t("_lottery.windfall.presale_tickets")}</span>
                  </div>
                  <div className="right_ticket_box_main">
                    <div className="right_ticket_box">
                      <JackpotAnimator {...remaining} />
                      <h3>{t("_lottery.windfall.remaining_tickets").toUpperCase()}</h3>
                      <Link to="/presale" className="banner_btn_play_now">
                        <img src={images.svg_login_button} alt="" /> &nbsp;
                        <span>{t("_common.play_now")}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-section-sticky-mobile">
          <div className="container">
            <div className="counter_box_right">
              <div className="ticket_pre_sale">
                <div>
                  <img src={images.svg_presale_button} alt="" />
                </div>
                <span className="ticket_text">Ticket Pre-Sale</span>
              </div>
              <div className="right_ticket_box_main">
                <div className="right_ticket_box">
                  let display = "LOADING"; if (remaining) display = utils.formatValue(remaining, 0);
                  <JackpotAnimator {...remaining} />
                  <h3>{t("_lottery.windfall.remaining_tickets").toUpperCase()}</h3>
                  <Link to="/presale" className="banner_btn_play_now">
                    <img src={images.svg_login_button} alt="" /> &nbsp;
                    <span>Play Now!</span>
                  </Link>
                </div>
              </div>

              <div className="home-winners-section">
                <img
                  className="windfall-img"
                  src={images.svg_windfall_lp}
                  alt="windfall lottery"
                />
                <br />
                <br />
                <div className="text-center pb-3">
                  <h3>
                    <span className="q-symbol"></span>15,000,000 Estimated Jackpot Winner*
                  </h3>
                  <h3>
                    TEN <span className="q-symbol"></span>1,000,000 Winners
                  </h3>
                  <h3>
                    109 Winners of <span className="q-symbol"></span>50,000 or more
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="active_main">
        <div className="container spacerT">
          <div className="row">
            <div className="col-12">
              <h2 className="main_title text-center py-2">UPCOMING GAMES</h2>
            </div>
          </div>
          <div className="game-goxes">
            <div className="game-item">
              <div className="img_active_main">
                <img
                  className="img"
                  src={images.svg_windfall_lp}
                  alt="Windfall Lottery"
                />
              </div>
              <h4>WINDFALL LOTTERY&#8482;</h4>
              <Link to="/presale" className="active_game_btn">
                <img src={images.svg_play_icon} alt="Play Now!" /> Play Now!
              </Link>
            </div>

            <div className="game-item">
              <div className="img_active_main">
                <img
                  className="img"
                  src={images.svg_ic_game_trimorra}
                  alt="Trimorra"
                />
              </div>
              <h4>TRIMORRA&#8482;</h4>
              <Link to="/presale" className="active_game_btn">
                <img src={images.svg_play_icon} alt="Play Now!" /> Play Now!
              </Link>
            </div>

            <div className="game-item">
              <div className="img_active_main">
                <img
                  style={{ height: "130px" }}
                  className="img-fluid"
                  src={images.png_ic_game_3up_bingo}
                  alt="3up Bingo"
                />
              </div>
              <h4>3UP BINGO&#8482;</h4>
              <button className="active_game_btn">Coming Soon!</button>
            </div>
          </div>

          <div className="row justify-content-center pb-5">
            <div className="col-lg-8">
              <p className="main_blue_color">
                Check out our latest featured game! To meet today's challenges & earn cryptocurrency. Top 10 players receive prizes every hour!
              </p>
            </div>
          </div>
        </div>
      </section>

      <KlaviyoSignUpForm />
      <CasinoBanner />
    </>
  );
};

export default Home;
