import React, { useEffect, useState, ReactNode, ReactElement, isValidElement } from "react";
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from "react-i18next";
import PageNumberPagination from "./PageNumberPagination";
import "./Paginations.css";

/**
 * Props for the Paginations component
 */
interface PaginationsProps<T> {
  data: T[]; // Array of data to paginate
  header?: string; // Optional header text for the table
  className?: string; // Optional CSS class name
  tableHeaders?: ReactElement | null; // React element for table headers, optional
  jsxData: (data: T[]) => React.ReactNode; // Function to render paginated data

  recordsPerPage: number; // Number of records to display per page
  useCircles?: boolean; // Option to use circles for pagination instead of page numbers
  dotPaginationThreshold?: number; // Threshold to switch to dot pagination

  leftArrow?: string; // Optional left arrow icon for pagination
  rightArrow?: string; // Optional right arrow icon for pagination
  theme?: "dark" | "light"; // Theme for the pagination, default is light

  onPageChange?: (page: number) => void; // Callback function when the page changes
};

/**
 * Paginations Component
 * Provides pagination functionality for displaying data in a table format.
 *
 * @param {PaginationsProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered Paginations component.
 */
function Paginations<T>({
  data,
  header,  
  className,
  tableHeaders = null, // Default to null if not provided  
  jsxData,

  recordsPerPage,
  useCircles = false,
  dotPaginationThreshold = 10,

  leftArrow,
  rightArrow,
  theme = "light",

  onPageChange,
 
}: PaginationsProps<T>): JSX.Element {
  const { t } = useTranslation();

  // State to manage the current page number
  const [page, setPage] = useState<number>(1);
  
  // State to manage the total number of pages
  const [totalPages, setTotalPages] = useState<number>(
    Math.ceil(data.length / recordsPerPage)
  );

  // Update total pages whenever data or recordsPerPage change
  useEffect(() => {
    setTotalPages(Math.ceil(data.length / recordsPerPage));
  }, [data, recordsPerPage]);

  // Call onPageChange callback whenever the page changes
  useEffect(() => {
    if (onPageChange) onPageChange(page);
  }, [page, onPageChange]);

  useEffect(() => {
//    console.log(`Pagination JSX…`, ReactDOMServer.renderToStaticMarkup(renderedTable));
  });

  /**
   * Handles page change
   * @param {number} pg - The new page number
   */
  const handlePageChange = (pg: number): void => {
    if (pg < 1 || pg > totalPages) return;
    setPage(pg);
  };

  // Calculate start and end indices for the current page
  const startIdx: number = (page - 1) * recordsPerPage;
  const endIdx: number = startIdx + recordsPerPage;
  const paginatedData: T[] = data.slice(startIdx, endIdx);

  /**
   * Renders the pagination controls
   * @returns {ReactNode | null} The pagination controls or null if not needed
   */
  const renderPagination = (): ReactNode => {
    if (totalPages <= 1) {
      return null; // No pagination needed for a single page
    }

    return (
      <PageNumberPagination
        totalItems={data.length}
        itemsPerPage={recordsPerPage}
        onPageChange={handlePageChange}
        theme={theme} // Pass the theme prop
      />
    );
  };

  // Extract number of columns from tableHeaders if available
  let numberOfColumns = 1;
  if (isValidElement(tableHeaders)) {
    const children = (tableHeaders as ReactElement<any>).props.children;
    if (children && Array.isArray(children)) {
      numberOfColumns = children.length;
    } else if (children) {
      numberOfColumns = 1;
    }
  }

  const renderedTable = (
    <>
      <table className={className}>
        {tableHeaders && (
          <thead>
            {tableHeaders}
          </thead>
        )}
        <tbody>
          {data.length > 0 ? (
            jsxData(paginatedData)
          ) : (
            <tr>
              <td className="text-center" colSpan={numberOfColumns}>
                {t("_common.no_data")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {renderPagination()}
    </>
  );

//  console.log(ReactDOMServer.renderToStaticMarkup(renderedTable));

  return renderedTable;
}

export default Paginations;
