import React, { useEffect } from "react";
import Select, { SingleValue } from "react-select";
import moment from 'moment';
import { images } from "../../helper";
import "./Selector.css";

interface SelectorCryptoAddressProps {
  label: string;
  className?: string;
  onSelect?: (address: ICryptoAddress) => void;
  preSelect?: ICryptoAddress;
  addresses: ICryptoAddress[];
  onToggle?: (isOpen: boolean) => void;
  accountType: "DEPOSIT" | "WITHDRAWAL";
}

export const SelectorCryptoAddress: React.FC<SelectorCryptoAddressProps> = ({
  label,
  className,
  onSelect,
  preSelect,
  addresses,
  onToggle,
  accountType,
}) => {

  const [selected, setSelected] = React.useState<ICryptoAddress | undefined>(preSelect);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  useEffect(() => {
    console.log("Initial props: ", { label, className, preSelect, addresses, accountType });
    setSelected(preSelect);
  }, [preSelect]);

  const handleSelectChange = (newValue: SingleValue<ICryptoAddress>) => {
    console.log("handleSelectChange triggered with:", newValue); // Debugging log
    if (newValue) {
      setSelected(newValue);
      if (onSelect) {
        console.log("Calling onSelect with newValue:", newValue);
        onSelect(newValue);
      }
    } else {
      setSelected(undefined);
    }
    setIsOpen(false); // Close the dropdown after selection
  };

  const handleExpandCollapse = () => {
    console.log("handleExpandCollapse triggered. Current state:", isOpen); // Debugging log
    setIsOpen(!isOpen);
    if (onToggle) {
      console.log("Calling onToggle with state:", !isOpen);
      onToggle(!isOpen);
    }
  };

  const customSelected = ({ data }: { data: ICryptoAddress }) => (
    <div className={`selector disabled`}>
      <div className="selector-item selector-address-text">
        <span className="address-left">{data.address}</span>
        {accountType === 'DEPOSIT' && (
          <span className="selector-item-right">
            {data.lastUsed ? moment.utc(data.lastUsed, 'YYYY-MMM-DD HH:mm:ss').local().format('D MMM YYYY / HH:mm:ss') : 'Unused'}
          </span>
        )}
        {accountType === 'WITHDRAWAL' && data.qrcode && (
          <span className="selector-item-right">
            {data.qrcode}
          </span>
        )}
      </div>
    </div>
  );

  // qrCode is overload as the Nickname of the address  
  const customSingleValue = ({ data }: { data: ICryptoAddress }) => (
    <div className={`selector selected`}>
      <div className="selector-item selector-address-text">
        <span className="address-left">{data.address}</span>
        {accountType === 'DEPOSIT' && (
          <span className="selector-item-right">
            {data.lastUsed ? moment.utc(data.lastUsed, 'YYYY-MMM-DD HH:mm:ss').local().format('D MMM YYYY / HH:mm:ss') : 'Unused'}
          </span>
        )}
        {accountType === 'WITHDRAWAL' && data.qrcode && (
          <span className="selector-item-right">
            {data.qrcode}
          </span>
        )}
      </div>
    </div>
  );

  const customOption = ({ data }: { data: ICryptoAddress }) => (
    <div className={`selector ${selected === data ? 'selected' : ''}`} onClick={() => handleSelectChange(data)}>
      <div className="selector-item selector-address-text">
        <span className="address-left">{data.address}</span>
        {accountType === 'DEPOSIT' && (
          <span className="selector-item-right">
            {data.lastUsed ? moment.utc(data.lastUsed, 'YYYY-MMM-DD HH:mm:ss').local().format('D MMM YYYY / HH:mm:ss') : 'Unused'}
          </span>
        )}
        {accountType === 'WITHDRAWAL' && data.qrcode && (
          <span className="selector-item-right">
            {data.qrcode}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div className="selector-container">
      <div  className="selector-expander" onClick={handleExpandCollapse}>
        <label>{label || "Select"}</label>
        <div className="icon">
          <img src={isOpen ? images.svg_ic_chevron_up : images.svg_ic_chevron_down} alt="toggle" />
        </div>
      </div>
  
      {!isOpen && selected && customSelected({ data: selected })}
  
      <Select
        className={`qselect ${isOpen ? "qselect-active" : ""}`}
        defaultMenuIsOpen={isOpen}
        menuIsOpen={isOpen}
        options={addresses}
        defaultValue={selected}
        value={selected}
        onChange={(e) => {
          console.log("onChange event triggered with:", e); // Debugging log
          handleSelectChange(e as SingleValue<ICryptoAddress>);
        }}
        getOptionLabel={(address: ICryptoAddress) => address.address}
        getOptionValue={(address: ICryptoAddress) => address.address}
        components={{ SingleValue: customSingleValue, Option: customOption }}
        isClearable
      />
    </div>
  );
};

export default SelectorCryptoAddress;
