import { useTranslation } from "react-i18next";
import { PageHeader } from "./PageHeader";
import { Link } from "react-router-dom";
import { QPlayNow } from "./QPlayNow";
import { images } from "../../../helper";
import QSymbol from "../../utils/QSymbol";
import { formatValue } from "../../utils/Round";

const LotteryPools = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="pages page-bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading">About Lottery Pools</h1>
            <br />
            <br />
            <p>
              At Quinto Games, we believe that playing the lottery should be not
              only exciting but also accessible and rewarding for everyone.
              That's why we offer an opportunity for you to maximize your
              chances of winning while keeping your costs in check through our
              carefully managed lottery pools.
            </p>

            <br />
            <h4>How Our Lottery Pools Work:</h4>
            <br />

            <p>
              <strong>Increased Chances of Winning:</strong> Our lottery pools
              allow you to join forces with other participants to collectively
              purchase more lottery tickets. This means that your chances of
              hitting the jackpot or any prize increase significantly without
              having to spend more from your own pocket.
            </p>
            <br />
            <p>
              <strong>Expert Management:</strong> At Quinto Games, we take pride
              in expertly managing every aspect of the lottery pool. From
              organizing contributions to buying tickets and handling the
              payouts, you can trust us to ensure a seamless and fair experience
              for all participants..
            </p>
            <br />
            <p>
              <strong>Public and Private Pools:</strong> We offer both public
              and private lottery pools to cater to your preferences. Public
              pools are open to anyone looking to join, making it an excellent
              option for a wide range of players. For those who prefer
              exclusivity, private pools can be created with a password, so you
              can play with your friends, family, or your chosen circle.
            </p>
            <br />
            <p>
              <strong>Fair Distribution:</strong> We employ a payout system
              designed to ensure every participant receives their fair share of
              the winnings, proportionate to their contribution. No one gets
              left out or overlooked; it's a true team effort.
            </p>
            <br />
            <p>
              <strong> Affordable Entry:</strong> Starting your lottery pool is
              both easy and cost-effective. There is a minimal fee to create a
              lottery pool of just <QSymbol value={formatValue(2.50)}/> for the 
              Windfall Lottery, and the price of a single pick for other lottery
              games (not all lottery allow pools). Importantly, this fee is
              reimbursed to the pool's creator from any winnings. This ensures
              that your collective success isn't offset by additional costs,
              making it a smart and economical way to play the lottery with
              others.
            </p>
            <br />

            <p>
              <strong>The Trade-off:</strong> It's essential to understand the
              trade-off involved in joining a lottery pool. While your chances
              of winning significantly increase, individual payouts decrease.
            </p>
            <br />
            <p>
              <strong>Accessibility and Inclusivity:</strong> Our lottery pools
              are not limited to a specific setting like offices. They are
              accessible to everyone. Friends, family, neighbors, and diverse
              groups can come together to chase those elusive life-changing
              prizes.
            </p>
            <br />
            <p>
              Join or create a lottery pool today. It's not just about winning;
              it's about sharing the excitement and rewards with others who
              share your dreams.
            </p>
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LotteryPools;
