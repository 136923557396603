import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { apiMessage } from "../../WebSocketProvider";
import { TabTypes, api } from "../../utils/Enums";
import * as utils from "../../utils/Utils";

import { ViewTypes } from "./TrimorraBase";
import Purchasing from "./Purchasing";
import GameHistory from "./GameHistory";
import "./Trimorra.css";

const ViewSelector: React.FC = () => {
  const { t } = useTranslation();  
  const [activeScreen, setActiveScreen] = useState<ViewTypes>(ViewTypes.HISTORY);

  useEffect(() => {
    let enumKey: string = ViewTypes.HISTORY.toString();
    console.log(`TrimorraContentContainer Default View: ${enumKey}`);

    enumKey = utils.readParams(TabTypes.TRIMORRA, "view", enumKey);
    console.log(`TrimorraContentContainer Initial View: ${enumKey}`);

    const view = utils.enumValueOf(ViewTypes, enumKey) as ViewTypes;
    if (view) {
      setActiveScreen(view);
    }
  }, []);
  const toggleScreen = (view: ViewTypes) => {
    setActiveScreen(view);
    const enumKey: string = view.toString();
    console.log(`TrimorraContentContainer writeParam: ${enumKey}`);
    utils.writeParams(TabTypes.TRIMORRA, "view", enumKey);
  };

  
  return (
    <div className="view-container">
      <div className="view-button-container">
        <button
          className={`default-selection-button view-button ${activeScreen === ViewTypes.HISTORY ? "selected" : ""}`}
          onClick={() => toggleScreen(ViewTypes.HISTORY)}
        >
          {t("_trimorra.view.history")}
        </button>
        <button
          className={`default-selection-button view-button ${activeScreen === ViewTypes.PURCHASE ? "selected" : ""}`}
          onClick={() => toggleScreen(ViewTypes.PURCHASE)}
        >
          {t("_trimorra.view.purchase")}
        </button>
      </div>
{/*
{/* 
      {activeScreen === TrimorraViewTypes.HISTORY && <TrimorraGameHistory />}
      {activeScreen === TrimorraViewTypes.PURCHASE && <TrimorraTierSelection />}
  */}      
      {activeScreen === ViewTypes.HISTORY && <GameHistory />}  
      {activeScreen === ViewTypes.PURCHASE && <Purchasing />}
    </div>
  );
};

export default ViewSelector;
