import React, { useEffect, useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { apiMessage, onData } from "../WebSocketProvider";
import AlertMessage, { AlertMessageType } from "../elements/AlertMessage";
import PasswordElement from "../elements/PasswordElement";
import QButton from "../elements/QButton";
import { MessageTypes, api } from "../utils/Enums";
import { is2FA } from "../utils/Utils";
import { QCardHeaders } from "./QCardHeaders";
import TwoFAElement from "../elements/TwoFAElement";

interface ReplyData {
  id: number;
}

const ChangeEmail: React.FC = () => {
  const { t } = useTranslation();

  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string | null>('');

  const [replyId, setReplyId] = useState<number | null>(null);

  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);

  useEffect(() => {
    const handleError = (data: IError) => {
      if (replyId !== data.id) return;

      setReplyId(null);
      setMessageType(AlertMessageType.WARNING);
      setMessage(data.reason);
    };

    onData.on(MessageTypes.SITE, handleChange);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      onData.off(MessageTypes.SITE, handleChange);
      onData.off(MessageTypes.ERROR, handleError);
    };
  }, [replyId]);

  const handleEmail = (value: string) => {
    if (value) setEmail(value);
    else setMessage("Please enter new email address.");
  };

  const handleChange = (data: IData) => {
    if (data.id === replyId) {
      setReplyId(null);
      setEmail(null);
      setPassword(null);
      setCode(null);
    }
  };

  const handlePassword = (value: string) => {
    if (value) setPassword(value);
    else setMessage("Please enter your password");
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage(null);

    if (!email) {
      setMessage("Please enter new email address");
      return;
    }

    if (!password) {
      setMessage("Please enter password");
      return;
    }

    if (is2FA() && !code) {
      setMessage("Please enter 2FA code");
      return;
    }

    const params: { email: string; password: string; code?: string } = {
      email,
      password,
    };
    if (code) params.code = code;

    setReplyId(apiMessage(api.CHANGE_EMAIL, params));
  };

  return (
    <div className="card user-account-tabs-card">
      <div className="card-body">
        <QCardHeaders
          title="CHANGE EMAIL"
          subTitle="Email Address Update"
          desc="Please make sure your email address is current."
        />

        <div className="card-content">
          <div className="col-xl-5 col-lg-6 col-md-12 flex-column justify-content-center mb-5 mb-lg-0">          
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
              className="text-center"
            >
              <input
                autoComplete="off"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder={t("_profile.section.changeEmail.inputs.new")}
              />
              <br />
                          
              <PasswordElement
                onPasswordChange={handlePassword}
                className="login_field"
                placeholder={t("_login.input.password")}
              />                        

              <TwoFAElement
                value={code || ''}
                onInput={(value) => setCode(value)}
              />

              <AlertMessage
                className="mt-4"
                type={messageType}
                message={message ? message : ''}
              />

              <QButton
                className={`default-selection-button request-button`}            
                submit 
                block 
                text={t("_profile.btn.changeEmail")} 
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmail;
