import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Decimal from 'decimal.js';
import SelectorCurrency from '../../elements/SelectorCurrency';
import { db } from '../../WebSocketProvider';
import { FundTypes } from '../../utils/Enums';
import * as utils from '../../utils/Utils';
import FiatWithdraw from './FiatWithdraw';
import CryptoWithdraw from './CryptoWithdraw';
import "../Accounts.css";

const Withdraw: React.FC = () => {
  const { currencies } = useOutletContext<{ currencies: ICurrency[] }>();
  const [selected, setSelected] = useState<ICurrency | undefined>(undefined);
  const [withdrawCurrencies, setWithdrawable] = useState<ICurrency[]>([]);

  const accountType="WITHDRAWAL";
  
  const handleCurrencySelect = (currency: ICurrency) => {
    console.log("Selected currency:", currency); // Debugging log
    utils.writeParams(accountType, "acronym", currency.acronym);
    setSelected(currency);
  };

  const adjustedBalances = () => {
    if (!db) throw new Error("Database instance not available");

    const query = `
      SELECT 
        acronym, IFNULL(min_withdrawal, 0) as min_withdrawal,
        balance + 
        ((SELECT 
            (balance - pending) 
          FROM 
            client_balances
          WHERE
            acronym = 'QVC') / exchange_rate) AS withdrawable
      FROM 
        client_balances
      WHERE 
        (fund_type = 'CRYPTO' OR fund_type = 'FIAT') AND in_use = TRUE AND pending = 0;
      ORDER BY 
        seq_nbr;
    `;

    const rows: ICurrency[] = [];
    const stmt = db.prepare(query);
    while (stmt.step()) {
      const data = stmt.getAsObject();
      const row = currencies.find(item => item.acronym === data.acronym);
      if (row && data.withdrawable > data.min_withdrawal) {
        row.balance = new Decimal(data.withdrawable);
        rows.push(row);
      }
    }
    stmt.free();

    setWithdrawable(rows);
  };

  useEffect(() => {
    adjustedBalances();
    const acronym = utils.readParams(accountType, "acronym", null);
    if (acronym) {
      // Find currency matching the acronym
      const foundCurrency = withdrawCurrencies.find(currency => currency.acronym === acronym);
      if (foundCurrency) {
        setSelected(foundCurrency);
      }
    }
  }, []);

  return (
    <div className="outlet-inner">

      <SelectorCurrency
        label="Select Currency"
        currencies={withdrawCurrencies}
        onSelect={handleCurrencySelect}
        preSelect={selected}
        accountType={accountType}
      />      
      {selected && (
        <div>
          {selected.fundType === FundTypes.FIAT ? (
            <FiatWithdraw currency={selected} />
          ) : (
            <CryptoWithdraw currency={selected} />
          )}
        </div>
      )}
    
    </div>
  );
};

export default Withdraw;
