/**
 * @file IterateJson.ts
 * @description
 * This module processes JSON messages received from a WebSocket connection, performs database operations based on the
 * content of these messages, and emits events after processing. The main functions are:
 * 
 * - `processMessage`: Parses the JSON message, processes the `result` key, and handles database operations accordingly.
 * - `iterateJSON`: Iterates through the `result` key in the JSON message and processes each object.
 * - `processObject`: Processes individual JSON objects, determining the mode (INSERT, UPDATE, DELETE, etc.) and 
 *   delegating the operation to the appropriate handler function.
 * - `processJsonRow`: Handles the actual database operation based on the mode and emits events.
 * - Database handler functions (`handleInsert`, `handleUpdate`, `handleUpsert`, `handleDelete`, `handleRemove`): 
 *   Perform the specific database operations and queue the emit events.
 * 
 * Additionally, helper functions such as `getPrimaryKeyColumns`, `getTableSchemaSync`, and `convertValueToType`
 * assist in schema retrieval and value conversion.
 * 
 * Each function is documented with JSDoc comments for better understanding and maintainability.
 */

import { db, onData } from "../WebSocketProvider";
import { MessageTypes, ModeTypes } from "./Enums";
import { enumValueOf } from "./Utils";

interface EmitData {
  msg: string;
  row: any;
}

/**
 * Processes a JSON message received from the WebSocket connection.
 * Parses the message, processes the `result` key, and handles database operations accordingly.
 * @param {string} message - The JSON message received from the WebSocket.
 */
function processMessage(message: string): void {
  const startTime = performance.now();
   console.log("Received Message", message);
  
  // Parse the message as JSON
  let json: any;
  try {
    json = JSON.parse(message);
  } catch (error) {
    if (error instanceof Error) {
      console.log("Invalid JSON:", error.message);
    } else {
      console.log("An unknown error occurred:", error);
    }
    // Handle the error gracefully, such as informing the user or logging it for debugging
    return;
  }

  if (json.hasOwnProperty("error")) {
    const data: IError = {
      id: json.id,
      err: json.error.err_code,
      reason: json.error.reason
    };

    onData.emit(MessageTypes.ERROR, data);
//    onData.emit(MessageTypes.REPLY, { id: replyId, row: {}});    
    return;
  }

  let reply: IData | null = null;
  // Handle the `id` key
  let replyId:number | null = null;

  if (json.hasOwnProperty("id")) {
    replyId = json.id;
    delete json.id;
    reply = {id: replyId, row:{}};
  }


  let resultsArray: any[] = [];
  if (!json.hasOwnProperty("result")) {
    throw new Error("Invalid JSON: Missing 'result' key");
  }

  if (Array.isArray(json.result)) {
    resultsArray = json.result;
  } else {
    const obj: JsonObject = json.result as JsonObject;
    const keys = Object.keys(obj).length;

    console.log(`IterateJson.result is object ${keys}`, obj);
    if (keys <= 1) {
      onData.emit(MessageTypes.REPLY, { id: replyId, row: {}});    
      return;
    }
    resultsArray = [obj];
  }


  const emitQueue: EmitData[] = []; // Initialize the emit queue

  iterateJSON(resultsArray, emitQueue);

  emitQueue.forEach(({ msg, row }) => {
    const data : IData = { id: replyId, row};
    onData.emit(msg, data);
  });
  
  if (reply) {
    console.log(`emitting MessageTypes.REPLY: replyId: ${reply.id}`);
    onData.emit(MessageTypes.REPLY, reply);
  }

  const endTime = performance.now();
  const duration = endTime - startTime;
  if (duration >= 100)
    console.log(`IterateJson.processMessage executed in ${duration} milliseconds`);
}

/**
 * Iterates through the `result` key in the JSON message and processes each object.
 * @param {any} json - The JSON object containing the `result` key.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 * @throws {Error} If the `result` key is missing in the JSON object.
 */
function iterateJSON(resultsArray: any[], emitQueue: EmitData[]): void {
  resultsArray.forEach((obj: any) => {
    processObject(obj, emitQueue);
  });
}

/**
 * Processes individual JSON objects, determining the mode (INSERT, UPDATE, DELETE, etc.) and 
 * delegating the operation to the appropriate handler function.
 * @param {any} json - The JSON object to process.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 * @throws {Error} If the `mode` key is missing or if there are unexpected keys in the JSON object.
 */
function processObject(json: any, emitQueue: EmitData[]): void {
  const mode: ModeTypes = enumValueOf(ModeTypes, json.mode?.toString()) as ModeTypes;

  if (!mode) {
    throw new Error("Invalid JSON: Missing 'mode' key");
  }
  delete json.mode;

  if (json.hasOwnProperty("_location_")) {
    // console.log("Message for Server Location", json._location_);
    delete json._location_;
  }

  // At this point, there should be exactly one key left in the json object
  const keys = Object.keys(json);
  if (keys.length !== 1) {
    throw new Error("Invalid JSON: Unexpected number of keys");
  }

  const tablename = keys[0];
  const obj = json[tablename];
  const items: any[] = Array.isArray(obj) ? obj : [obj]; // Simplified with ternary operator

  items.forEach((elem: any) => processJsonRow(mode, tablename, elem, emitQueue));
}

/**
 * Handles the actual database operation based on the mode and emits events.
 * @param {ModeTypes} mode - The operation mode (INSERT, UPDATE, DELETE, etc.).
 * @param {string} tablename - The name of the table to operate on.
 * @param {JsonObject} json - The JSON object containing the data to operate on.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 */
function processJsonRow(
  mode: ModeTypes,
  tablename: string,
  json: JsonObject,
  emitQueue: EmitData[]
): void {
  switch (mode) {
    case ModeTypes.INSERT:
      handleInsert(tablename, json, emitQueue);
      break;
    case ModeTypes.UPDATE:
      handleUpdate(tablename, json, emitQueue);
      break;
    case ModeTypes.UPSERT:
      handleUpsert(tablename, json, emitQueue);
      break;
    case ModeTypes.DELETE:
      handleDelete(tablename, json, emitQueue);
      break;
    case ModeTypes.REMOVE:
      handleRemove(tablename, json, emitQueue);
      break;
    default:
      throw new Error(`Unknown mode: ${mode}`);
  }
}

/**
 * Retrieves and emits the last modified row based on the primary key values.
 * @param {string} tableName - The name of the table.
 * @param {JsonObject} data - The data containing the primary key values.
 * @param {string[]} primaryKeyColumns - The primary key columns of the table.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 */
function emitModifiedRow(tableName: string, data: JsonObject, primaryKeyColumns: string[], emitQueue: EmitData[]): void {
  function generateQuery(): string {
    const whereClause = primaryKeyColumns.map(column => `${column} = $${column}`).join(" AND ");
    const result = `SELECT * FROM ${tableName} WHERE ${whereClause};`;
    return result;
  }

  // Function to get primary key values from data
  function getParamValues(): JsonObject {
    const values: JsonObject = {};
    primaryKeyColumns.forEach(column => {
      if (data[column] !== undefined) {
        values[`$${column}`] = data[column].toString();  // Ensure values are strings
      }
    });
    return values;
  }

  const stmt = db.prepare(generateQuery());
  const row = stmt.getAsObject(getParamValues());
  stmt.free();

  if (row) {
    const messageType = MessageTypes[enumValueOf(MessageTypes, tableName) as keyof typeof MessageTypes];
    if (messageType) {
      // Check if an entry already exists in the emitQueue
      const existingIndex = emitQueue.findIndex(
        (item) => item.msg === tableName && primaryKeyColumns.every((pk) => item.row[pk] === row[pk])
      );

      if (existingIndex !== -1) {
        // Update the existing entry with the latest data
        emitQueue[existingIndex].row = { ...emitQueue[existingIndex].row, ...row };
      } else {
        // Add a new entry to the emitQueue
        emitQueue.push({ msg: messageType, row: row });
      }
    } else {
      throw new Error(`Invalid MessageType for ${tableName}`);
    }
  } else {
    throw new Error("INSERT/UPDATE Row not found");
  }
}

/**
 * Performs the INSERT operation and queues the emit data.
 * @param {string} tableName - The name of the table to insert into.
 * @param {JsonObject} data - The data to insert.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 */
function handleInsert(tableName: string, data: JsonObject, emitQueue: EmitData[]): void {
  if (!db) 
    throw new Error("Database instance not available");

  const primaryKeyColumns = getPrimaryKeyColumns(tableName);
  const dataColumns = Object.keys(data);

  // Validate that all primary key columns are present in the JSON data
  const missingPrimaryKeys = primaryKeyColumns.filter(pk => !dataColumns.includes(pk));
  if (missingPrimaryKeys.length > 0) {
    throw new Error(`Missing primary key columns: ${missingPrimaryKeys.join(", ")}`);
  }

  const columns = dataColumns;
  const values = Object.values(data);

  const insertQry = `INSERT INTO ${tableName} (${columns.join(", ")}) VALUES (${columns.map(() => "?").join(", ")})`;
  try {
    db.run(insertQry, values);
    emitModifiedRow(tableName, data, primaryKeyColumns, emitQueue);
  } catch (error) {
    console.log("handleInsert Error:", error);
  }
}

/**
 * Performs the UPDATE operation and queues the emit data.
 * @param {string} tableName - The name of the table to update.
 * @param {JsonObject} data - The data to update.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 */
function handleUpdate(tableName: string, data: JsonObject, emitQueue: EmitData[]): void {
  const schema = getTableSchemaSync(tableName);
  const primaryKeyColumns = schema.filter(column => column.pk !== 0).map(column => column.name);

  if (primaryKeyColumns.length === 0) {
    console.log(`Table '${tableName}' does not have a primary key.`);
    return;
  }

  const updateColumns = Object.keys(data).filter(
    (column) => !primaryKeyColumns.includes(column),
  );
  const sets = updateColumns.map((column) => `${column} = ?`).join(", ");
  const { clause, values }: { clause: string; values: any[] } = getWhereClause(
    tableName,
    data,
    primaryKeyColumns,
  );
  const updateQry = `UPDATE ${tableName} SET ${sets} WHERE ${clause}`;
  const updateValues = updateColumns.map((column) => data[column]);
  const params = [...updateValues, ...values];

  try {
    db.run(updateQry, params);
    emitModifiedRow(tableName, data, primaryKeyColumns, emitQueue);
  } catch (error) {
    console.log(`handleUpdate Error: ${tableName}`, error);
  }
}

/**
 * Performs the UPSERT operation (INSERT or REPLACE) and queues the emit data.
 * @param {string} tableName - The name of the table to upsert.
 * @param {JsonObject} data - The data to upsert.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 */
function handleUpsert(tableName: string, data: JsonObject, emitQueue: EmitData[]): void {
  const schema = getTableSchemaSync(tableName);
  const primaryKeyColumns = schema.filter(column => column.pk !== 0).map(column => column.name);
  const columns = Object.keys(data);
  const values = Object.values(data);

  const upsertQry = `INSERT OR REPLACE INTO ${tableName} (${columns.join(", ")}) VALUES (${columns.map(() => "?").join(",")})`;

  try {
    db.run(upsertQry, values);
    emitModifiedRow(tableName, data, primaryKeyColumns, emitQueue);
  } catch (error) {
    console.log("handleUpsert Error:", error);
  }
}

/**
 * Performs the DELETE operation and queues the emit data.
 * @param {string} tableName - The name of the table to delete from.
 * @param {JsonObject} data - The data to delete.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 */
function handleDelete(tableName: string, data: JsonObject, emitQueue: EmitData[]): void {
  const primaryKeyColumns = getPrimaryKeyColumns(tableName);

  if (primaryKeyColumns.length === 0) {
    console.log(`Table '${tableName}' does not have a primary key.`);
    return;
  }

  const { clause, values }: { clause: string; values: any[] } = getWhereClause(
    tableName,
    data,
    primaryKeyColumns,
  );
  const deleteQry = `DELETE FROM ${tableName} WHERE ${clause}`;
  console.log("Executing Delete Query", { qry: deleteQry, values });

  try {
    if (!db) throw new Error("Database instance not available");

    db.run(deleteQry, values);

    console.log("Deleted Row from", tableName, data);

    const deletedRowKeys = primaryKeyColumns.reduce((acc, column, index) => {
      acc[column] = values[index];
      return acc;
    }, {} as JsonObject);

    const messageType: MessageTypes = enumValueOf(MessageTypes, tableName) as MessageTypes;
    if (messageType) {
      emitQueue.push({ msg: messageType, row: { deleted: true, ...deletedRowKeys } });
      console.log("Deleted row emitted", { deleted: true, ...deletedRowKeys });
    } else {
      console.log("Invalid message type for table name", tableName);
    }
  } catch (error) {
    console.log("handleDelete Error:", error);
  }
}


/**
 * Performs the REMOVE operation and queues the emit data.
 * @param {string} tableName - The name of the table to remove from.
 * @param {JsonObject} data - The data to remove.
 * @param {EmitData[]} emitQueue - The queue to collect emit data.
 */
function handleRemove(tableName: string, data: JsonObject, emitQueue: EmitData[]): void {
  const primaryKeyColumns = getPrimaryKeyColumns(tableName);

  if (primaryKeyColumns.length === 0) {
    console.log(`Table '${tableName}' does not have a primary key.`);
    return;
  }

  const { clause, values }: { clause: string; values: any[] } = getWhereClause(
    tableName,
    data,
    primaryKeyColumns,
  );
  const removeQry = `DELETE FROM ${tableName} WHERE ${clause}`;
  console.log("Executing Remove Query", { qry: removeQry, values });

  try {
    if (!db) throw new Error("Database instance not available");

    db.run(removeQry, values, function (error: Error | null, changes: number) {
      if (error) {
        console.log("handleRemove Error:", error);
      } else if (changes > 0) {
        const removedRowKeys = primaryKeyColumns.reduce((acc, column, index) => {
          acc[column] = values[index];
          return acc;
        }, {} as JsonObject);

        const messageType = enumValueOf(MessageTypes, tableName) as MessageTypes;
        if (messageType) {
          emitQueue.push({ msg: messageType, row: { deleted: true, ...removedRowKeys } });
          console.log("Removed row emitted", { deleted: true, ...removedRowKeys });
        } else {
          console.log("Invalid message type for table name", tableName);
        }
      }
    });
  } catch (error) {
    console.log("handleRemove Error:", error);
  }
}

/**
 * Constructs the WHERE clause for SQL queries based on the primary key columns.
 * @param {string} tableName - The name of the table.
 * @param {JsonObject} data - The data containing the primary key values.
 * @param {string[]} [primaryKeyColumns] - The primary key columns of the table.
 * @returns {{ clause: string; values: any[] }} The WHERE clause and values.
 */
function getWhereClause(
  tableName: string,
  data: JsonObject,
  primaryKeyColumns?: string[],
): { clause: string; values: any[] } {
  if (!primaryKeyColumns) {
    primaryKeyColumns = getPrimaryKeyColumns(tableName); // Synchronous call
  }
  const conditions = primaryKeyColumns.map((column) => `${column} = ?`);
  const values = primaryKeyColumns.map((column) => data[column]);
  return { clause: conditions.join(" AND "), values };
}

/**
 * Retrieves the schema for a table using PRAGMA table_info.
 * @param {string} tableName - The name of the table.
 * @returns {any[]} - The schema of the table.
 */
export function getTableSchemaSync(tableName: string): any[] {
  const query = `PRAGMA table_info(${tableName});`;

  if (!db) throw new Error("Database instance not available");

  try {
    const stmt = db.prepare(query);
    const fields = [];
    while (stmt.step()) {
      fields.push(stmt.getAsObject());
    }
    stmt.free();

    if (fields.length === 0) {
      console.log(`Failed to fetch schema for table '${tableName}': No fields`);
    }

    return fields;
  } catch (error) {
    console.log(`Failed to retrieve schema for table '${tableName}':`, error);
    return [];
  }
}

/**
 * Retrieves the primary key columns for a table.
 * @param {string} tableName - The name of the table.
 * @returns {string[]} - The primary key columns of the table.
 */
export function getPrimaryKeyColumns(tableName: string): string[] {
  const schema = getTableSchemaSync(tableName);
  const primaryKeyColumns = schema
    .filter((column: any) => column.pk !== 0)
    .map((column: any) => column.name);
  return primaryKeyColumns;
}

/**
 * Retrieves the schema for a specific column in a table.
 * @param {string} tableName - The name of the table.
 * @param {string} columnName - The name of the column.
 * @returns {object} - The schema of the column.
 */
function getTableColumnSchema(tableName: string, columnName: string): { name: string, type: string } {
  const schema = getTableSchemaSync(tableName);
  return schema.find(column => column.name === columnName) || { name: columnName, type: 'string' };
}

export { processMessage };
