import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Outlet, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { protectedRoute } from "../config";
import { images } from "../helper";
import { isAuthenticated } from "./utils/Utils"; // Import the isAuthenticated function
import QButton from "./elements/QButton";
import Footer from "./inc/Footer";
import Header from "./inc/Header";

const PublicRoutes: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  /* Protect routes if user is not logged in */
  useEffect(() => {
    if (matchRoutes([{ path: "/logout" }], location) !== null) {
      return;
    }

    let route = protectedRoute.filter((val) => {
      const routes = [{ path: val }];
      return matchRoutes(routes, location) !== null;
    });

    if (route.length !== 0 && !isAuthenticated()) {
      navigate("/login");
    }
  }, [location, navigate]);

  return (
    <>
      <Header />

      <main>
        <Outlet />
      </main>

      <Footer />

      <SocketConnectionClosedPopup />
    </>
  );
};

const SocketConnectionClosedPopup: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      className="presale-modal"
      centered
      keyboard={false}
    >
      <Modal.Body className="text-center">
        <img
          className="w-100 img-filter-warning"
          src={images.svg_ic_info_dark}
          alt="Info"
        />
        <br />
        <br />
        <Modal.Title>Connection Timeout</Modal.Title>
        <br />
        <p>Please refresh the page to re-establish connection.</p>
        <br />
        <QButton
          onClick={() => {
            window.location.reload();
          }}
          noIcon
          className="btn-sm"
          text="Okay"
        />
      </Modal.Body>
    </Modal>
  );
};

export default PublicRoutes;
