import React, { useEffect, useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { MessageTypes, ClientStatus, api } from "../utils/Enums";
import { apiMessage, onData } from "../WebSocketProvider";
import AlertMessage, {AlertMessageType } from "../elements/AlertMessage";
import QButton from "../elements/QButton";
import { QCardHeaders } from "./QCardHeaders";

const ValidateEmail: React.FC = ():  JSX.Element => {
  const { t } = useTranslation();

  const [replyId, setReplyId] = useState<number | null>(null);
  const [messageType, setMessageType] = useState<AlertMessageType>(AlertMessageType.WARNING);
  const [message, setMessage] = useState<string>("");
  const [pin, setPin] = useState<string>("");

  let isResentAPICall = false;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setMessageType(AlertMessageType.WARNING);
    setMessage("");

    if (!pin) {
      setMessage("Please enter PIN");
      return;
    }

    setReplyId(apiMessage(api.EMAIL_VALIDATION, { pin_code: pin }));
  };

  const handleReply = (data: IData) => {
    if (data.id !== replyId) return;
    setReplyId(null);
  }

  const handleError = (data: IError) => {
    if (data.id !== replyId) return;

    setReplyId(null);
    setMessageType(AlertMessageType.WARNING);
    setMessage(data.reason);
  }

  useEffect(() => {
    onData.on(MessageTypes.SITE, handleReply);
    onData.on(MessageTypes.ERROR, handleError);

    return () => {
      onData.off(MessageTypes.SITE, handleReply);
      onData.off(MessageTypes.ERROR, handleError);
    }
  }, [replyId]);

  const handleResendVerificationCode = () => {
    isResentAPICall = true;
    setReplyId(apiMessage(api.RESEND_PIN, {}));
  };

  return (
    <div className="card user-account-tabs-card">
      <div className="card-body">
        <QCardHeaders
          title="VALIDATE EMAIL"
          subTitle="Email Validation"
          desc="The validation code should have been sent to you. Please check your SPAM folder."
        />

        <div className="card-content">
          {mii && mii.status === ClientStatus.UNVERIFIED ? (
            <>
              <AlertMessage
                type={AlertMessageType.WARNING}
                message="Please check your email and validate email address."
              />

              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className="text-center"
              >
                <input
                  onChange={(e) => setPin(e.target.value)}
                  className="form-control form-control-lg"
                  placeholder={t("_profile.validate_email.plc.vp")}
                />

                <AlertMessage
                  className="mt-4"
                  type={messageType}
                  message={message}
                />

                <br />

                <QButton submit block text={t("_profile.btn.validate_email")} />

                <br />

                <a
                  onClick={handleResendVerificationCode}
                  className="link-black"
                >
                  Resend Verification Code
                </a>
              </form>
            </>
          ) : (
            <h4>Your Email address is already verified.</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default ValidateEmail;
