import React, { useEffect, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavDropdownMenu } from "react-bootstrap-submenu";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../helper";
import { onData } from "../WebSocketProvider";
import LangDropDownMenu from "../elements/LangDropDownMenu";
import { MessageTypes, ClientStatus } from "../utils/Enums";
import { NotificationBell } from "./NotificationBell";
import * as utils from "../utils/Utils";
import ProfileNavigation from "./ProfileNavigation";
import TopNav from "./TopNav";

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [loggedIn, setLogin] = useState<boolean>(false);

  useEffect(() => {
    const handleChange = (data: IMii) => {
      console.log("handleMii", data);
      if (!loggedIn && (mii.status === ClientStatus.ACTIVE || mii.status === ClientStatus.UNVERIFIED)) {
        setLogin(true);

        const language = utils.readParams(MessageTypes.MII, "language", "en");
        i18n.changeLanguage(language);

        const dflt: string = (mii.status === ClientStatus.UNVERIFIED) ? "/profile/validate-email" : "/";
        const page = utils.readParams(MessageTypes.MII, "page", dflt);

        console.log(`Header.tsx: Changing status to ${mii.status}, Language: ${language}, Page: ${page}`);
        onData.off(MessageTypes.MII, handleChange);
        
        navigate(page);
      }
    };

    onData.on(MessageTypes.MII, handleChange);

    return () => {
      onData.off(MessageTypes.MII, handleChange);
    };
  }, [i18n, navigate, loggedIn]);

  const toggleDropdown = (e?: React.MouseEvent<HTMLElement>) => {
    setShow((prevShow) => !prevShow);
  };

  const handleLinkClick = () => {
    toggleDropdown();
  };

  return (
    <header className={`page-header ${"page-header-sticky"} ${loggedIn ? "user-loggedin" : ""}`}>

{/*    <header
      className={`page-header ${
        window.location.hostname !== "localhost" ? "page-header-sticky" : ""
      } ${loggedIn ? "user-loggedin" : ""}`}
    >
*/}    
      <TopNav />

      <div className="logoheader_main">
        <Navbar expanded={show} sticky="top" expand="lg">
          <Container>
            <Navbar.Brand as={Link} to="/">
              <img src={images.svg_h_logo} alt="Logo Image" />
            </Navbar.Brand>

            {(() => {
              console.log("LoggedIn:", loggedIn);
              return null;
            })()}

            {loggedIn ? (
              <div style={{ display: "flex" }}>
                <NotificationBell />
                <Navbar.Toggle
                  onClick={toggleDropdown}
                  aria-controls="navbarScroll"
                />
              </div>
            ) : (
              <Navbar.Toggle
                onClick={toggleDropdown}
                aria-controls="navbarScroll"
              />
            )}

            <Navbar.Collapse id="navbarScroll">
              <div className="navbar-close-section-mobile">
                <img src={images.svg_h_logo} alt="Logo Image" />
                <Navbar.Toggle
                  onClick={toggleDropdown}
                  aria-controls="navbarScroll"
                />
              </div>

              {loggedIn && (
                <div className="navbar-profile-section-mobile">
                  <ProfileNavigation onLinkClicked={handleLinkClick} />
                </div>
              )}

              <div className="navbar-info-section-mobile">
                <Link
                  onClick={toggleDropdown}
                  to="/support"
                  className="h_support"
                >
                  <img src={images.svg_headphones} alt="" height="100%" />
                </Link>
                <Link
                  onClick={toggleDropdown}
                  to="/"
                  style={{ marginRight: "24px" }}
                  className="h_contact"
                >
                  <img src={images.svg_mail} alt="" height="100%" />
                </Link>
                <LangDropDownMenu />
              </div>

              {!loggedIn ? (
                <div className="navbar-buttons-section-mobile">
                  <Link
                    onClick={toggleDropdown}
                    to="/register"
                    className="login-button"
                  >
                    <p>{t("_menu.create_account")}</p>
                  </Link>
                  <Link
                    onClick={toggleDropdown}
                    to="/login"
                    className="login-button"
                  >
                    <p>{t("_top_nav.login")}</p>
                  </Link>
                </div>
              ) : (
                <div className="h-divider" />
              )}

              <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                <NavDropdownMenu
                  title={t("_menu.windfall.title")}
                  alignRight
                  renderMenuOnMount={true}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    as={Link}
                    onClick={toggleDropdown}
                    to="/presale"
                  >
                    {t('_menu.windfall.menu.purchase')}  
                  </NavDropdown.Item>
                  
                  <NavDropdown.Item
                    as={Link}
                    onClick={toggleDropdown}
                    to="/why-windfall-lottery"
                  >
                    {t('_menu.windfall.menu.game-rules')}  
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    onClick={toggleDropdown}
                    to="/lottery-pools"
                  >
                    {t('_menu.windfall.menu.about-pools')}  
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as={Link}
                    onClick={toggleDropdown}
                    to="/windfall/provably-fair"
                  >
                    {t('_menu.windfall.menu.provably-fair')}  
                  </NavDropdown.Item>
                </NavDropdownMenu>

                <Nav.Item>
                  <Nav.Link as={Link} to="/trimorra">
                    {t("_menu.trimorra")}
                  </Nav.Link>
                </Nav.Item>

                <NavDropdownMenu
                  title={t("_menu.bingo.title")}
                  alignRight
                  renderMenuOnMount={true}
                  disabled={true}
                  id="collasible-nav-dropdown"
                >
                  <Nav.Item>
                    <Nav.Link className="text-gray" 
                      as={Link} 
                      to="/3up"                   
                      disabled={true}
                    >
                      {t("_menu.bingo.menu.3-up")}
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                  <Nav.Link className="text-gray" 
                      as={Link} 
                      to="/bonanza"
                      disabled={true}
                    >
                      {t("_menu.bingo.menu.bonanza")}
                    </Nav.Link>
                  </Nav.Item>

                </NavDropdownMenu>              
                <Nav.Item>
                  <Nav.Link
                    onClick={toggleDropdown}
                    as={Link}
                    to="/provably-fair-general"
                  >
                    {t("_menu.provably-fair")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {mii.status === ClientStatus.OBSERVER && (
                <Link className="login-button d-flex"
                  to="/register" >
                  {t("_menu.create-account")}
                </Link>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
